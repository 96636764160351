import { connect } from 'react-redux';
import {
    getCodeCategorieVehicule,
    getGenreVehicule,
    getGroupeVehicule,
} from '../../../../../../../redux/selectors/vehicule/vehicule.selectors';
import BlocInformations from './BlocInformations';

const mapStateToProps = (state) => {
    return {
        codeCat: getCodeCategorieVehicule(state),
        genre: getGenreVehicule(state),
        groupe: getGroupeVehicule(state),
    };
};

const BlocInformationsContainer = connect(mapStateToProps)(BlocInformations);

export default BlocInformationsContainer;
