import './footer.scss';

const Footer = ({source}) => {
    return (
        <div className={'footer'}>
            <div className={`container-fluid p-4 f-12 bg-white bg-grey-medium`}>
                <div className="row">
                    {source === 'courtier' && <div className="col-12 col-md-3">
                        <a className="f-14 font-weight-bold text-initial" href='https://www.april-moto.com/wp-content/uploads/2022/07/26.06.2022-CGU-Groupe-April.pdf' target='_blank'>Mentions légales</a>
                    </div> }
                    <div className="col-12 col-md-3">
                        <a className="f-14 font-weight-bold text-initial" href='https://amgestionassurance.com/gestion-cookies.html' target='_blank'>Gestion des cookies</a>
                    </div>
                    <div className="col-12 col-md-3">
                        <a className="f-14 font-weight-bold text-initial" href='https://www.amgestionassurance.com/Doc/Lettre-RGPD-AM-Gestion-11.2021.pdf' target='_blank'>Politique des données personnelles</a>
                    </div>
                    {source === 'courtier' && <div className="col-12 col-md-3">
                        <a className="f-14 font-weight-bold text-initial"
                           href='https://www.april-moto.com/wp-content/uploads/2022/07/26.06.2022-Rubrique-Fonctionnement_Groupe-April.pdf'
                           target='_blank'>Fonctionnement du Tarificateur</a>
                    </div>}
                </div>
            </div>

            {
                source !== "site" && <div className={'container-fluid bg-dark p-4'}>
                    <div className="row footer-link">
                        <div className={'col'}>
                            <p className={'f-14'}>
                                En cas de problème sur le formulaire, copier-coller cette référence avant de nous l'envoyer
                                :
                            </p>
                        </div>
                        <p className={'f-14'}>Navigateur : {window.navigator.userAgent}</p>
                    </div>
                </div>
            }

        </div>
    );
};

export default Footer;
