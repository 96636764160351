import { API_URL_WHEELS } from '@amo/core/constants/constants.js';

export const VEHICULE = '[Vehicule]';

export const GET_VEHICULE = `${VEHICULE} Get Vehicule`;
export const SET_VEHICULE = `${VEHICULE} Set Vehicule`;

export const getVehicule = ({ idSra }) => ({
    type: GET_VEHICULE,
    payload: {
        data: `${API_URL_WHEELS}/vehicles/${idSra}`,
    },
});

export const setVehicule = ({ data }) => ({
    type: SET_VEHICULE,
    payload: {
        data,
    },
});
