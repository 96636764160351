export const FILL = '[Fill]';

export const POST_FILL = `${FILL} Post`;

export const postFill = ({ step, maxStep, form, query, urlReloadTarif }) => ({
    type: POST_FILL,
    payload: {
        data: query,
        body: form,
        meta: {
            otherData: {
                step,
                maxStep,
                urlReloadTarif,
                form,
            },
        },
    },
});
