export const DEMANDE_TARIF = '[DemandeTarif]';

export const SET_DEMANDE_TARIF = `${DEMANDE_TARIF} Set`;

export const setDemandeTarif = ({ demandeTarif }) => ({
    type: SET_DEMANDE_TARIF,
    payload: {
        data: demandeTarif,
    },
});
