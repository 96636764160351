import { Divider } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import { Field } from 'redux-form';

const ASAP = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-4 col-12 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={'choixCreneau'}
                    value={'asap'}
                    label={'Je souhaite être rappelé au plus vite'}
                />
            </div>
            <div className={'col-12 d-lg-none d-xl-none'}>
                <Divider content="OU" />
            </div>
            <div className={'col-lg-4 col-12 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={'choixCreneau'}
                    value={'creneau'}
                    label={'Je prends rendez-vous avec un conseiller'}
                />
            </div>
        </div>
    );
};

export default ASAP;
