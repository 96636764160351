import { useEffect, useState } from 'react';
import {Modal} from "@amo/core/components/containers";

const PopupRecontact = () => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        return () => document.body.classList.remove('modal-open');
    }, []);

    return (
        <>
            {open && (
                <Modal
                    onClick={() => {
                        setOpen(!open);
                        document.body.classList.remove('modal-open');
                    }}
                >
                    <div className={'text-center'}>
                        <h3 className={'card-title'}>Merci, nous avons bien reçu votre demande</h3>
                        
                        <div className={'mt-3'}>
                            <p className={'card-text'}>Pour toute information, notre équipe se tient à votre disposition au 02 47 51 07 07.</p>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default PopupRecontact;
