import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { getFormInputLabel } from '../../../../../../utils/transcoLabels';
import BesoinsSideBar from './BesoinsSideBar';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');
    return {
        incendie: getFormInputLabel('incendie', selector(state, 'DemandeTarif[DevoirConseil][DevoirVol]')),
        accidents: getFormInputLabel('accidents', selector(state, 'DemandeTarif[DevoirConseil][DevoirDommage]')),
        remorquage: getFormInputLabel('remorquage', selector(state, 'DemandeTarif[DevoirConseil][DevoirASS2]')),
        indemnisation: getFormInputLabel('indemnisation', selector(state, 'DemandeTarif[DevoirConseil][DevoirIC]')),
        remplacement: getFormInputLabel('remplacement', selector(state, 'DemandeTarif[DevoirConseil][DevoirASS3]')),
        accessoires: getFormInputLabel('accessoires', selector(state, 'DemandeTarif[DevoirConseil][DevoirACCESSEQP]')),
        rachat: getFormInputLabel('rachat', selector(state, 'DemandeTarif[DevoirConseil][DevoirRAFRA]')),
    };
};

const BesoinsSideBarContainer = connect(mapStateToProps)(BesoinsSideBar);

export default BesoinsSideBarContainer;
