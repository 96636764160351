import cross from '@amo/core/assets/images/produits/icon_cross.svg';
import moto from '@amo/core/assets/images/produits/icon_moto.svg';
import quad from '@amo/core/assets/images/produits/icon_quad.svg';
import scooter from '@amo/core/assets/images/produits/icon_scooter.svg';
import { SECRET_ENCRYPT } from '@amo/core/constants/constants';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';

export const crm = [
    { code: '0.50', libelle: '0,50 soit 50% de bonus' },
    { code: '0.51', libelle: '0,51 soit 49% de bonus' },
    { code: '0.52', libelle: '0,52 soit 48% de bonus' },
    { code: '0.53', libelle: '0,53 soit 47% de bonus' },
    { code: '0.54', libelle: '0,54 soit 46% de bonus' },
    { code: '0.55', libelle: '0,55 soit 45% de bonus' },
    { code: '0.56', libelle: '0,56 soit 44% de bonus' },
    { code: '0.57', libelle: '0,57 soit 43% de bonus' },
    { code: '0.58', libelle: '0,58 soit 42% de bonus' },
    { code: '0.59', libelle: '0,59 soit 41% de bonus' },
    { code: '0.60', libelle: '0,60 soit 40% de bonus' },
    { code: '0.61', libelle: '0,61 soit 39% de bonus' },
    { code: '0.62', libelle: '0,62 soit 38% de bonus' },
    { code: '0.63', libelle: '0,63 soit 37% de bonus' },
    { code: '0.64', libelle: '0,64 soit 36% de bonus' },
    { code: '0.65', libelle: '0,65 soit 35% de bonus' },
    { code: '0.66', libelle: '0,66 soit 34% de bonus' },
    { code: '0.67', libelle: '0,67 soit 33% de bonus' },
    { code: '0.68', libelle: '0,68 soit 32% de bonus' },
    { code: '0.69', libelle: '0,69 soit 31% de bonus' },
    { code: '0.70', libelle: '0,70 soit 30% de bonus' },
    { code: '0.71', libelle: '0,71 soit 29% de bonus' },
    { code: '0.72', libelle: '0,72 soit 28% de bonus' },
    { code: '0.73', libelle: '0,73 soit 27% de bonus' },
    { code: '0.74', libelle: '0,74 soit 26% de bonus' },
    { code: '0.75', libelle: '0,75 soit 25% de bonus' },
    { code: '0.76', libelle: '0,76 soit 24% de bonus' },
    { code: '0.77', libelle: '0,77 soit 23% de bonus' },
    { code: '0.78', libelle: '0,78 soit 22% de bonus' },
    { code: '0.79', libelle: '0,79 soit 21% de bonus' },
    { code: '0.80', libelle: '0,80 soit 20% de bonus' },
    { code: '0.81', libelle: '0,81 soit 19% de bonus' },
    { code: '0.82', libelle: '0,82 soit 18% de bonus' },
    { code: '0.83', libelle: '0,83 soit 17% de bonus' },
    { code: '0.84', libelle: '0,84 soit 16% de bonus' },
    { code: '0.85', libelle: '0,85 soit 15% de bonus' },
    { code: '0.86', libelle: '0,86 soit 14% de bonus' },
    { code: '0.87', libelle: '0,87 soit 13% de bonus' },
    { code: '0.88', libelle: '0,88 soit 12% de bonus' },
    { code: '0.89', libelle: '0,89 soit 11% de bonus' },
    { code: '0.90', libelle: '0,90 soit 10% de bonus' },
    { code: '0.91', libelle: '0,91 soit 9% de bonus' },
    { code: '0.92', libelle: '0,92 soit 8% de bonus' },
    { code: '0.93', libelle: '0,93 soit 7% de bonus' },
    { code: '0.94', libelle: '0,94 soit 6% de bonus' },
    { code: '0.95', libelle: '0,95 soit 5% de bonus' },
    { code: '0.96', libelle: '0,96 soit 4% de bonus' },
    { code: '0.97', libelle: '0,97 soit 3% de bonus' },
    { code: '0.98', libelle: '0,98 soit 2% de bonus' },
    { code: '0.99', libelle: '0,99 soit 1% de bonus' },
    { code: '1.00', libelle: '1 soit ni bonus ni malus' },
    { code: '1.01', libelle: '1,01 soit 1% de malus' },
    { code: '1.02', libelle: '1,02 soit 2% de malus' },
    { code: '1.03', libelle: '1,03 soit 3% de malus' },
    { code: '1.04', libelle: '1,04 soit 4% de malus' },
    { code: '1.05', libelle: '1,05 soit 5% de malus' },
    { code: '1.06', libelle: '1,06 soit 6% de malus' },
    { code: '1.07', libelle: '1,07 soit 7% de malus' },
    { code: '1.08', libelle: '1,08 soit 8% de malus' },
    { code: '1.09', libelle: '1,09 soit 9% de malus' },
    { code: '1.10', libelle: '1,10 soit 10% de malus' },
    { code: '1.11', libelle: '1,11 soit 11% de malus' },
    { code: '1.12', libelle: '1,12 soit 12% de malus' },
    { code: '1.13', libelle: '1,13 soit 13% de malus' },
    { code: '1.14', libelle: '1,14 soit 14% de malus' },
    { code: '1.15', libelle: '1,15 soit 15% de malus' },
    { code: '1.16', libelle: '1,16 soit 16% de malus' },
    { code: '1.17', libelle: '1,17 soit 17% de malus' },
    { code: '1.18', libelle: '1,18 soit 18% de malus' },
    { code: '1.19', libelle: '1,19 soit 19% de malus' },
    { code: '1.20', libelle: '1,20 soit 20% de malus' },
    { code: '1.21', libelle: '1,21 soit 21% de malus' },
    { code: '1.22', libelle: '1,22 soit 22% de malus' },
    { code: '1.23', libelle: '1,23 soit 23% de malus' },
    { code: '1.24', libelle: '1,24 soit 24% de malus' },
    { code: '1.25', libelle: '1,25 soit 25% de malus' },
    { code: '1.26', libelle: '1,26 soit 26% de malus' },
    { code: '1.27', libelle: '1,27 soit 27% de malus' },
    { code: '1.28', libelle: '1,28 soit 28% de malus' },
    { code: '1.29', libelle: '1,29 soit 13% de malus' },
    { code: '1.30', libelle: '1,30 soit 30% de malus' },
    { code: '1.31', libelle: '1,31 soit 31% de malus' },
    { code: '1.32', libelle: '1,32 soit 32% de malus' },
    { code: '1.33', libelle: '1,33 soit 33% de malus' },
    { code: '1.34', libelle: '1,34 soit 34% de malus' },
    { code: '1.35', libelle: '1,35 soit 35% de malus' },
    { code: '1.36', libelle: '1,36 soit 36% de malus' },
    { code: '1.37', libelle: '1,37 soit 37% de malus' },
    { code: '1.38', libelle: '1,38 soit 38% de malus' },
    { code: '1.39', libelle: '1,39 soit 39% de malus' },
    { code: '1.40', libelle: '1,40 soit 40% de malus' },
    { code: '1.41', libelle: '1,41 soit 41% de malus' },
    { code: '1.42', libelle: '1,42 soit 42% de malus' },
    { code: '1.43', libelle: '1,43 soit 43% de malus' },
    { code: '1.44', libelle: '1,44 soit 44% de malus' },
    { code: '1.45', libelle: '1,45 soit 45% de malus' },
    { code: '1.46', libelle: '1,46 soit 46% de malus' },
    { code: '1.47', libelle: '1,47 soit 47% de malus' },
    { code: '1.48', libelle: '1,48 soit 48% de malus' },
    { code: '1.49', libelle: '1,49 soit 49% de malus' },
    { code: '1.50', libelle: '1,50 soit 50% de malus' },
    { code: '1.51', libelle: '1,51 soit 51% de malus' },
    { code: '1.52', libelle: '1,52 soit 52% de malus' },
    { code: '1.53', libelle: '1,53 soit 53% de malus' },
    { code: '1.54', libelle: '1,54 soit 54% de malus' },
    { code: '1.55', libelle: '1,55 soit 55% de malus' },
    { code: '1.56', libelle: '1,56 soit 56% de malus' },
];

export const getCRMMotoMin = ({ datePermisA1 = null, datePermisA2 = null, datePermisA = null, datePermisB = null }) => {
    let crms = [getCRM(datePermisA1), getCRM(datePermisA2), getCRM(datePermisA), getCRM(datePermisB)];

    return _.min(crms);
};

export const getCRMAutoMin = (datePermisB) => {
    return getCRM(datePermisB);
};

const getCRM = (date) => {
    if (moment(date, 'DD/MM/YYYY').isValid()) {
        date = moment(date, 'DD/MM/YYYY');
        let now = moment();
        let mois = now.diff(date, 'months');

        if (mois > 154) {
            return 0.5;
        } else if (mois > 142) {
            return 0.51;
        } else if (mois > 130) {
            return 0.54;
        } else if (mois > 118) {
            return 0.57;
        } else if (mois > 106) {
            return 0.6;
        } else if (mois > 94) {
            return 0.64;
        } else if (mois > 82) {
            return 0.68;
        } else if (mois > 70) {
            return 0.72;
        } else if (mois > 58) {
            return 0.76;
        } else if (mois > 46) {
            return 0.8;
        } else if (mois > 34) {
            return 0.85;
        } else if (mois > 22) {
            return 0.9;
        } else if (mois >= 10) {
            return 0.95;
        }
    }

    return 1;
};

export const encrypt = (string) => {
    return CryptoJS.AES.encrypt(JSON.stringify(string), SECRET_ENCRYPT).toString();
};

export const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(JSON.parse(data), SECRET_ENCRYPT);
    const decrypt = bytes.toString(CryptoJS.enc.Utf8);
    return decrypt ? JSON.parse(decrypt) : {};
};

export const getIconByType = (typeVehicule) => {
    const icons = [
        { type: 'moto', icon: moto },
        { type: 'scooter', icon: scooter },
        { type: '50', icon: scooter },
        { type: 'quad', icon: quad },
        { type: 'verte', icon: cross },
    ];

    return _.find(icons, ['type', typeVehicule])?.icon || moto;
};

export const showModal = ({ imageUrl, title, icon, text, html, confirmButtonText = "J'ai compris" }) => {
    Swal.fire({
        imageUrl,
        icon,
        title,
        text,
        html,
        confirmButtonText,
    });
};

export const getVehicleHolderName = (listePersonnes, titulaireCarteGrise) => {
    const datenaissanceCond = listePersonnes[0]['DateNaissance'];

    let nomPrenomTitulaire = listePersonnes[0]['Nom'] + ' ' + listePersonnes[0]['Prenom'];
    if (titulaireCarteGrise === '2') {
        nomPrenomTitulaire = listePersonnes[1]['Nom'];
    } else if (
        datenaissanceCond &&
        moment.isMoment(datenaissanceCond) &&
        moment(datenaissanceCond).add(18, 'years') > moment()
    ) {
        nomPrenomTitulaire = listePersonnes[1]['Nom'] + ' ' + listePersonnes[1]['Prenom'];
    }
    return _.trim(nomPrenomTitulaire);
};

export const hameconnage = ({ changeValue, setIndexQuestion }) => {
    /* index à 1 pour sauter la premiere question d'arrivé */
    let index = 1;

    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    const search = {};

    for (const [key, value] of params) {
        search[key] = value;
    }

    /* Les mêmes conditions que la question du type de véhicule */
    if (search.vehicule) {
        index = index + 1;
        switch (search.vehicule) {
            case 'moto':
            case 'scooter':
                changeValue('typeVehicule', search.vehicule);
                changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', '1');
                break;
            case '50':
                changeValue('typeVehicule', '50');
                changeValue('cylindreeVehicule', '50');
                changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', '1');
                break;
            case 'quad':
            case 'verte':
                changeValue('typeVehicule', search.vehicule);
                changeValue('DemandeTarif[Vehicule][UsageVehicule]', '2');
                break;
            default:
                break;
        }
        /* ne change l'index que si on a un véhicule */
        if (search.marque) {
            index = index + 1;
        }
    }

    if (search.marque) {
        changeValue('marqueVehicule', search.marque.toUpperCase());
    }

    index > 1 && setIndexQuestion({ index });
};

/**
 * Récupère la valeur numérique contenue dans une chaîne de caractères
 * @param {string} string Chaîne de caractères
 * @returns {number|null} Valeur numérique ou null si aucune valeur numérique n'a été trouvée
 */
export const getNumberInString = (string) => {
    const regex = /(\d+)/;
    const match = regex.exec(string);
    if (match) {
        return parseInt(match[0]);
    }
    return null;
};

export const getSlugStep = slug => {
    const route = _.find(RouteStep, ['slug', slug]);
    return (route && route !== -1) ? route.step : '';
};
export const getStepSlug = (step, needRDB) => {
    const route = _.find(needRDB ? calculRoutesByRecueilBesoin(needRDB, RouteStep) : RouteStep, ['step', step]);
    return (route && route !== -1) ? route.slug : '';
};
export const getTexteBySource = (source, question) => {
    let newSource = source === 'conseiller' ? 'courtier' : source
    return texteSource[0][question][newSource]
}
export const calculRoutesByRecueilBesoin = (value, routes) => {
    let step = 0
    let cloneRoute = _.cloneDeep(routes)

    if (value === 'non') {
        cloneRoute = _.reject(cloneRoute, ['slug', 'votre-besoin'])
    }

    value !== undefined && _.forEach(cloneRoute, r => {
        r.step = step++
    })

    return cloneRoute
}

export const getInformationVehicule = (vehicule) => {
    const {cylindree, type_vehicule, genre, groupe, type} = vehicule

    if (genre === 'TRI') {
        return "<b>Pour assurer ce tricycle chez nous</b>, vous devez remplir les conditions suivantes :<br/>" +
            "- Vous avez le permis B depuis au moins 2 ans ou le permis A<br/>" +
            "- Si vous n’avez que le permis B, vous devez avoir au moins 21 ans<br/>" +
            " - Si vous n’avez que le permis B, vous avez suivi une formation pratique de 7 heures<br/><br/>" +
            "Vous êtes dispensé de suivre la formation pratique de 7 heures dans les cas suivants :<br/>" +
            "- Vous avez obtenu votre permis B (avec l'équivalence A1) avant mars 1980<br/>" +
            "- Vous avez conduit une moto légère ou un scooter 3 roues de la catégorie L5e au cours des 24 derniers mois justifiés par un relevé d’information";
    } else if (cylindree === 50 && type_vehicule === 'cyclo') {
        return "Pour conduire un 50cm³, vous devez être titulaire au minimum du permis AM (BSR) si vous êtes né après le 01/01/1988.";
    } else if (type_vehicule === 'quad' && cylindree > 50) {
        return "Pour conduire ce véhicule vous devez remplir les conditions suivantes :<br/>" +
            "- Être titulaire du permis B<br/>" +
            "- Votre bonus est inférieur ou égal à 1,00";
    } else if (type.id === 'verte' && cylindree > 50) {
        return "Pour conduire ce véhicule vous devez remplir les conditions suivantes :<br/>" +
            "- Vous avez au moins 18 ans<br/>" +
            "- Votre bonus est inférieur ou égal à 1,00";
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree === 125) {
        return "Pour conduire une moto de 125cm³ avec un permis B vous devez :<br/>" +
            "- Avoir suivi une formation à la conduite d'une moto 125 cm³<br/>" +
            "- Ou avoir été assuré entre le 01/01/2006 et le 01/01/2011 pour cette typologie de véhicule justifié par un relevé d'information<br/>" +
            "- Avoir un bonus inférieur ou égal à 1,56";
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && genre === 'A2' && parseInt(groupe) < 15) {
        return "Pour assurer cette moto chez nous, vous devez remplir les conditions suivantes :<br/>" +
            "- Vous avez au moins 18 ans<br/>" +
            "- Vous avez le permis A ou A2<br/>" +
            "- Votre bonus est inférieur ou égal à 1,56";
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && parseInt(groupe) < 15) {
        return "Pour assurer cette moto chez nous, vous devez remplir les conditions suivantes :<br/>" +
            "- Vous avez au moins 18 ans<br/>" +
            "- Vous avez le permis A<br/>" +
            "- Votre bonus est inférieur ou égal à 1,56<br/>"
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && groupe === '15') {
        return "Pour assurer cette moto chez nous, vous devez remplir les conditions suivantes :<br/>" +
            "- Vous avez au moins 21 ans<br/>" +
            "- Vous avez le permis A ou B depuis au moins 24 mois<br/>" +
            "- Votre bonus est inférieur ou égal à 1,56<br/>" +
            "- Vous pouvez justifier de 12 mois consécutifs d'assurance auto ou moto sur les 24 derniers mois"
    }  else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && (groupe === '16' || groupe === '17')) {
        return "Pour assurer cette moto chez nous, vous devez remplir les conditions suivantes :<br/>" +
            "- Vous avez au moins 21 ans<br/>" +
            "- Vous avez le permis A ou B depuis au moins 24 mois<br/>" +
            "- Votre bonus est inférieur ou égal à 1,00<br/>" +
            "- Vous pouvez justifier de 24 mois consécutifs d'assurance auto ou moto sur les 24 derniers mois"
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && groupe === '18') {
        return "Pour assurer cette moto chez nous, vous devez remplir les conditions suivantes :<br/>" +
            "<b>Si vous avez au moins 25 ans</b><br/>" +
            "- Vous avez le permis A depuis au moins 60 mois (5 ans)<br/>" +
            "- Votre bonus est inférieur ou égal à 0,80 (en moto)<br/>" +
            "- Vous pouvez justifier de 18 mois consécutifs d'assurance moto sur les 24 derniers mois<br/>" +
            "<b>Si vous avez au moins 30 ans</b><br/>" +
            "- Vous avez le permis A depuis au moins 24 mois (2 ans)<br/>" +
            "- Votre bonus est inférieur ou égal à 0,90 (en moto)<br/>" +
            "- Vous pouvez justifier de 18 mois consécutifs d'assurance moto sur les 24 derniers mois"
    }

    return '';
};
