import { connect } from 'react-redux';
import {
    getContratInit,
    getDocumentsUrlInit, getImmatriculationVehiculeDemandeTarifInit,
    getListeDocumentsContratInit, getUrlAttestationAssurance, getUrlMemo,
    getUrlPdfContrat, getUrlPDFFicheConseilInit, getUrlPdfHamon,
    getUrlPdfSepa,
} from '../../../../../../redux/selectors/init/init.selectors';
import EnvoiPieces from './EnvoiPieces';

const mapStateToProps = (state) => {
    return {
        documentsUrl: getDocumentsUrlInit(state),
        listeDocuments: getListeDocumentsContratInit(state),
        contract: getContratInit(state),
        immatriculation: getImmatriculationVehiculeDemandeTarifInit(state),
        urlPdfContrat: getUrlPdfContrat(state),
        urlPdfSepa: getUrlPdfSepa(state),
        urlPdfHamon: getUrlPdfHamon(state),
        urlFicheConseil: getUrlPDFFicheConseilInit(state),
        urlMemo: getUrlMemo(state),
        urlAttestationAssurance: getUrlAttestationAssurance(state)
    };
};

const EnvoiPiecesContainer = connect(mapStateToProps)(EnvoiPieces);

export default EnvoiPiecesContainer;
