import { connect } from 'react-redux';
import { compose } from 'redux';
import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js'
import { fetchContratStatut } from '../../../../../../redux/actions/app/checkContratStatut/checkContratStatut.actions';
import { getToken } from '../../../../../../redux/selectors/init/init.selectors';
import {loader} from '@amo/core/components/loaders';
import Validation from './Validation';

const mapStateToProps = (state) => {
    return {
        query: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
    };
};

const mapDispatchToProps = {
    fetchContratStatut,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps;
    const { fetchContratStatut } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => fetchContratStatut({ query }),
    };
};

const ValidationContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps), loader())(Validation);

export default ValidationContainer;
