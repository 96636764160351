import {useState} from 'react';
import PropTypes from 'prop-types'

export const Modal = (props) => {
    const { title, classTitle, children, canClose, bodyScrollable, large, onClick } = props
    const [close, setClose] = useState(false)

    if( !bodyScrollable && !close ) {
        document.body.classList.add('modal-open')
    } else {
        document.body.classList.remove('modal-open')
    }

    return (
        <div data-id="modal">
            <div className={`modal fade ${!close && 'show d-block'}`} tabIndex="-1" role="dialog" aria-labelledby={title} aria-hidden="false">
                <div className={`modal-dialog modal-dialog-scrollable ${large && 'modal-lg'}`} role="document">
                    <div className="modal-content">

                        {
                            ( canClose || title ) &&

                                <div className="modal-header">
                                    {title && <h5 className={`${classTitle} modal-title`} data-test={'title'} dangerouslySetInnerHTML={{__html: title}} />}

                                    { canClose &&
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClick ? onClick : () => setClose(true)} data-test={"close"}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    }
                                </div>
                        }
                        <div className="modal-body">
                            { children }
                        </div>
                    </div>
                </div>
            </div>

            { !bodyScrollable && <div data-test={"background"} className={`modal-backdrop d-none ${!close && 'show d-block'}`} /> }
        </div>
    );
};


Modal.defaultProps = {
    bodyScrollable: false,
    title: '',
    canClose: true,
    large: false,
    classTitle: ''
};


Modal.propTypes = {
    title: PropTypes.string,
    classTitle: PropTypes.string,
    children: PropTypes.node.isRequired,
    canClose: PropTypes.bool,
    bodyScrollable: PropTypes.bool,
    large: PropTypes.bool,
    onClick: PropTypes.func
};