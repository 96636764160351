import './question.scss';
import _ from "lodash";
import info from "../../../assets/images/AMO_Icons/FAQ.svg";

export const Question = (props) => {
    const { icon, title, subtitle, position, children, loader, bandeau } = props;

    return (
        <div className={`${!loader && 'question'} rounded text-center col-lg-8 offset-lg-2 col-md-12`}>
            {bandeau &&
                <>
                    {(!_.get(props.data, `DemandeTarif[ListePersonnes][0][Nom]`) && props.apporteur1.codeBelair == 43397) &&
                    <div className={'row rounded-5 border border-primary-light'}>
                        <div className={'d-flex align-items-center gap-4 p-3'}>
                            <img src={info} alt={'Informations complémentaires'}/>
                            <div className={'text-start ms-4'}>
                                <p className={'f-16 fw-bold mb-2'}>Déjà assuré APRIL Moto ?</p>
                                <p className={'f-13'}>Vous êtes déjà assuré chez APRIL Moto ? Connectez-vous afin de gagner du temps lors de la réalisation de votre devis.</p>
                            </div>
                            <a href={'https://mon-espace.april-moto.com/'} className={'btn btn-primary btn-smaller'}>Aller sur mon espace assuré</a>
                        </div>
                    </div>
                }
            </>
            }
            <div className={'p-3'}>
                {icon && <img src={icon} alt={title} className={'mx-2'} width={65} />}
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <div className={`${position === 'top' ? 'flex-column' : 'flex-column-reverse'} d-flex`}>
                            <p className={'question-title mx-2'} data-title={title.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi, ' ')} dangerouslySetInnerHTML={{ __html: title }} />
                            <p className={'f-16 text-secondary mx-2 fw-bolder'}>{subtitle}</p>
                        </div>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

Question.defaultProps = {
    children: <p>Children Question</p>,
};
