import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { getFormInputLabel } from '../../../../../../utils/transcoLabels';
import VehiculeSideBar from './VehiculeSideBar';
import {getImgMarqueVehicule, getVehicule} from "../../../../../../redux/selectors/vehicule/vehicule.selectors.js";

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');

    return {
        situationVehicule: getFormInputLabel(
            'DemandeTarif[Vehicule][SituationVehicule]',
            selector(state, 'DemandeTarif[Vehicule][SituationVehicule]'),
        ),
        familleProduit: getFormInputLabel('typeVehicule', selector(state, 'typeVehicule')),
        marque: selector(state, `marqueVehicule`),
        cylindree: selector(state, `cylindreeVehicule`),
        modele: selector(state, `modeleVehicule`),
        vehicule: getVehicule(state),
        logoMarque: getImgMarqueVehicule(state)
    };
};

const VehiculeSideBarContainer = connect(mapStateToProps)(VehiculeSideBar);

export default VehiculeSideBarContainer;
