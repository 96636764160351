import _ from 'lodash';

export const getRoutesByDevice = (step, routes) => {
    const index = _.findIndex(routes, ['step', step]);

    switch (index) {
        case 0:
            routes = _.slice(routes, index, 5);
            break;
        case 1:
            routes = _.slice(routes, index - 1, 5);
            break;
        case routes.length - 2:
            routes = _.slice(routes, index - 3);
            break;
        case routes.length - 1:
            routes = _.slice(routes, index - 4);
            break;
        default:
            routes = _.slice(routes, index - 2, index + 3);
    }

    return routes;
};

export const getActiveStepByDevice = (step, routes) => {
    const index = _.findIndex(routes, ['step', step]);
    let activeStep = step;

    switch (index) {
        case 0:
            activeStep = 0;
            break;
        case 1:
            activeStep = 1;
            break;
        case routes.length - 2:
            activeStep = 3;
            break;
        case routes.length - 1:
            activeStep = 4;
            break;
        default:
            activeStep = 2;
    }

    return activeStep;
};
