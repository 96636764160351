import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Field } from 'redux-form';
import OptionPrix from '../BlocOption/OptionPrix/OptionPrix';
import Options from '../BlocOption/Options/Options';
import {Radio} from "@amo/core/components/forms";

const BlocFranchises = (props) => {
    const { franchises, fractionnement, infoDTA, infoVI, selectedFranchiseVI, selectedFranchiseDTA } = props;

    return (
        <Options title={'Nous proposons le rachat de franchise sur mesure'}>
            <div className={'p-3'}>
                <div className={'row'}>
                    {_.map(franchises.data, (franchise, i) => (
                        <Fragment key={`div-${franchise.value}`}>
                            <div
                                className={`col-12 order-lg-${i} ${
                                    franchises.data.length > 1 &&
                                    (franchises.data.length % 2 === 0 ? 'col-lg-6' : 'col-lg-4')
                                }`}
                            >
                                <div className={'mb-4'}>
                                    <Field
                                        component={Radio}
                                        name={`optionsFranchise[${franchise.value}]`}
                                        value={franchise.value}
                                        type={'checkbox'}
                                        label={franchise.label}
                                        className={classNames(
                                            'checkboxBlue cursor-pointer py-3 big d-flex align-items-center small',
                                        )}
                                    />
                                </div>
                            </div>

                            <div
                                className={`col-12 order-lg-${franchises.data.length + i} ${
                                    franchises.data.length > 1 &&
                                    (franchises.data.length % 2 === 0 ? 'col-lg-6' : 'col-lg-4')
                                }`}
                            >
                                {franchise.dataAffichage.titre && franchise.dataAffichage.text && (
                                    <div className={'mb-4'}>
                                        <div
                                            className={
                                                'f-14 text-uppercase text-center text-primary-light font-weight-bold mb-3'
                                            }
                                        >
                                            {franchise.dataAffichage.titre}
                                        </div>
                                        {franchise.dataAffichage.conditionsIndemnisation && (
                                            <div className={'text-center f-12 mb-3'}>
                                                {franchise.value === 'RAFRAVI' &&
                                                    (selectedFranchiseVI ? (
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: franchise.dataAffichage.conditionsIndemnisation,
                                                            }}
                                                        />
                                                    ) : (
                                                        <strong>{infoVI[0].ConditionsIndemnisationSansRachat}</strong>
                                                    ))}
                                                {franchise.value === 'RAFRADTA' &&
                                                    (selectedFranchiseDTA ? (
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: franchise.dataAffichage.conditionsIndemnisation,
                                                            }}
                                                        />
                                                    ) : (
                                                        <strong>{infoDTA[0].ConditionsIndemnisationSansRachat}</strong>
                                                    ))}
                                            </div>
                                        )}

                                        <div className={'px-4 py-3 bg-secondary-light f-14 text-center'}>
                                            <div className={'text-primary-light f-21 font-weight-bold'}>
                                                <OptionPrix
                                                    valueSelected={true}
                                                    values={[franchise]}
                                                    fractionnement={fractionnement}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
        </Options>
    );
};

BlocFranchises.propTypes = {
    franchises: PropTypes.object,
};

export default BlocFranchises;
