import PropTypes from 'prop-types';
import {Icons} from "@amo/core/components/containers";
import './card.scss';

export const Card = (props) => {
    const { icon, title, children, className } = props;

    return (
        <div className={`card-bloc rounded mt-4 overflow-hidden ${className}`}>
            {(icon || title) && (
                <div className={'d-flex align-items-center bg-secondary-light py-3 px-4'}>
                    {icon && <Icons icon={icon} fontSize={20} className={'pr-3'} />}
                    {title && <span className={'f-14 fw-600'}>{title}</span>}
                </div>
            )}
            <div className={'p-3 f-12'}>{children}</div>
        </div>
    );
};

Card.defaultProps = {
    icon: '',
    title: '',
    className: '',
};

Card.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
