import { APP_NAME } from '@amo/core/constants/constants.js'
import { CLEAR_LOCALSTORAGE, SET_LOCALSTORAGE } from '../../../actions/core/localStorage/localStorage.actions';

export const localStorageMiddleware = () => (next) => (action) => {
    next(action);

    if (action.type.includes(SET_LOCALSTORAGE)) {
        const { storage } = action.meta;

        const name = !action.meta.name ? `${APP_NAME}` : action.meta.name;

        localStorage.setItem(name, JSON.stringify(storage));
    }

    if (action.type.includes(CLEAR_LOCALSTORAGE)) {
        localStorage.clear();
    }
};
