import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {change, clearFields, formValueSelector, getFormValues} from 'redux-form';
import {
    getApporteur1Init, getApporteurInit,
    getPersonnePrincipaleIsSouscripteurDemandeTarifInit,
    getSourceDemandeTarifInit
} from '../../../../../../redux/selectors/init/init.selectors';
import {getDevisOrSubscribe, getListCountries} from '../../../../../../redux/selectors/ui/ui.selectors';
import InformationsConducteur from './InformationsConducteur';
import {setCountries} from "../../../../../../redux/actions/app/ui/ui.actions.js";

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');
    const ville = selector(state, 'DemandeTarif[ListePersonnes][0][Ville]');
    const cp = selector(state, 'DemandeTarif[ListePersonnes][0][CP]');
    const ListePersonnes = selector(state, 'DemandeTarif[ListePersonnes]');
    const conducteurPrincipal = ListePersonnes
        ? _.find(ListePersonnes, { TypePersonne: 'P', RoleConducteur: 'P' })
        : {};

    return {
        data: getFormValues('devis')(state),
        apporteur1: getApporteur1Init(state),
        source: getSourceDemandeTarifInit(state),
        postalCodeCity: ville ? `${ville} ${cp}` : null,
        conducteurEstSouscripteur: conducteurPrincipal
            ? String(conducteurPrincipal.Souscripteur)
            : getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state),
        titulaireCarteGrise: selector(state, 'titulaireCarteGrise'),
        VehiculeImmatricule: selector(state, 'DemandeTarif[Vehicule][VehiculeImmatricule]'),
        permis: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis]'),
        addressNotFound: true,
        devisOrSubscribe: getDevisOrSubscribe(state),
        email: selector(state, 'DemandeTarif[ListePersonnes][0][Email]'),
        emailValidation: selector(state, 'EmailValidation'),
        emailOnError: selector(state, 'emailOnError'),
        countries: getListCountries(state),
        birthCountry: selector(state, 'DemandeTarif[ListePersonnes][0][BirthCountry]'),
        birthCountryRepresentantLegal: selector(state, 'DemandeTarif[ListePersonnes][1][BirthCountry]'),
        ImmatCheck: selector(state, 'immatriculation_check'),
        apporteur: getApporteurInit(state),
        dateNaissance: selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]'),
        immatriculationAlreadySet: selector(state, 'DemandeTarif[Vehicule][Immatriculation]'),
        searchBy: selector(state, `searchBy`),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeValue: (field, value) => change('devis', field, value),
            unsetValue: (field) => clearFields('devis', false, false, field),
            setCountries,
        },
        dispatch,
    );

const InformationsConducteurContainer = connect(mapStateToProps, mapDispatchToProps)(InformationsConducteur);

export default InformationsConducteurContainer;
