import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import {Slide} from "@amo/core/components/forms";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('devis');

    return {
        inputState: selector(state, ownProps.input.name),
    };
};

const SlideContainer = connect(mapStateToProps)(Slide);

export default SlideContainer;
