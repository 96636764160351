import PropTypes from 'prop-types';
import { useEffect } from 'react';
import './question-content.scss';

export const QuestionContent = (props) => {
    const { status, children, setIndexQuestion, name, isComplete, index, value, globalIndex } = props;

    useEffect(() => {
        if (status === 'enabled' && isComplete && globalIndex <= index) {
            setIndexQuestion({ index: index + 1 });
        }

    }, [status, value, index]);

    return (
        <div className={`${status}-step step`} data-name={name}>
            <div className={'children-question-content'}>{children}</div>
        </div>
    );
};

QuestionContent.propTypes = {
    status: PropTypes.oneOf(['enabled', 'disabled']),
};