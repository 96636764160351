import PropTypes from 'prop-types';
import './checkbox.scss';

export const Checkbox = (props) => {
    const { children, input, type, className } = props;

    return (
        <div className={`form-check form-checkbox ${className} d-flex align-items-center`}>
            <input
                className="form-check-input"
                type={type}
                id={type === 'radio' ? `${input.name}-${input.value}` : input.name}
                checked={input.value}
                {...input}
            />
            <label
                className="form-check-label ms-2"
                htmlFor={type === 'radio' ? `${input.name}-${input.value}` : input.name}
            >
                {children}
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    children: PropTypes.node,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
    }).isRequired,
    type: PropTypes.oneOf(['checkbox', 'radio']),
    className: PropTypes.string,
};

Checkbox.defaultProps = {
    children: '',
    type: 'checkbox',
    className: '',
};
