export const LOCALSTORAGE = '[LocalStorage]';

export const SET_LOCALSTORAGE = `${LOCALSTORAGE} Set`;
export const CLEAR_LOCALSTORAGE = `${LOCALSTORAGE} Clear`;

export const setLocalStorage = ({ entity, storage, name }) => ({
    type: `${entity} ${SET_LOCALSTORAGE}`,
    meta: {
        storage,
        entity,
        name,
    },
});

export const clearLocalStorage = ({ entity }) => ({
    type: `${entity} ${CLEAR_LOCALSTORAGE}`,
    meta: {
        entity,
    },
});
