import PropTypes from 'prop-types';
import {Icons} from "@amo/core/components/containers";
import './linkbutton.scss';

export const LinkButton = (props) => {
    const { text, className, right, icon, iconProps, onClick, disabled, ...other } = props;

    return (
        <button
            className={`border-0 linkspe text-primary ${className} mb-0`}
            onClick={onClick}
            disabled={disabled}
            {...other}
            data-test="button"
        >
            {!right && icon && (
                <span className={'icon mr-2 d-inline'} data-test={'iconleft'}>
                    <Icons icon={icon} {...iconProps} />
                </span>
            )}
            <span className={'linktext'} data-test={'text'}>
                {text}
            </span>
            {right && icon && (
                <span className={'icon ml-2 d-inline'} data-test={'iconright'}>
                    <Icons icon={icon} {...iconProps} />
                </span>
            )}
        </button>
    );
};

LinkButton.defaultProps = {
    className: '',
    right: false,
    iconProps: {},
    disabled: false,
};

LinkButton.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    right: PropTypes.bool,
    icon: PropTypes.node,
    iconProps: PropTypes.object,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};