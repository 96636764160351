import _ from 'lodash';
import moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY';

const dateIsValid = (value, format = DATE_FORMAT) => moment(value, format, true).isValid();
const minAge = (value, age) =>
    moment(value, DATE_FORMAT, true).add(age, 'years') >= moment() ? `L'âge minimum est ${age} ans.` : undefined;

/**
 * Fonction OnError retourne une erreur si le field doit declancher une erreur s'il est selectionner
 * @param value
 * @returns {*}
 */
export const onError = (value) => (value === 'onError' ? 'error' : undefined);

export const validateDate = value => ( moment(value, 'DD/MM/YYYY') > moment("01/01/1900") ? undefined : 'La date est invalide');

/**
 * Return validateEmail function
 * @param value
 * @returns {function}
 */
export const validateEmail = (value) => {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? "L'adresse email est invalide"
        : undefined;
};

/**
 * Fonction required pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const required = (value) => (value || value === 0 ? undefined : 'Obligatoire');

/**
 * Fonction required avec affichage d'un message d'erreur pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const requiredWithError = (value) => (value || value === 0 ? undefined : 'Le champs est obligatoire');

/**
 * Fonction required avec affichage d'un message d'erreur pour les Fields date de redux-form
 * @param value
 * @returns {String}
 */
export const requiredDateWithError = (value) => {
    if (moment().diff(value, 'years') > 90) {
        return 'Attention, la date saisie semble erronée.';
    }
    return value && moment.isMoment(value) && dateIsValid(value) ? undefined : 'Le champs est obligatoire';
};

/**
 * Fonction required pour la date du formulaire de rappel en fonction du champ asap
 * @param value
 * @returns {String}
 */
export const requiredWithoutAsap = (value, allValues) =>
    value || allValues.asap ? undefined : 'Le champs est obligatoire';

export const minDateValidator = value => {
    if (moment(value, "DD/MM/YYYY").isBefore(moment().subtract(3, 'years'))) {
        return 'La date ne peut pas être antérieure à ' + moment(moment().subtract(3, 'years')).format('L');
    }
    return undefined;
};

/**
 * Fonction minDateToday pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const minDateToday = (value) =>
    !value || !moment.isMoment(value) || !dateIsValid(value) || moment(value).startOf('day') >= moment().startOf('day')
        ? undefined
        : 'La date est trop récente';

/**
 * Fonction minDateHourToday affiche une erreur si la date et heure sont plus tot qu'actuellement
 * @param value
 * @returns {*}
 */
export const minDateHourToday = (value, allValues) => {
    let heureEffetContrat = _.get(allValues, 'HeureEffetContrat');

    if (!value) {
        return undefined;
    }

    if(heureEffetContrat === undefined && !moment.isMoment(value)){
        const [date, time] = value?.trim().split(' ');

        heureEffetContrat = time
    }

    const valueMoment = moment(value, 'DD/MM/YYYY')
        .hour(moment(heureEffetContrat, 'HH:mm').hour())
        .minute(moment(heureEffetContrat, 'HH:mm').minute())


    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !valueMoment.isValid() ||
    valueMoment.isAfter(moment())
        ? undefined
        : "La date ou l'heure n'est plus valide.";
}


/**
 * Fonction maxDateToday pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDateToday = (value) =>
    moment(value, DATE_FORMAT, true) > moment() ? 'La date renseignée est dans le futur.' : undefined;

/**
 * Fonction minDate36Mois pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const minDate36Mois = (value) =>
    moment(value, DATE_FORMAT, true).add(36, 'months') <= moment()
        ? 'La date renseignée doit être au cours des 3 dernières années.'
        : undefined;

/**
 * Fonction minDate24Mois pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const minDate24Mois = (value) =>
    moment(value, DATE_FORMAT, true).add(24, 'months') <= moment()
        ? 'La date renseignée doit être au cours des 2 dernières années.'
        : undefined;

/**
 * min14Year verifie que la date est supérieur à 14 ans
 * @param value
 * @returns {*}
 */
export const min14Year = (value) => minAge(value, 14);

/**
 * min16Year verifie que la date est supérieur à 14 ans
 * @param value
 * @returns {*}
 */
export const min16Year = (value) => minAge(value, 16);

/**
 * min18Year verifie que la date est supérieur à 18 ans
 * @param value
 * @returns {*}
 */
export const min18Year = (value) => value && minAge(value, 18);

/**
 * Fonction minDateMEC controle si :
 * Moto + Scooter > 80cm3 hors END + TRI + CRO blocage si date inférieure à 01/01/1990
 * @param value
 * @param allValues
 * @returns {string}
 */
export const minDateMEC = (value, allValues) => {
    const produit = _.get(allValues, 'typeVehicule');
    const categorie = allValues.code_categorie;
    const cylindree = _.get(allValues, 'cylindreeVehicule');

    if (
        _.includes(['moto', 'scooter'], produit) &&
        cylindree > 80 &&
        !_.includes(['END', 'TRI', 'CRO'], categorie) &&
        moment(value, DATE_FORMAT, true) < moment('01/01/1990', DATE_FORMAT, true)
    ) {
        return 'Nous ne sommes pas en mesure de faire une proposition pour les 2 roues immatriculés avant 1990.';
    }

    return undefined;
};

/**
 * Fonction minDateMEC controle si :
 * Moto + Scooter > 80cm3 hors END + TRI + CRO blocage si date inférieure à 01/01/1990
 * @param value
 * @param allValues
 * @returns {string}
 */
export const errorOnQuad = (value, allValues) => {
    const produit = _.get(allValues, 'typeVehicule');

    if (_.includes(['quad', 'verte'], produit) && value) {
        return 'Notre produit n’est pas disponible pour les véhicules de société';
    }

    return undefined;
};

/**
 * permisAM vérifie les conditions de blocage pour le Permis AM :
 * Blocage si avant 14 ans
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisAM = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');
    const PermisA1 = _.get(allValues, 'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]');
    const datePermisA1 = _.get(allValues, 'DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]');
    const dateCreaA2 = '01/06/2016';

    if (
        value &&
        PermisA1 &&
        datePermisA1 &&
        moment(datePermisA1).isBefore(value) &&
        moment(value).format('L') !== dateCreaA2
    ) {
        return 'Le conducteur ne peut pas avoir obtenu son permis A1 avant son permis AM.';
    }

    return moment(value, 'DD/MM/YYYY', true) < moment(age, DATE_FORMAT, true).add(14, 'years')
        ? "Vous ne pouvez pas avoir le permis AM (ou BSR) avant d'avoir 14 ans."
        : undefined;
};

/**
 * permisA1 vérifie les conditions de blocage pour le Permis A1 :
 * Blocage si avant 16 ans
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisA1 = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');

    return moment(value, 'DD/MM/YYYY', true) < moment(age, DATE_FORMAT, true).add(16, 'years')
        ? "Vous ne pouvez pas avoir le permis A1 avant d'avoir 16 ans."
        : undefined;
};

/**
 * permisA2 vérifie les conditions de blocage pour le Permis A2 :
 * Blocage si avant 18 ans
 * Blocage si avant 19/01/2013 et soit pas de permis A soit permis A après 19/01/2013
 *
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisA2 = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');
    const dateCreaA2 = moment('2013-01-19');

    if (moment(value, 'DD/MM/YYYY', true) < moment(age, DATE_FORMAT, true).add(18, 'years')) {
        return "Vous ne pouvez pas avoir le permis A2 avant d'avoir 18 ans.";
    }

    if (moment(value, 'DD/MM/YYYY', true) < dateCreaA2) {
        return "La date d'obtention du permis n'est pas valide.";
    }
    return undefined;
};

/**
 * permisA vérifie les conditions de blocage pour le Permis A :
 * Blocage si avant 18 ans
 * Blocage si permis A après 19/01/2013 plus une tolérance de 6 mois (19/06/2013) et que la date permis A2 pas remplie
 * Blocage si permis A après 19/01/2013 plus une tolérance de 6 mois (19/06/2013) et que la date permis A2 est inférieure à 2 ans
 * Blocage Si la date du permis A est inférieur à la date du permis A2
 *
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisA = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');
    const dateCreaA2 = moment('2013-06-19');
    const dateObligationPermisA2 = moment('2016-01-01')
    const PermisA2 = _.get(allValues, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]');
    const datePermisA2 = moment(
        _.get(allValues, 'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]'),
        DATE_FORMAT,
    );

    if (moment(value, DATE_FORMAT, true) < moment(age, DATE_FORMAT, true).add(18, 'years')) {
        return "Vous ne pouvez pas avoir le permis A avant d'avoir 18 ans.";
    }

    if ((!PermisA2 || !datePermisA2) && moment(value, DATE_FORMAT, true) >= dateCreaA2 && moment(value, DATE_FORMAT, true) <= moment(age, DATE_FORMAT, true).add(24, 'years')){
        return "La date d'obtention du permis A2 doit être renseignée. Merci de revenir à l'étape précédente et de sélectionner le permis A2";
    }

    if ((!PermisA2 || !datePermisA2) && moment(value, DATE_FORMAT, true) >= dateCreaA2 && moment(value, DATE_FORMAT, true) >= dateObligationPermisA2) {
        return "La date d'obtention du permis A2 doit être renseignée. Merci de revenir à l'étape précédente et de sélectionner le permis A2";
    }

    if (moment(value, 'DD/MM/YYYY', true) >= dateCreaA2 && datePermisA2 > moment().subtract(2, 'years')) {
        return 'Pour avoir le permis A vous devez avoir deux ans de permis A2.';
    }

    return undefined;
};

/**
 * permisB vérifie les conditions de blocage pour le Permis B :
 * Blocage si avant 17 ans
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisB = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');

    return moment(value, 'DD/MM/YYYY', true) < moment(age, DATE_FORMAT, true).add(17, 'years')
        ? "Vous ne pouvez pas avoir le permis B avant d'avoir 17 ans."
        : undefined;
};

/**
 * maxValeurVehicule verifie les valeurs du vehicule pour Quad et Moto Verte
 * Blocage Quad si > 26 000
 * Blocage Verte si > 18 000
 * @param value
 * @param allValues
 * @returns {*}
 */
export const maxValeurVehicule = (value, allValues) => {
    const produit = _.get(allValues, 'typeVehicule');
    let valMax = null;

    if (produit === 'quad') {
        valMax = 26000;
    } else if (produit === 'verte') {
        valMax = 18000;
    }

    return valMax && Number(String(value).replace(/\s+/g, '')) > valMax
        ? 'La valeur du véhicule doit être inférieure ou égale à ' + valMax + '€.'
        : undefined;
};

/**
 * excludeCodePostal exclue certain code postaux
 * Blocage si commence par 97
 * Blocage si commence par 98
 * @param value
 * @param stopByApp1
 * @returns {*}
 */
export const excludeCodePostal = (value) =>
    _.startsWith(value, '98') ? "Nous ne sommes pas en mesure d'assurer les véhicules de cette région" : undefined;
export const excludeCodePostalWith97 = (value) =>
    _.startsWith(value, '97') || _.startsWith(value, '98')
        ? "Nous ne sommes pas en mesure d'assurer les véhicules de cette région"
        : undefined;

/**
 * minLentghImmatriculation verifie la longueur de l'immat
 * Blocage si longueur < 5
 * @param value
 * @param allValues
 * @returns {*}
 */
export const minLentghImmatriculation = (value) => {
    return value && value.length < 4 ? "L'immatriculation doit comporter minimum 4 caractères" : undefined;
};

export const minLentghTwo = (value) => {
    return value && value.length < 1 ? "La ville doit faire minimium 2 caractères" : undefined;
};

/**
 * validateImmatriculation Valide la plaque immatriculation
 * @param value
 * @param allValues
 * @returns {*}
 */
export const validateImmatriculation = (value) => {
    if (!value) {
        return value;
    }

    const filteredValue = value.replace(/[!@#$%^&*\-(),.?":{}|<> \t\r\n]/g, '');

    // Nouvelle plaque immatriculation : AA123AA ou AA123A ou C254L
    if (/^[a-zA-Z]{1,2} ?-?[0-9]{2,3} ?-?[a-zA-Z]{1,2}$/.test(filteredValue)) {
        return undefined;
    }

    // Ancienne plaque immatriculation : 1234AA12
    if (/^[0-9]{1,4} ?-?[a-zA-Z]{2,3} ?-?[0-9]{2}$/.test(filteredValue)) {
        return undefined;
    }

    // Ancienne plaque corse : 1234AAA1A
    if (/^[0-9]{1,4} ?-?[a-zA-Z]{2,3} ?-?[0-9][a-bA-B]$/.test(filteredValue)) {
        return undefined;
    }

    // Format 123AA12 ou 123AAA12
    if (/^[0-9]{3}[a-zA-Z]{2,3}[0-9]{2}$/.test(filteredValue)) {
        return undefined;
    }

    // Format A12A
    if (/^[a-zA-Z][0-9]{2}[a-zA-Z]$/.test(filteredValue)) {
        return undefined;
    }

    return "La plaque d'immatriculation n'est pas valide";
};

/**
 * validatePhone verifie la longueur du numero de tel et vérifie qu'il commence par 0
 * Blocage si longueur < 10 ou commence par autre chose que 0
 * @param value
 * @returns {*}
 */
export const validatePhone = (value) => {
    if (value && !_.startsWith(value, '0')) {
        return 'Le numéro de téléphone doit commencer par 0';
    }

    return value && _.replace(value, /\s/g, '').length < 10
        ? 'Le numéro de téléphone doit comporter 10 chiffres'
        : undefined;
};

/**
 * Fonction maxDate30Days pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const isBeforeAv = (value, allValues) => {
    const selectedDateMoment = moment(value, 'DD/MM/YYYY');
    const derPiece = moment(_.get(allValues, 'DemandeTarif[Police][DateEffetContrat]'), 'DD/MM/YYYY')
    if (selectedDateMoment.isBefore(derPiece, 'day')) {
        return 'La date ne peut pas être antérieure à la date de la dernière pièce du contrat.';
    } else {
        return undefined
    }

}

/**
 * Fonction maxDate30Days pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDate30Days = value => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm')

    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !moment(valueMoment).isValid() ||
    moment(valueMoment) <= moment().add(30, 'day').set('hour', 23).set('minute', 59).set('second', 59)
        ? undefined
        : 'La date sélectionnée est à plus de 30 jours';
}

/**
 * Fonction maxDate60Days pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDate60Days = (value) => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm');

    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !dateIsValid(valueMoment) ||
    moment(valueMoment) <= moment().add(60, 'day').set('hour', 23).set('minute', 59).set('second', 59)
        ? undefined
        : "La date sélectionnée est à plus de 60 jours";
};

/**
 * Fonction minDateHourToday affiche une erreur si la date et heure sont plus tot que 30 jours
 * @param value
 * @returns {*}
 */
export const minDate30Days = (value) => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm');

    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !dateIsValid(valueMoment) ||
    moment(valueMoment).isAfter(moment().subtract(30, 'day'))
        ? undefined
        : "La date sélectionnée est à plus de 30 jours passée";
};


/**
 * Fonction minDate6Months affiche une erreur si la date et heure sont plus de 6 mois antérieur
 * @param value
 * @returns {*}
 */
export const minDate6Months = (value) => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm');

    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !dateIsValid(valueMoment) ||
    moment(valueMoment).isAfter(moment().subtract(6, 'month'))
        ? undefined
        : "La date sélectionnée est à plus de 6 mois passée";
};

/**
 * Fonction maxDate33Days pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDate33Days = (value) => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm');

    return !valueMoment ||
        !moment.isMoment(valueMoment) ||
        !dateIsValid(valueMoment) ||
        moment(valueMoment) <= moment().add(33, 'day').set('hour', 23).set('minute', 59).set('second', 59)
        ? undefined
        : "La date sélectionnée n'est pas valide";
};

/**
 * minLengthBIC verifie la longueur du code BIC
 * Blocage si longueur < 8
 * @param value
 * @returns {*}
 */
export const minLengthBIC = (value) => {
    return value && value.length !== 8 ? 'Le code BIC doit comporter minimum 8 caractères' : undefined;
};

/**
 * validateIban verifie que les 3 derniers caractère ne comporte pas de lettres
 * Blocage si 3 derniers caractère sont des lettres
 * @param value
 * @returns {*}
 */
export const validateIban = (value) => {
    const lastChar = value.slice(-3);
    return value && !/^\d+$/.test(lastChar) ? "Le numéro IBAN n'est pas valide" : undefined;
};

/**
 * valeurVehicule verifie que la valeur du véhicule est entre 800 et 1000
 * @param value
 * @returns {*}
 */
export const valeurVehicule = value => (value < 800 || value > 6000) ? 'La valeur du véhicule doit être comprise entre 800€ et 6000€ TTC.' : undefined

/**
 * min18Year verifie que la date est inférieur à 6 mois
 * @param value
 * @returns {*}
 */
export const dateMin6Month = value => moment(value) <= moment().subtract(6, 'month') ? 'Nous assurons uniquement les vélos achetés chez un professionnel depuis moins de 6 mois' : undefined

/**
 * Return validateEmailDifferente function
 * @param value
 * @returns {function}
 */
export const validateEmailDifferente = (value, allValues) => {
    return _.get(allValues, 'DemandeTarif[ListePersonnes][0][Email]') === _.get(allValues, 'DemandeDevis[EmailCourtier]')
        ? 'Cette adresse email est déjà utilisée pour la souscription.' : undefined
}

/**
 * minLentghMarquage verifie la longueur du numero de marquage
 * Blocage si longueur < 12
 * @param value
 * @param allValues
 * @returns {*}
 */
export const minLentghMarquage = value => {
    return value && (value.length <3 || value.length > 15)? 'Le numéro de marquage doit comporter entre 3 et 15 caractères' : undefined
}

/**
 * minLengthBIC verifie la longueur du code BIC
 * Blocage si longueur > 11
 * @param value
 * @returns {*}
 */
export const maxLengthBIC = value => {
    return value && value.length > 11 ? 'Le code BIC doit comporter entre 8 et 11 caractères' : undefined
}


export const validateVIN = (value) => {
    return value && value.length !== 7 ? 'Le numéro VIN doit comporter 7 caractères' : undefined;
}

export const isValid = value => !value || !moment.isMoment(value) ? undefined : !moment(value).isValid() && value?._i.length === 10 ? 'La date renseigné est incorrect' : undefined;