
import PropTypes from 'prop-types'
import './champs-obligatoires.scss'


export const ChampsObligatoires = (props) => {
    const { children, className } = props

    return <p className={`champ mb-0 mt-4${className}`}><sup className={'text-danger'}>*</sup> {children}</p>
};

ChampsObligatoires.defaultProps = {
    children: 'champs obligatoires',
    className: ''
};

ChampsObligatoires.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};