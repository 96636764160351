import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js'
import {
    fetchContratStatut,
    FETCH_CHECK_STATUT_CONTRAT,
} from '../../../../../../../redux/actions/app/checkContratStatut/checkContratStatut.actions';
import {
    getIDDevisDevisInit,
    getPaiementHistory,
    getState,
    getToken,
} from '../../../../../../../redux/selectors/init/init.selectors';
import { getLoadingEntity } from '../../../../../../../redux/selectors/ui/ui.selectors';
import {loader} from '@amo/core/components/loaders';
import StatusContrat from './StatusContrat';

const mapStateToProps = (state) => {
    return {
        loaded: getLoadingEntity(state, FETCH_CHECK_STATUT_CONTRAT) === false,
        etapes_paiement: getPaiementHistory(state),
        query: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        contratState: getState(state),
        id_devis: getIDDevisDevisInit(state),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchContratStatut,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps;
    const { fetchContratStatut } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        checkContratStatut: () => fetchContratStatut({ query }),
        load: () => fetchContratStatut({ query }),
    };
};

const StatusContratContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
)(StatusContrat);

export default StatusContratContainer;
