export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';

export const apiRequest = ({ body, method, url, entity, otherData }) => ({
    type: `${entity} ${API_REQUEST}`,
    payload: {
        data: body,
        meta: {
            method,
            url,
            entity,
            otherData,
        },
    },
});

export const apiSuccess = ({ response, entity, otherData }) => ({
    type: `${entity} ${API_SUCCESS}`,
    payload: {
        data: response,
        meta: {
            entity,
            otherData,
        },
    },
});

export const apiError = ({ error, entity, otherData }) => ({
    type: `${entity} ${API_ERROR}`,
    payload: {
        data: error,
        meta: {
            entity,
            otherData,
        },
    },
});
