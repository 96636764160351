import { SET_DEMANDE_TARIF } from '../../actions/app/demandeTarif/demandeTarif.actions';

export const demandeTarifReducer = (demandeTarif = {}, action) => {
    const { payload } = action;

    switch (action.type) {
        case SET_DEMANDE_TARIF:
            return payload.data;

        default:
            return demandeTarif;
    }
};
