import { APP_ENV } from '@amo/core/constants';
import { sentryInit } from '@amo/core/utils/vitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import 'toastr/build/toastr.css';
import AppProvider from './AppProvider';
import '@amo/core/assets/scss/index.scss';
import './assets/scss/variables.scss'

window.dataLayer = window.dataLayer || [];

window.dataLayer.push({
    environnement: APP_ENV,
    ap1: 43397,
});

const container = document.getElementById('root');
const root = createRoot(container);

if (APP_ENV) {
    root.render(<AppProvider />);
} else {
    root.render(<h1>.env requis à la racine du projet, un npm start est nécessaire.</h1>);
}

sentryInit();
