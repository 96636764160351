import {useState} from 'react';
import {Field} from "redux-form";
import {excludeCodePostal, required, validateEmail, validatePhone} from "@amo/core/utils/validateField";
import PersonneFields from "../InformationsConducteur/PersonneFields/PersonneFields";
import {normalizeTel} from '@amo/core/utils/normalize';
import PopupRecontact from "../PopupRecontact/PopupRecontact";
import {Text, PostalCodeCity} from "@amo/core/components/forms"
import {QuestionLabel, SimpleTitle} from "@amo/core/components/containers";
import SIREN from "../InformationsConducteur/SIREN.jsx";

const Recontact = (props) => {
    const { source, postSaveDevis, changeValue, postalCodeCity, conducteurEstSouscripteur, titulaireCarteGrise, valid, handleSubmit, saveDevisLoading, fillLoading, devisOrSubscribe, saveDevisError, data } = props
    const [showPopupDevis, setShowPopupDevis] = useState(false)

    const postSaveDevisAndShowModal = (form) => {
        setShowPopupDevis(true)
        postSaveDevis(form)
    }

    return (
        <div className={'row'}>
            <div className={'col-8 offset-2'}>
                <div className={'bg-warning p-4 rounded text-left mb-4'}>
                    <p>Les informations que vous avez saisies ne nous permettent pas de vous proposer un tarif en ligne.</p>
                    <p>L’un de nos conseillers peut vous contacter afin de vous proposer une solution d'assurance adaptée à vos besoins</p>
                </div>

                <SimpleTitle>Être recontacté</SimpleTitle>
                {
                    titulaireCarteGrise && titulaireCarteGrise === '2' ?
                        <SIREN changeValue={changeValue} source={source} />
                        : (conducteurEstSouscripteur === "false" ?
                            <PersonneFields personneIndex={1} changeValue={changeValue} {...props} data={data} /> :
                            <PersonneFields personneIndex={0} changeValue={changeValue} {...props} data={data}/>
                        )

                }

                <QuestionLabel className={'f-14 mt-4'} required>Adresse&nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                        <Field
                            component={Text}
                            name={'DemandeTarif[ListePersonnes][0][Adresse1]'}
                            normalize={v => v.toUpperCase()}
                            validate={required}
                        />
                    </div>
                </div>

                <QuestionLabel className={'f-14 mt-4'}>Complément d'adresse</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                        <Field
                            component={Text}
                            name={'DemandeTarif[ListePersonnes][0][Adresse2]'}
                            normalize={v => v.toUpperCase()}
                        />
                    </div>
                </div>

                <QuestionLabel className={'f-14 mt-4'}>Complément d'adresse (2)</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                        <Field
                            component={Text}
                            name={'DemandeTarif[ListePersonnes][0][Adresse3]'}
                            normalize={v => v.toUpperCase()}
                        />
                    </div>
                </div>

                <QuestionLabel className={'f-14 mt-4'} required>Ville / Code postal</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-2'} data-name="DemandeTarif[ListePersonnes][0][Ville]">
                        <PostalCodeCity
                            icon={'pin'}
                            id={'coordonnees'}
                            nameVille={'DemandeTarif[ListePersonnes][0][Ville]'}
                            changeValue={changeValue}
                            nameCodePostal={'DemandeTarif[ListePersonnes][0][CP]'}
                            validate={[excludeCodePostal]}
                            defaultValue={postalCodeCity}
                            required
                        />
                    </div>
                </div>

                <QuestionLabel className={'f-14 mt-4'} required>N° de téléphone&nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                        <Field
                            component={Text}
                            name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                            icon={'phone'}
                            iconSize={18}
                            validate={[required, validatePhone]}
                            normalize={normalizeTel}
                            onChange={e => changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value) )}
                        />
                    </div>
                </div>
                <p className={'m-0 text-grey'}><small>Il sera utilisé dans le cadre de la gestion de son contrat</small></p>

                <QuestionLabel className={'f-14 mt-4'} required>Adresse e-mail &nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                        <Field
                            component={Text}
                            name={'DemandeTarif[ListePersonnes][0][Email]'}
                            icon={'envelope'}
                            validate={[required, validateEmail]}
                            iconSize={18}
                            onChange={e => changeValue('DemandeContrat[SignatureEmail]', e.target.value )}
                        />
                    </div>
                </div>
                <p className={'m-0 text-grey'}><small>En nous communiquant son adresse e-mail, il accepte que la gestion et l’exécution de son contrat soient réalisées par voie dématérialisée : il peut y mettre un terme à tout moment et sans frais en se rendant sur son Espace Assuré.</small></p>
                
                <QuestionLabel className={'f-14 mt-4'} required>Confirmez l'adresse e-mail &nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                        <Field
                            component={Text}
                            name={'EmailValidation'}
                            icon={'envelope'}
                            validate={[required, validateEmail]}
                            iconSize={18}
                        />
                    </div>
                </div>

                {
                    conducteurEstSouscripteur === "false" &&
                    <>
                        <SimpleTitle className={'mt-5'}>Le conducteur</SimpleTitle>
                        <PersonneFields data={data} personneIndex={0} changeValue={changeValue} {...props}/>
                    </>
                }

                <div className={'submit-row mt-5'}>
                    <button
                        className={`${!valid && 'disabled'} btn btn-primary medium-rounded float-right mb-2 order-lg-1`}
                        onClick={valid ? handleSubmit(form => postSaveDevisAndShowModal(form)) : null}
                        data-id="btn-next-info-step"
                    >
                        {
                            saveDevisLoading || fillLoading
                                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                : 'Être recontacté'
                        }
                    </button>
                </div>

                {
                    devisOrSubscribe === 'devis' && saveDevisError === false && saveDevisLoading === false && showPopupDevis && <PopupRecontact />
                }

            </div>
        </div>
    );
};


export default Recontact;
