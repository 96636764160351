import { SET_DEMANDE_TARIF_AFTER_GET_TARIF, SET_INIT } from '../../actions/app/init/init.actions';

export const initReducer = (init = {}, action) => {
    const { payload } = action;

    switch (action.type) {
        case SET_INIT:
            return payload.data;

        case SET_DEMANDE_TARIF_AFTER_GET_TARIF:
            return { ...init, data: { ...init.data, DemandeTarif: payload.data } };

        default:
            return init;
    }
};
