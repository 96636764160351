import './small-spinner.scss';

export const SmallSpinner = () => {
    return (
        <div className="loading">
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
        </div>
    );
};
