import _ from 'lodash';
import optionsTextes from '@amo/core/utils/json/options.json';

export const reformatGaranties = (garantiesFormule, fractionnement, typeVehicule) => {
    if (garantiesFormule.length > 0) {
        const idGroupe = _.head(garantiesFormule).IdGroupeGarantie;
        const textesGroupe = optionsTextes[typeVehicule][idGroupe];

        const listeGarantiesSelectable = _.map(garantiesFormule, (g) => {
            const text_garantie =
                textesGroupe && textesGroupe.options ? _.find(textesGroupe.options, ['id', g.CodeGarantie]) : null;

            let prix_promo = null;
            let listeTarifOption = null;

            if (g.listeTarifOption !== undefined && g.IdGroupeGarantie === 'ACCESSEQP') {
                listeTarifOption = _.map(_.orderBy(g.listeTarifOption, ['OrdreAffichage'], ['asc']), (option) => {
                    if (fractionnement === 'M') {
                        prix_promo = option.PrimeClientMensuellePromo;
                    } else if (fractionnement === 'A') {
                        prix_promo = option.PrimeClientPromo;
                    }

                    return {
                        value: option.DetailOption,
                        label: option.ConditionsIndemnisation,
                        dataAffichage: {
                            prix: fractionnement === 'M' ? option.PrimeClientMensuelle : option.PrimeClient,
                            prix_promo: prix_promo,
                        },
                    };
                });
            }

            if (fractionnement === 'M') {
                prix_promo = g.PrimeClientMensuellePromo;
            } else if (fractionnement === 'A') {
                prix_promo = g.PrimeClientPromo;
            }

            return {
                listeTarifOption: listeTarifOption,
                value: g.CodeGarantie,
                label: g.LibelleBouton,
                dataAffichage: {
                    ...text_garantie,
                    titre: g.LibelleGarantie,
                    prix: fractionnement === 'M' ? g.PrimeClientMensuelle : g.PrimeClient,
                    prix_promo: prix_promo,
                    conditionsIndemnisation: g.ConditionsIndemnisation || '',
                },
            };
        });
        if (listeGarantiesSelectable.length > 0) {
            return {
                ...textesGroupe,
                idGroupe: idGroupe,
                labelGroupe: _.head(garantiesFormule).LibelleGroupeGarantie,
                data: listeGarantiesSelectable,
            };
        }
    }

    return [];
};
