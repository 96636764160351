import { connect } from 'react-redux';
import {
    getContratInit,
    getDocumentsUrlInit,
    getImmatriculationVehiculeDemandeTarifInit,
    getListeDocumentsContratInit,
    getSourceDemandeTarifInit,
    getUrlAttestationAssurance,
    getUrlMemo,
    getUrlPdfContrat,
    getUrlPDFFicheConseilInit,
    getUrlPdfHamon,
    getUrlPdfSepa,
} from '../../../../../../redux/selectors/init/init.selectors';
import Signature from './Signature';

const mapStateToProps = (state) => {
    return {
        documentsUrl: getDocumentsUrlInit(state),
        listeDocuments: getListeDocumentsContratInit(state),
        contract: getContratInit(state),
        urlPdfContrat: getUrlPdfContrat(state),
        urlPdfSepa: getUrlPdfSepa(state),
        urlPdfHamon: getUrlPdfHamon(state),
        source: getSourceDemandeTarifInit(state),
        urlFicheConseil: getUrlPDFFicheConseilInit(state),
        immatriculation: getImmatriculationVehiculeDemandeTarifInit(state),
        urlMemo: getUrlMemo(state),
        urlAttestationAssurance: getUrlAttestationAssurance(state)
    };
};

const SignatureContainer = connect(mapStateToProps)(Signature);

export default SignatureContainer;
