import { Text } from '@amo/core/components/forms';
import { getCity, getCityBySearch, postLogPostalCode } from '@amo/core/utils/requestsApi';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import { Field } from 'redux-form';

export const PostalCodeCity = (props) => {
    const {
        id,
        nameVille,
        changeValue,
        nameCodePostal,
        defaultValue,
        validate,
        placeholder,
        label,
        required,
        disabled,
        token,
        canal,
        onChange
    } = props;

    const [city, setCity] = useState([]);
    const [selected, setSelected] = useState(false);
    const [labelSelected, setLabelSelected] = useState(defaultValue);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (defaultValue) setLabelSelected(defaultValue)
    }, [defaultValue])

    const formatObj = (codePostal, villes) => {
        return villes.map((ville) => ({ ville, codePostal }));
    };

    const fetchCityByPostalCode = async (value) => {
        let cities = await getCity(value, setLoading);
        setCity(formatObj(value, cities));
    };

    const fetchCityBySearch = async (value) => {
        let cities = await getCityBySearch(value, setLoading);
        setCity(cities);
    };

    const logPostalCode = async (value) => {
        await postLogPostalCode(canal, token, value);
    };

    const searchCity = (value) => {
        changeValue(nameVille, '');
        changeValue(nameCodePostal, '');
        setSelected(false);
        setLabelSelected(value);
        onChange && onChange(value)

        if (_.parseInt(value)) {
            value.length === 5 && fetchCityByPostalCode(value);
        } else {
            value.length >= 3 && fetchCityBySearch(value);
        }

        if (_.startsWith(value, '97') && value.length === 5 && id === 'lieuStationnement') {
            logPostalCode(value);
        }
    };

    const setCityAndPostalCode = (clickCity) => {
        changeValue(nameVille, clickCity.ville);
        changeValue(nameCodePostal, clickCity.codePostal);
        changeValue(
            `postalCodeCity${id ? `-${id}` : ''}`,
            `${clickCity.codePostal} ${_.startCase(_.toLower(clickCity.ville))}`,
        );
        setSelected(true);
        setLabelSelected(`${clickCity.codePostal} ${_.startCase(_.toLower(clickCity.ville))}`);
    };

    return (
        <>
            {label && (
                <label className={'label-text mb-1'}>
                    {label}
                    {required ? <sup className={'text-danger'}>*</sup> : ''}
                </label>
            )}
            <Field
                name={`postalCodeCity${id ? `-${id}` : ''}`}
                component={Text}
                typeFormat={'postal'}
                input={{
                    name: `postalCodeCity${id ? `-${id}` : ''}`,
                    valueInput: labelSelected,
                    onChange: (e) => searchCity(e.target.value),
                }}
                validate={validate}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
            />

            {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}

            {!selected && (
                <div className="input-text-content">
                    <div className="list-group">
                        {city.map((c, index) => (
                            <li
                                className={'text-start cursor-pointer list-group-item list-group-item-action'}
                                key={index}
                                value={c.ville}
                                onClick={() => setCityAndPostalCode(c)}
                            >
                                {c.codePostal} {_.startCase(_.toLower(c.ville))}
                            </li>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

PostalCodeCity.propTypes = {
    id: PropTypes.string,
    nameVille: PropTypes.string.isRequired,
    changeValue: PropTypes.func.isRequired,
    nameCodePostal: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    validate: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    token: PropTypes.string,
    session_id: PropTypes.string,
    canal: PropTypes.string,
};

PostalCodeCity.propTypes = {
    id: PropTypes.string,
    nameVille: PropTypes.string,
    changeValue: PropTypes.func,
    nameCodePostal: PropTypes.string,
    defaultValue: PropTypes.string,
};
