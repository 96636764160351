import _ from 'lodash';
import { useEffect, useState } from 'react';
import {Icons} from "@amo/core/components/containers";
import {SmallSpinner} from "@amo/core/components/loaders";

const StatusContrat = (props) => {
    const {
        checkContratStatut,
        etapes_paiement,
        contratState,
        id_devis,
        valid,
        handleSubmit,
        postPaySubmit,
        payLoaded,
        previousStep,
    } = props;
    const [secondesAvtRefresh, setSecondesAvtRefresh] = useState(60);

    useEffect(() => {
        let interval = null;

        if (secondesAvtRefresh > 0) {
            interval = setInterval(() => {
                setSecondesAvtRefresh((seconds) => seconds - 1);
            }, 1000);
        } else {
            checkContratStatut();
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [secondesAvtRefresh, checkContratStatut]);

    return (
        <div>
            <h1 className="title h2 mb-4 text-center">
                Contrat en{' '}
                {contratState === 'contrat_en_attente_de_paiement' ? 'attente de paiement' : "cours d'enregistrement"}
                ...
            </h1>
            <div className={'mb-4 text-center'}>Devis {id_devis}</div>

            {contratState === 'contrat_en_attente_de_paiement' && (
                <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                    <p className={'mb-0'}>
                        Attention, votre contrat ne sera pas validé si votre paiement n'est pas finalisé dans les 48h.
                    </p>
                </div>
            )}

            <div className={'my-5 border-bottom'}>
                {etapes_paiement &&
                    _.map(etapes_paiement, (etape, index) => (
                        <div key={index} className={'d-flex py-3 border-top align-items-center'}>
                            {etape.date ? (
                                <Icons
                                    className={`checkSuccess mediumBlocIcons border full-rounded border-success d-inline-block position-relative align-middle`}
                                    fontSize={15}
                                    icon={'Shape'}
                                />
                            ) : (
                                <SmallSpinner />
                            )}
                            <span className={'d-inline-block ml-4'}>{etape.name}</span>
                        </div>
                    ))}
            </div>

            <div className={'text-center mb-5'}>
                <button className={`btn btn-primary medium-rounded`} onClick={checkContratStatut}>
                    {secondesAvtRefresh > 0 ? `Actualiser (${secondesAvtRefresh})` : 'Actualisation...'}
                </button>
            </div>

            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn btn-primary medium-rounded float-right mb-2 order-lg-1`}
                    data-id="btn-souscription"
                    onClick={valid ? handleSubmit(postPaySubmit) : null}
                >
                    {!!payLoaded ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Je finalise la souscription immédiatement'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
        </div>
    );
};

StatusContrat.defaultProps = {};

StatusContrat.propTypes = {};

export default StatusContrat;
