import {
    API_URL_WHEELS,
    API_URL_WS_FORMULAIRE,
    APP_URL_PASS,
    GRAVITEE_API_KEY_IBAN,
    GRAVITEE_URL,
    SERVICES_URL,
} from '@amo/core/constants/constants';
import axios from 'axios';

const makeApiCall = (url, method = 'GET', setLoading, headers = {}) => {
    setLoading && setLoading(true);
    return new Promise((resolve, reject) => {
        axios({ method, url, headers })
            .then((response) => {
                setLoading && setLoading(false);
                resolve(response.data.data || response.data);
            })
            .catch((error) => {
                setLoading && setLoading(false);
                if (error.response && error.response.status === 500) {
                    console.error('Server Error: 500 - ', error.response.data.message);
                    resolve(error.response);
                } else {
                    console.error('Une erreur est survenue:', error);
                    reject(error);
                }
            });
    });
};

export const getVehiculeWheelsByType = (type, setLoading) =>
    makeApiCall(`${API_URL_WHEELS}/vehicles?type=${type}`, 'GET', setLoading);

export const getCity = (value, setLoading) => makeApiCall(`${APP_URL_PASS}/towns/${value}`, 'GET', setLoading);

export const getCityBySearch = (value, setLoading) =>
    makeApiCall(`${APP_URL_PASS}/towns/search/${value}`, 'GET', setLoading);

export const getCompanys = () => makeApiCall(`${APP_URL_PASS}/insurers`);

export const getCountries = (setLoading) => makeApiCall(`${APP_URL_PASS}/countries`, 'GET', setLoading);

export const getCitiesFromFrance = ({ setLoading, value, request = 'SearchBirthplaceWS', setErrorLoadingCountries }) => {
    const url = `${SERVICES_URL}/check-birth?value=${value}&function=${request}`;
    return makeApiCall(url, 'GET', setLoading).then((data) => {
        setErrorLoadingCountries && setErrorLoadingCountries(false)

        if (data && data.status === 500) {
            return data
        }

        if (data instanceof Array) {
            return data?.filter((item) => {
                const birthplace = item.sBirthplace.toUpperCase();
                const hasParis = birthplace.startsWith('PARIS');
                const hasMarseille = birthplace.startsWith('MARSEILLE');
                const hasLyon = birthplace.startsWith('LYON');

                return (
                    !(hasParis || hasMarseille || hasLyon) ||
                    birthplace === 'PARIS' ||
                    birthplace === 'MARSEILLE' ||
                    birthplace === 'LYON'
                );
            });
        } else {
            return [];
        }
    }).catch(error => {
        setErrorLoadingCountries && setErrorLoadingCountries(true)
    });
};

/**
 * Post Log Consent
 */
export const postLogConsent = ({ token, phone }) => {
    return new Promise((resolve) => {
        axios
            .post(`${API_URL_WS_FORMULAIRE}/consent/log`, {
                token: token,
                phone: phone.replace(/ /g, ''),
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    });
};

/**
 * Post Log Postal Code
 */
export const postLogPostalCode = (canal, token, cp) => {
    return new Promise((resolve) => {
        axios
            .post(`${API_URL_WS_FORMULAIRE}/DROM-COM/log`, {
                canal: canal,
                cp: cp,
                token: token,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    });
};

/**
 * Post Recontact Drom
 * use
 * async postRecontactDrom(value){
 *      let result = await postRecontactDrom(data)
 *      console.log(result)
 * }
 * @param data
 * @param setLoading
 * @returns {object}
 */
export const postRecontactDrom = (
    params,
    setLoading = (bool) => console.log(`loading post recontact drom: ${bool}`),
    setEmailSend,
) => {
    setLoading(true);
    return new Promise((resolve) => {
        axios
            .post(`${API_URL_WS_FORMULAIRE}/recontact/drom`, params)
            .then((response) => {
                setLoading(false);
                setEmailSend(true);
                resolve(response.data);
            })
            .catch((error) => {
                setLoading(false);
                setEmailSend(false);
                console.log(error);
            });
    });
};

/**
 * Check iban
 * use
 * async fetchCheckIban(value){
 *      let iban = await checkIban(value)
 *      console.log(iban)
 * }
 * @param value
 * @returns {object}
 */
export const checkIban = (value) => {
    return new Promise((resolve) => {
        axios({
            method: 'GET',
            url: `${GRAVITEE_URL}/ibancheck/?iban=${value}`,
            headers: {
                'X-Gravitee-Api-Key': GRAVITEE_API_KEY_IBAN,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    });
};
/**
 * Get Address
 * use
 * async fetchAddress(value){
 *      let address = await getAddress(value)
 *      console.log(address)
 * }
 * @param value, validate, locality, localityId, postalCode
 * @returns {object}
 */
export const getAddress = (value, validate = 'Undefined', localityId = null, postalCode = null, locality = null) => {
    return new Promise((resolve) => {


        const params = {
            value: encodeURIComponent(value),
            validate,
            localityId,
            postalCode
        };

        const filteredParams = Object.entries(params)
            .filter(([key, val]) => val !== null)
            .map(([key, val]) => `${key}=${val}`)
            .join('&');

        const url = `${SERVICES_URL}/check-address?${filteredParams}`;

        axios.get(url)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 500) {
                    console.error('Server Error: 500 - ', error.response.data.message);
                    resolve(error.response);
                } else {
                    console.error('Une erreur est survenue:', error);
                }
            });
    });
};

/**
 * get Raison Sociale By Siren
 *
 * @param siren
 * @returns {Promise<unknown>}
 */
export const getRaisonSocialeBySiren = async (siren) => {
    return axios({
        method: 'GET',
        url: `${SERVICES_URL}/company/${siren}?onlyCompany=true`,
    }).then((response) => response.data);
};

/**
 * get Raison Sociale By Siren
 *
 * @param siren
 * @returns {Promise<unknown>}
 */
export const getRaisonSocialeBySirenV2 = async (siren) => {
    return axios({
        method: 'GET',
        url: `${SERVICES_URL}/company/${siren}?min=true`,
    }).then((response) => response.data);
};

