import PropTypes from 'prop-types'
import './title.scss'

export const SimpleTitle = (props) => {
    const { children, className } = props

    return <p className={`simple-title ${className}`}> {children}</p>;
};

SimpleTitle.defaultProps = {
    className: '',
    children: 'Titre de l\'étape'
};

SimpleTitle.propTypes = {
    children: PropTypes.node,
};
