import './header.scss';

const HeaderError = ({message}) => {
    return (
        <div className={'header header-error'}>
            <div className={'text-center m-auto position-relative container'}>
                <div className={'row'}>
                    <div className={'col-md-6 offset-md-3 bg-white bg-white pt-5 pb-5'}>
                        <p className="header-title">
                            {message}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderError;
