export const SAVE_DEVIS = '[Save Devis]';

export const POST_SAVE_DEVIS = `${SAVE_DEVIS} Post`;

export const postSaveDevis = ({ form, query, devisOrSubscribe, step }) => ({
    type: POST_SAVE_DEVIS,
    payload: {
        data: query,
        body: form,
        meta: {
            otherData: {
                devisOrSubscribe,
                step,
            },
        },
    },
});
