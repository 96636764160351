import _ from 'lodash';
import PropTypes from 'prop-types';
import './question-label.scss';
import classNames from 'classnames';

export const QuestionLabel = (props) => {
    const { children, className, required } = props;

    return (
        <p className={classNames('question-label', className)}>
            <span dangerouslySetInnerHTML={{ __html: _.isArray(children) ? children[0] : children }} />
            <span style={{whiteSpace: 'nowrap'}}>
                {_.isArray(children) && children[1]} {_.isArray(children) && children[2]}
                {required && <small className={'text-error text-danger f-16'}>*</small>}
            </span>
        </p>
    );
};

QuestionLabel.defaultProps = {
    children: 'QuestionLabel pour le client',
    className: '',
};

QuestionLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
