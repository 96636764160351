import { setDemandeTarifAfterGetTarif } from '../../../actions/app/init/init.actions';
import { POST_TARIF, POST_TARIF_RELOAD, setTarif, TARIF, TARIF_RELOAD } from '../../../actions/app/tarif/tarif.actions';
import { apiRequest, API_ERROR, API_SUCCESS } from '../../../actions/core/api/api.actions';
import { formatDataForApi } from '../fill/formatDataForApi';
import {
    setError,
    setErrorTarif,
    setLoader, setMaxStep, setStep,
} from '../../../actions/app/ui/ui.actions'
import * as Sentry from '@sentry/react';
import { getSlugStep } from '../../../../utils/function'


export const tarifMiddleware = () => (next) => (action) => {
    next(action);

    const { payload } = action;
    let nextActions = [];
    let body = {};

    switch (action.type) {
        case POST_TARIF:
            if (payload.body) {
                body = formatDataForApi(payload.body);
            }

            next([
                apiRequest({ body: body, method: 'POST', url: payload.data, entity: TARIF }),
                setLoader({ state: true, entity: TARIF }),
                setErrorTarif({ data: '' }),
            ]);
            break;

        case `${TARIF} ${API_SUCCESS}`:
            nextActions = [
                setTarif({data: payload.data.data.Tarif}),
                setDemandeTarifAfterGetTarif({data: payload.data.data.DemandeTarif}),
                setError({state: false, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ]
            if(payload.data.data.Tarif.FeuTricolore === 'Orange' && (payload.data.data.source === 'courtier' || payload.data.data.source === 'concessionnaire')){
                nextActions.push(setStep({step: getSlugStep('recontact')}))
                nextActions.push(setMaxStep({maxStep: getSlugStep('recontact')}))
            }
            next(nextActions);
            break;

        case `${TARIF} ${API_ERROR}`:
            next([
                setErrorTarif({ data: payload.data.response.data.error }),
                setError({ state: true, entity: TARIF }),
                setLoader({ state: false, entity: TARIF }),
            ]);
            break;

        case POST_TARIF_RELOAD:
            if (payload.body) {
                body = formatDataForApi(payload.body);
            }

            next([
                apiRequest({ body: body, method: 'POST', url: payload.data, entity: TARIF_RELOAD }),
                setLoader({ state: true, entity: TARIF }),
            ]);
            break;

        case `${TARIF_RELOAD} ${API_SUCCESS}`:
            nextActions = [
                setTarif({data: payload.data.data.Tarif}),
                setDemandeTarifAfterGetTarif({data: payload.data.data.DemandeTarif}),
                setError({state: false, entity: TARIF})
            ];

            if(payload.data.data.Tarif.FeuTricolore === 'Orange' && (payload.data.data.source === 'courtier' || payload.data.data.source === 'concessionnaire')){
                nextActions.push(setStep({step: getSlugStep('recontact')}))
                nextActions.push(setMaxStep({maxStep: getSlugStep('recontact')}))
                setTimeout(() => nextActions.push(setLoader({state: false, entity: TARIF})), 3000)
            } else {
                nextActions.push(setLoader({state: false, entity: TARIF}))
            }

            next(nextActions);
            break;

        case `${TARIF_RELOAD} ${API_ERROR}`:
            try {
                next([
                    setErrorTarif({ data: payload.data.response ? payload.data.response.data.error : "Une erreur de tarif est survenue"}),
                    setError({ state: true, entity: TARIF }),
                    setLoader({ state: false, entity: TARIF }),
                ]);
            } catch (error) {
                Sentry.captureException(error, {
                    extra: {
                        action,
                        payload,
                    }
                });
            }
            break;

        default:
            return null;
    }
};
