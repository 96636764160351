import info from '@amo/core/assets/images/icons/info.svg';
import PropTypes from 'prop-types';
import './bulle-aide.scss';

export const BulleAide = (props) => {
    const { title, text, icon, className, type } = props;

    return (
        <div
            className={`${type === 'danger' ? 'bg-danger-subtle' : 'bg-secondary-light'} p-3 bulle-aide text-left ${
                className ? className : 'mt-3 col-lg-8 offset-lg-2 col-md-12'
            }`}
        >
            <div className={'d-flex align-items-center gap-4'}>
                {icon && <img src={icon} alt={'Informations complémentaires'} />}
                <div className={'text-start ms-4'}>
                    <p className={'f-16 fw-bold mb-2'} dangerouslySetInnerHTML={{ __html: title }} />
                    <p className={'f-13'} dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        </div>
    );
};

BulleAide.defaultProps = {
    title: '',
    text: '',
    icon: null,
};

BulleAide.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
};
