import { reset } from 'redux-form';
import { CONTACT } from '../../../actions/app/contact/contact.actions';
import { setError, setLoader } from '../../../actions/app/ui/ui.actions';
import { apiRequest, API_ERROR, API_SUCCESS } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';

export const contactMiddleware = (dispatch) => (next) => (action) => {
    next(action);

    const { payload } = action;

    switch (action.type) {
        case CONTACT:
            next([
                apiRequest({ body: payload.body, method: 'POST', url: payload.data, entity: CONTACT }),
                setLoader({ state: true, entity: CONTACT }),
            ]);

            break;

        case `${CONTACT} ${API_SUCCESS}`:
            next([
                setError({ state: false, entity: CONTACT }),
                setLoader({ state: false, entity: CONTACT }),
                setNotification({
                    entity: CONTACT,
                    body: null,
                    type: 'success',
                    title: 'Merci, votre demande a bien été prise en envoyé.',
                }),
                reset('contact'),
            ]);

            break;

        case `${CONTACT} ${API_ERROR}`:
            next([setError({ state: true, entity: CONTACT }), setLoader({ state: false, entity: CONTACT })]);
            break;

        default:
            return null;
    }
};
