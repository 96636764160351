export const ANALYTICS = '[Analytics]';

export const SET_ANALYTICS = `${ANALYTICS} Set`;
export const SET_ANALYTICS_ACTION = `${ANALYTICS} Set Action`;

export const setAnalytics = ({ entity }) => ({
    type: `${entity} ${SET_ANALYTICS}`,
    payload: {
        entity,
    },
});

export const setTrackPage = ({ page }) => ({
    type: `${ANALYTICS} ${page}`,
    meta: {
        entity: ANALYTICS,
        analytics: {
            event: 'Page View',
            actionGoogle: page,
            pageMatomo: page,
        },
    },
});

export const setTrackAction = ({
    event,
    actionGoogle,
    libelle,
    categorie,
    valeur,
    unique,
    titleMatomo,
    valueMatomo,
}) => ({
    type: SET_ANALYTICS_ACTION,
    meta: {
        analytics: {
            event,
            actionGoogle,
            libelle,
            categorie,
            valeur,
            unique,
            titleMatomo,
            valueMatomo,
        },
    },
});
