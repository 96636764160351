import PropTypes from 'prop-types';
import './radioinline.scss';

export const RadioInline = (props) => {
    const { children, input } = props;

    return (
        <div className="form-check form-checkbox">
            <label
                className={`cursor-pointer form-check-label d-flex justify-content-start ${
                    input.checked ? 'input-checked' : ''
                }`}
            >
                
                <input
                    className="cursor-pointer form-check-input"
                    type={'radio'}
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    checked={input.checked}
                />
                {children}
            </label>
        </div>
    );
};

RadioInline.propTypes = {
    children: PropTypes.node.isRequired,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        checked: PropTypes.bool.isRequired,
    }).isRequired,
};
