import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {Icons} from '@amo/core/components/containers';
import './slide.scss';

export const Slide = (props) => {
    const {
        symbole,
        input,
        inputState,
        disabled,
        step,
        marks,
        min,
        max,
        meta: { touched, invalid, error },
        minBackground,
        maxBackground,
        minColor,
        maxColor,
    } = props;
    const getNumberToConvertInInteger = (float) => {
        let nbapresvirgule = float.toString().split('.').slice(-1)[0].length;
        let number = '1';

        for (let i = 0; i < nbapresvirgule; i++) {
            number += '0';
        }

        return parseInt(number);
    };
    const nb_conversion = getNumberToConvertInInteger(step);
    const startVal = (0.5 * min + 0.5 * max).toFixed(nb_conversion);
    const [value, setValue] = useState(input.value ? parseFloat(input.value).toFixed(nb_conversion) : null);

    const handleChange = (event, newValue) => {
        const floatValue = parseFloat(newValue);
        const roundedValue = Number.isInteger(floatValue) ? Math.round(floatValue) : floatValue.toFixed(nb_conversion);

        setValue(roundedValue);
        input.onChange(roundedValue);
    };

    const plus = () => {
        let currentVal = inputState === undefined ? startVal : inputState;
        if (currentVal + step <= max) {
            handleChange(null, Math.round((currentVal + step) * nb_conversion) / nb_conversion);
        }
    };

    const moins = () => {
        let currentVal = inputState === undefined ? startVal : inputState;
        if (currentVal - step >= min) {
            handleChange(null, Math.round((currentVal - step) * nb_conversion) / nb_conversion);
        }
    };

    return (
        <div style={{ marginTop: 37 }} data-name={input.name}>
            {(input.value === undefined || input.value === '') && (value === undefined || value === '') && (
                <div className="d-flex justify-content-center">
                    <Icons className={'text-initial sliding-icon'} fontSize={24} icon={'horizontal-scroll'} />
                </div>
            )}
            <Grid container spacing={1} className={`align-items-center ${inputState === undefined ? 'empty' : ''}`}>
                <Grid item>
                    <Icons
                        fontSize={14}
                        icon={'minus'}
                        className={`${minBackground} ${minColor} p-2 full-rounded position-relative d-inline-block`}
                        onClick={moins}
                    />
                </Grid>
                <Grid item xs>
                    <Slider
                        valueLabelDisplay="on"
                        aria-labelledby="slider"
                        disabled={disabled}
                        variant="outlined"
                        {...input}
                        value={input.value || value}
                        step={step}
                        marks={marks}
                        min={min}
                        max={max}
                        error={String(error)}
                        onChange={handleChange}
                        valueLabelFormat={(value) => `${value} ${symbole}`}
                    />
                </Grid>
                <Grid item>
                    <Icons
                        fontSize={14}
                        icon={'plus'}
                        className={`${maxBackground} ${maxColor} p-2 full-rounded position-relative d-inline-block`}
                        onClick={plus}
                    />
                </Grid>
            </Grid>
            {touched && invalid && <FormHelperText className={'text-error'}>{error}</FormHelperText>}
        </div>
    );
};

Slide.defaultProps = {
    disabled: false,
    step: 1,
    marks: false,
    min: 0,
    max: 100,
    minBackground: 'bg-secondary',
    maxBackground: 'bg-secondary',
    minColor: 'text-primary-dark',
    maxColor: 'text-primary-dark',
};

Slide.propTypes = {
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    step: PropTypes.number,
    marks: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    minBackground: PropTypes.string,
    maxBackground: PropTypes.string,
    minColor: PropTypes.string,
    maxColor: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        invalid: PropTypes.bool,
        error: PropTypes.string,
    }),
};