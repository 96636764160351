import { reset } from 'redux-form';
import { POST_DEMANDE_RAPPEL } from '../../../actions/app/demandeRappel/demandeRappel.actions';
import { setError, setLoader } from '../../../actions/app/ui/ui.actions';
import { setTrackAction } from '../../../actions/core/analytics/analytics.actions';
import { apiRequest, API_ERROR, API_SUCCESS } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';

export const demandeRappelMiddleware = () => (next) => (action) => {
    next(action);

    const { payload } = action;

    switch (action.type) {
        case POST_DEMANDE_RAPPEL:

            next([
                apiRequest({ body: payload.body, method: 'POST', url: payload.data, entity: POST_DEMANDE_RAPPEL }),
                setLoader({ state: true, entity: POST_DEMANDE_RAPPEL }),
            ]);

            break;

        case `${POST_DEMANDE_RAPPEL} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DEMANDE_RAPPEL,
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Terminer',
                    title: 'Merci, un gestionnaire dédié vous recontactera dans les délais demandés.',
                }),
                setTrackAction({
                    event: 'click',
                    actionGoogle: 'clickButton',
                    libelle: 'Demande de rappel',
                    categorie: 'WEBCALLBACK',
                    titleMatomo: 'clickButton',
                    valueMatomo: 'Demande de rappel',
                }),
                setError({ state: false, entity: POST_DEMANDE_RAPPEL }),
                setLoader({ state: false, entity: POST_DEMANDE_RAPPEL }),
                reset('demande-rappel'),
            ]);
            break;

        case `${POST_DEMANDE_RAPPEL} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DEMANDE_RAPPEL,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: 'Terminer',
                    title: 'Une erreur est survenue',
                }),
                setError({ state: true, entity: POST_DEMANDE_RAPPEL }),
                setLoader({ state: false, entity: POST_DEMANDE_RAPPEL }),
            ]);
            break;

        default:
            return null;
    }
};
