import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import { appMiddleware } from '../middlewares/app';
import { coreMiddleware } from '../middlewares/core';
import AppReducer from './AppReducer';

const middlewares = [...appMiddleware, ...coreMiddleware];

middlewares.push(logger);

export const store = createStore(AppReducer, applyMiddleware(...middlewares));
