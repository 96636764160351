import { Field } from 'redux-form';
import { normalizeNumber } from '@amo/core/utils/normalize';
import { required } from '@amo/core/utils/validateField';
import QuestionContentContainer from '../../QuestionContent/QuestionContentContainer';
import questions from '../../../../../../utils/json/questions.json'
import {BulleAide, QuestionLabel, SimpleTitle} from "@amo/core/components/containers";
import {Text, Radio} from "@amo/core/components/forms"
import warning from "@amo/core/assets/images/icons/warning.svg";

const Besoins = (props) => {
    const {
        assistance,
        montantAccessoires,
        typeVehicule,
        valid,
        handleSubmit,
        nextStep,
        fillLoading,
        previousStep,
        unsetValue,
        utilisation_recueil,
        recueilActif,
        isConcess, source,
        isConseiller
    } = props;

    return (
        <div>
            <SimpleTitle>{questions['titreBesoin'][source]}</SimpleTitle>
            {(isConcess || isConseiller || recueilActif || source === 'site') ? (
                <>
                    {
                        (!isConcess && !isConseiller && recueilActif && source !== 'site') && <QuestionContentContainer name={'utilisation_recueil'}>
                            <QuestionLabel className={'f-14 mt-5'} required>Souhaitez-vous utiliser le Recueil du Besoin&nbsp;?&nbsp;</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'utilisation_recueil'}
                                        value={'true'}
                                        type={'radio'}
                                        label={'Oui'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>
                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'utilisation_recueil'}
                                        value={'false'}
                                        type={'radio'}
                                        label={'Non'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    }
                    {(utilisation_recueil === 'true' || isConcess || isConseiller || source === 'site') && (
                        <>
                            <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirVol]'}>
                                <QuestionLabel>
                                    {questions['introBesoin'][source]}
                                </QuestionLabel>
                                <QuestionLabel className={'f-14 mt-5'} required>{questions['devoirVol'][source]}</QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[DevoirConseil][DevoirVol]'}
                                            value={'true'}
                                            type={'radio'}
                                            label={'Oui'}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[DevoirConseil][DevoirVol]'}
                                            value={'false'}
                                            type={'radio'}
                                            label={'Non'}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                </div>
                            </QuestionContentContainer>

                            <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirDommage]'}>
                                <QuestionLabel className={'f-14 mt-5'} required>{questions['devoirDommage'][source]}</QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[DevoirConseil][DevoirDommage]'}
                                            value={'true'}
                                            type={'radio'}
                                            label={'Oui'}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>

                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[DevoirConseil][DevoirDommage]'}
                                            value={'false'}
                                            type={'radio'}
                                            label={'Non'}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                </div>
                            </QuestionContentContainer>

                            <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirASS2]'}>
                                <QuestionLabel className={'f-14 mt-5'}>{questions['devoirASS2'][source]}</QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[DevoirConseil][DevoirASS2]'}
                                            value={'true'}
                                            type={'radio'}
                                            label={'Oui'}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[DevoirConseil][DevoirASS2]'}
                                            value={'false'}
                                            type={'radio'}
                                            label={'Non'}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                </div>
                            </QuestionContentContainer>

                            {!isConcess && assistance === 'true' && (typeVehicule === 'moto' || typeVehicule === 'scooter') && (
                                <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirASS3]'}>
                                    <QuestionLabel className={'f-14 mt-5'} required>{questions['devoirAss3'][source]}&nbsp;?&nbsp;</QuestionLabel>
                                    <div className={'row'}>
                                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                            <Field
                                                component={Radio}
                                                name={'DemandeTarif[DevoirConseil][DevoirASS3]'}
                                                value={'true'}
                                                type={'radio'}
                                                label={'Oui'}
                                                validate={required}
                                                className={'small'}
                                            />
                                        </div>
                                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                            <Field
                                                component={Radio}
                                                name={'DemandeTarif[DevoirConseil][DevoirASS3]'}
                                                value={'false'}
                                                type={'radio'}
                                                label={'Non'}
                                                validate={required}
                                                className={'small'}
                                            />
                                        </div>
                                    </div>
                                </QuestionContentContainer>
                            )}

                            <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirIC]'}>
                                <QuestionLabel className={'f-14 mt-5'} required>{questions['devoirIC'][source]}</QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                                            value={'3'}
                                            type={'radio'}
                                            label={'Oui'}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>

                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                                            value={'0'}
                                            type={'radio'}
                                            label={'Non'}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                </div>
                            </QuestionContentContainer>

                            <BulleAide
                                icon={warning}
                                title={`<b>Le saviez-vous ?</b>`}
                                text={`<p>Si vous êtes victime et blessé(e) suite à un accident causé par un autre véhicule,
                                    vos blessures seront indemnisées par son assureur.</p><p>Mais si vous êtes le responsable ou que vous avez provoqué seul cet accident, les
                                    impacts financiers d'une invalidité ou même d'un décès peuvent être importants pour
                                    vous et vos proches.</p><p>Vous pouvez vous assurer pour faire face à ces conséquences financières.</p>`}
                                className={'col-lg-12 col-md-12 f-14 mt-4'}
                            />
                            {
                                !isConcess && source !== 'site' && <>
                                    <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]'}>
                                        <QuestionLabel className={'f-14 mt-5'} required>{questions['devoirMontantAccesseQP'][source]}&nbsp;?&nbsp;</QuestionLabel>
                                        <div className={'row'}>
                                            <div className={'col-6 col-md-6 mt-3 mt-md-2'}>
                                                <Field
                                                    component={Text}
                                                    name={'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]'}
                                                    icon={'payment1'}
                                                    normalize={normalizeNumber}
                                                    onChange={() => unsetValue('DemandeTarif[DevoirConseil][DevoirACCESSEQP]')}
                                                />
                                            </div>
                                        </div>
                                    </QuestionContentContainer>

                                    {Number(montantAccessoires) > 0 && (
                                        <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirACCESSEQP]'}>
                                            <QuestionLabel className={'f-14 mt-5'} required>{questions['devoirAccesseQP'][source]}&nbsp;?&nbsp;</QuestionLabel>
                                            <div className={'row'}>
                                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                                    <Field
                                                        component={Radio}
                                                        name={'DemandeTarif[DevoirConseil][DevoirACCESSEQP]'}
                                                        value={'true'}
                                                        type={'radio'}
                                                        label={'Oui'}
                                                        validate={required}
                                                        className={'small'}
                                                    />
                                                </div>

                                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                                    <Field
                                                        component={Radio}
                                                        name={'DemandeTarif[DevoirConseil][DevoirACCESSEQP]'}
                                                        value={'false'}
                                                        type={'radio'}
                                                        label={'Non'}
                                                        validate={required}
                                                        className={'small'}
                                                    />
                                                </div>
                                            </div>
                                        </QuestionContentContainer>
                                    )}

                                    {typeVehicule !== '50' && (
                                        <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirRAFRA]'}>
                                            <QuestionLabel className={'f-14 mt-5'} required>{questions['devoirRAFRA'][source]}&nbsp;?&nbsp;</QuestionLabel>
                                            <div className={'row'}>
                                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                                    <Field
                                                        component={Radio}
                                                        name={'DemandeTarif[DevoirConseil][DevoirRAFRA]'}
                                                        value={'true'}
                                                        type={'radio'}
                                                        label={'Oui'}
                                                        validate={required}
                                                        className={'small'}
                                                    />
                                                </div>

                                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                                    <Field
                                                        component={Radio}
                                                        name={'DemandeTarif[DevoirConseil][DevoirRAFRA]'}
                                                        value={'false'}
                                                        type={'radio'}
                                                        label={'Non'}
                                                        validate={required}
                                                        className={'small'}
                                                    />
                                                </div>
                                            </div>
                                        </QuestionContentContainer>
                                    )}
                                </>
                            }
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className={'bg-secondary-light border p-4 rounded text-left mt-5 f-14'}>
                        <QuestionLabel className={'text-uppercase d-inline m-0'}>
                            Le saviez-vous ? Vous avez la possibilité d'activer le Recueil du Besoin pour obtenir une
                            Fiche Conseil.
                        </QuestionLabel>
                        <br />
                        <br />
                        <p className={'d-inline'}>
                            Si vous souhaitez bénéficier de ce service, vous avez la possibilité de remplir toutes les
                            informations nécessaires à la personnalisation de la Fiche Conseil dans votre espace,
                            rubrique "Mes services".
                        </p>
                        <br />
                        <br />
                        <p className={'d-inline'}>
                            Si vous préférez utiliser votre document, vous pouvez poursuivre la souscription.
                        </p>
                    </div>
                </>
            )}
            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn btn-primary float-right medium-rounded mb-2 order-lg-1`}
                    onClick={valid ? handleSubmit(nextStep) : null}
                    data-id="btn-next"
                >
                    {fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Continuer'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
        </div>
    );
};

export default Besoins;
