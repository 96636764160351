import * as Sentry from '@sentry/react';
import _ from 'lodash';
import InitFormulaireContainer from './InitFormulaire/InitFormulaireContainer';
import {useEffect} from "react";
import {APP_NAME} from '@amo/core/constants/constants.js'
import RouteStep from "./InitFormulaire/RouteStep.jsx";

const Init = (props) => {
    const { state, cle } = props;
    const isPathSlug = RouteStep.some(step => step.slug === cle);
    const logs = [
        {
            libelle: 'form',
            state: state.form,
        },
        {
            libelle: 'step',
            state: state.ui.step,
        },
        {
            libelle: 'indexQuestion',
            state: state.ui.question,
        },
        {
            libelle: 'init',
            state: state.init,
        },
        {
            libelle: 'loading',
            state: state.ui.loading,
        },
        {
            libelle: 'error',
            state: state.ui.error,
        },
    ];

    useEffect(() => {
        if (cle && !isPathSlug) {
            localStorage.setItem(`${APP_NAME}-key`, cle);
        }
        const existingKey = localStorage.getItem(`${APP_NAME}-key`);
        if (existingKey) {
            localStorage.setItem(`${APP_NAME}-key`, existingKey);
        }
    }, []);

    _.map(logs, (log) => {
        Sentry.addBreadcrumb({
            category: log.libelle,
            message: JSON.stringify(log.state),
            level: 'debug',
        });
    });

    return <InitFormulaireContainer {...props} />;
};

export default Init;
