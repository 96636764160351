import CancelPaiement from './CancelPaiement/CancelPaiement';
import StatusContratContainer from './StatusContrat/StatusContratContainer';

const Paiement = (props) => {
    const { sendMailPaiement, postPay, isCheque } = props;

    if (sendMailPaiement || isCheque) {
        return <StatusContratContainer {...props} />;
    } else {
        return <CancelPaiement {...props} postPay={postPay} />;
    }
};

export default Paiement;
