import { Checkbox, Text } from '@amo/core/components/forms';
import { validatePhone } from '@amo/core/utils/validateField';
import { Field } from 'redux-form';

const NumeroTel = ({ nameWhitelabel }) => {
    return (
        <>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-10 col-sm-10 col-12 my-lg-2 my-1'}>
                    <Field
                        label={'N° de téléphone'}
                        component={Text}
                        type={'tel'}
                        typeFormat={'tel'}
                        name={'booking_tel'}
                        placeholder={'Exemple : 02 38 53 02 38'}
                        validate={[validatePhone]}
                        required
                    />
                    <small className={'text-left d-block'}>
                        Il sera utilisé par nos équipes pour vous recontacter.
                    </small>
                </div>
            </div>
            <div className={'row justify-content-center mt-3'}>
                <div className={'col-lg-8 col-md-10 col-sm-10 col-12 my-lg-2 my-1'}>
                    <Field name="accept-recontact" component={Checkbox}>
                        <p className={'pl-3 d-block'}>
                            Afin d'être accompagné dans le cadre de ma démarche d'assurance, j'accepte qu’un expert moto
                            me contacte par téléphone et/ou email.
                        </p>
                        <small className={'pl-3 d-block'}>
                            (Pour vous conseiller au mieux dans le cadre de votre démarche, vous pourrez être rappelé
                            par un expert {nameWhitelabel === 'APRIL Moto' ? 'APRIL Moto' : 'AM Gestion'} d'ADM Value
                            -N°ORIAS : 10055724-)
                        </small>
                        <p className={'pl-3 d-block'}>
                            <sup className={'text-success'}>Obligatoire</sup>
                        </p>
                    </Field>
                </div>
            </div>
        </>
    );
};

export default NumeroTel;
