import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change, formValueSelector } from 'redux-form';
import {
    fetchMarquesVehicule,
    FETCH_MARQUES_VEHICULIER,
} from '../../../../../../redux/actions/app/vehiculier/vehiculier.actions.js';
import { getErrorEntity, getLoadingEntity, getMarquesVehiculeUi } from '../../../../../../redux/selectors/ui/ui.selectors.js';
import {loader, SmallSpinner} from '@amo/core/components/loaders';
import SelectMarque from './SelectMarque.jsx';
import {getApporteur1Init} from "../../../../../../redux/selectors/init/init.selectors.js";

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');
    return {
        type: selector(state, `typeVehicule`),
        marqueIdSelected: selector(state, `marqueIdVehicule`),
        marques: getMarquesVehiculeUi(state),
        loaded: getLoadingEntity(state, FETCH_MARQUES_VEHICULIER) === false,
        error: getErrorEntity(state, FETCH_MARQUES_VEHICULIER),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeValue: (field, value) => change('devis', field, value),
            fetchMarquesVehicule: ({ type }) => fetchMarquesVehicule({ type }),
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { type } = stateProps;
    const { fetchMarquesVehicule } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => fetchMarquesVehicule({ type }),
    };
};

const SelectMarqueContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps), loader({LoadingIndicator: SmallSpinner}))(SelectMarque);

export default SelectMarqueContainer;
