import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './radio-button-opt.scss';
import FormHelperText from "@mui/material/FormHelperText";

export const RadioButtonOpt = (props) => {
    const {
        input,
        meta: { touched, error, dirty, submitFailed, invalid },
        label,
        data,
        className,
    } = props;

    const isError = ((touched || dirty ) || submitFailed) && invalid;

    return (
        <>
            <div className="" data-name={input.name}>
                {label && <label className={'col-12'}>{label}</label>}
                <div className='row'>

                {_.map(data, (option) => (
                    <div
                        className={`col-12 ${
                            data.length > 1 && (data.length % 2 === 0 ? 'col-lg-6' : 'col-lg-4')
                        } ${className}`}
                        key={`div-${option.value}`}
                    >
                        <label
                            className={classNames(
                                'position-relative radioButtonField w-100 cursor-pointer py-3 h-100 d-block border d-flex align-items-center m-0',
                                {
                                    'rounded-bottom border-bottom-0 border-left-0 border-right-0': option.value === '',
                                    rounded: option.value !== '',
                                    'bg-grey-light': option.value === '' && input.value !== option.value,
                                    'bg-primary': input.value === option.value,
                                    'label-offerte':
                                        option.value !== '' &&
                                        option.dataAffichage &&
                                        option.dataAffichage.prix_promo === 0,
                                    'border-danger text-danger': isError,
                                },
                            )}
                            key={option.value}
                        >
                            <div
                                className={`position-relative position-relative f-14 ${
                                    input.value === option.value ? 'text-white' : ''
                                } `}
                            >
                                {option.label}
                                <input
                                    className={`position-absolute cursor-pointer`}
                                    type="radio"
                                    value={option.value}
                                    onChange={(e) => input.onChange(e)}
                                    onBlur={input.onBlur}
                                    checked={input.value === option.value}
                                />
                                <span className={classNames('checkmark bg-white border position-absolute full-rounded ', {
                                    'border-danger': isError,
                                })} />
                            </div>
                        </label>
                    </div>
                ))}
                </div>

                {isError && <FormHelperText error={isError}>{error}</FormHelperText> }
            </div>
        </>
    );
};

RadioButtonOpt.defaultProps = {
    children: '',
    className: '',
    classNameParent: 'col-12',
};

RadioButtonOpt.propTypes = {
    children: PropTypes.node,
    input: PropTypes.object.isRequired,
};
