import PropTypes from 'prop-types';
import SideBarContent from '../SideBarContent/SideBarContent';
import { normalizeEuro } from '@amo/core/utils/normalize.js'

const FormuleSideBar = (props) => {
    const {
        fractionnement,
        formule,
        tarif,
        isCurrentStep,
        slug,
        goTo,
    } = props;

    return (
        <SideBarContent
            title={'Formule'}
            icon={'security'}
            isCurrentStep={isCurrentStep}
            slug={slug}
            goTo={goTo}
            tabContent={[
                { title: 'Fractionnement', value: fractionnement },
                { title: 'Formule choisie', value: formule },
                { title: 'Tarif', value: normalizeEuro(tarif)},
            ]}
        />
    );
};

FormuleSideBar.propTypes = {
    slug: PropTypes.string.isRequired,
    goTo: PropTypes.func,
};

export default FormuleSideBar;
