import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change } from 'redux-form';
import { postVehiculeIsDisabled, setVehicule } from '../../../../../../../../redux/actions/app/vehicule/vehicule.actions.js';
import {
    fetchModelesVehicule,
    FETCH_MODELES_VEHICULIER,
} from '../../../../../../../../redux/actions/app/vehiculier/vehiculier.actions.js';
import { getCanalDemandeTarifInit } from '../../../../../../../../redux/selectors/init/init.selectors.js';
import {
    getErrorEntity,
    getLoadingEntity,
    getModelesVehiculeUi,
} from '../../../../../../../../redux/selectors/ui/ui.selectors.js';
import {loader, SmallSpinner} from '@amo/core/components/loaders';
import SelectModele from './SelectModele.jsx';

const mapStateToProps = (state) => {
    return {
        canal: getCanalDemandeTarifInit(state),
        modeles: getModelesVehiculeUi(state),
        loaded: getLoadingEntity(state, FETCH_MODELES_VEHICULIER) === false,
        error: getErrorEntity(state, FETCH_MODELES_VEHICULIER),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeValue: (field, value) => change('devis', field, value),
            fetchModelesVehicule: ({ type, marque_id, cylindree }) =>
                fetchModelesVehicule({ type, marque_id, cylindree }),
            setVehicule,
            postVehiculeIsDisabled,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { type, marque_id, cylindree } = ownProps;
    const { fetchModelesVehicule, setVehicule, postVehiculeIsDisabled } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        setVehicule: (data) => setVehicule({ data: data }),
        selectedVehiculeIsDisabled: (data) => postVehiculeIsDisabled({ log: data }),
        load: () => fetchModelesVehicule({ type, marque_id, cylindree }),
    };
};

const SelectModeleContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps), loader({LoadingIndicator: SmallSpinner}))(SelectModele);

export default SelectModeleContainer;
