import { useEffect } from 'react';
import {SmallSpinner} from "@amo/core/components/loaders";
import './header-tarif.scss';
import HeaderTarifAnnualPrice from './HeaderTarifAnnualPrice/HeaderTarifAnnualPrice';
import HeaderTarifPrice from './HeaderTarifPrice/HeaderTarifPrice';
import {Icons} from "@amo/core/components/containers";

const HeaderTarif = (props) => {
    const {
        marque,
        changeValue,
        fractionnement,
        codePromo,
        error,
        prixTotal,
        prixTotalPromo,
        prixTotalAnnuel,
        prixTotalAnnuelPromo,
        libelleFormule,
        nbOptions,
        loadingCodePromo,
    } = props;

    const handleFractionnement = (value) => {
        changeValue('DemandeTarif[Police][FractionnementChoisi]', value);
    };

    useEffect(() => {
        const el = document.querySelector('.headerTarifContent');
        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio < 1),
            { threshold: [1] },
        );

        if (el) {
            observer.observe(el);
        }
    });

    return (
        <>
            {!error && (
                <>
                    <div className={'headerTarifTitle container'}>
                        <div className={'row'}>
                            <div className={'col-12 col-lg-6 offset-lg-3 text-center'}>
                                <h1 className={'h4 mb-3'}>
                                    Voici nos recommandations
                                    <br />
                                    personnalisées pour assurer votre{' '}
                                    <span className={'text-primary-light'}>{marque}</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className={'headerTarifContent container-fluid position-sticky'}>
                        <div className={'row'}>
                            <div className={'col-12 col-lg-6 offset-lg-3'}>
                                <div className={'text-center'}>
                                    <div className={'mx-auto blocTarif border border-primary-light rounded'}>
                                        <div className={'font-weight-bold'}>
                                            Formule {libelleFormule}
                                            {nbOptions ? (
                                                <span>
                                                    {' '}
                                                    + {nbOptions} {nbOptions === 1 ? 'option' : 'options'}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <h2>Votre tarif :</h2>
                                        <div
                                            className={
                                                'font-weight-bold prix text-primary-light m-0 d-inline-block text-left'
                                            }
                                        >
                                            <HeaderTarifPrice
                                                frequence={fractionnement === 'M' ? 'mois' : 'an'}
                                                prix={prixTotal ?? 0}
                                                prix_promo={prixTotalPromo}
                                            />
                                            {fractionnement === 'M' ? (
                                                <HeaderTarifAnnualPrice
                                                    prix={prixTotalAnnuel ?? 0}
                                                    prix_promo={prixTotalAnnuelPromo}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                        <div className={'row'}>
                                            <div className={`col-6`}>
                                                <button
                                                    className={`btn ${
                                                        fractionnement === 'M'
                                                            ? 'btn-primary'
                                                            : 'btn-secondary'
                                                    } medium-rounded f-14 w-100 h-100`}
                                                    onClick={() => handleFractionnement('M')}
                                                >
                                                    Mensuel
                                                </button>
                                            </div>
                                            <div className={`col-6`}>
                                                <button
                                                    className={`btn ${
                                                        fractionnement === 'A'
                                                            ? 'btn-primary'
                                                            : 'btn-secondary'
                                                    } medium-rounded f-14 w-100 h-100`}
                                                    onClick={() => handleFractionnement('A')}
                                                >
                                                    Annuel
                                                    <br />
                                                    <span className={'font-weight-normal'}>
                                                        (économisez jusqu'à 10%)
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loadingCodePromo && <SmallSpinner />}

                    {!loadingCodePromo && codePromo && codePromo.Eligible && codePromo.MessageEligible && (
                        <div className={'font-weight-bold text-success mt-4 text-center'}>
                            <Icons
                                className={`checkSuccess border full-rounded border-success d-inline-block position-relative align-middle mr-2 p-2`}
                                fontSize={7}
                                icon={'Shape'}
                            />
                            {codePromo.MessageEligible}
                        </div>
                    )}
                    {!loadingCodePromo && codePromo && !codePromo.Eligible && codePromo.MessageEligible && (
                        <div className={'font-weight-bold mt-4 text-center text-primary'}>
                            <Icons
                                className={`text-danger border full-rounded d-inline-block position-relative align-middle mr-2`}
                                fontSize={20}
                                icon={'info-1'}
                            />
                            {codePromo.MessageEligible}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default HeaderTarif;
