import TextField from '@mui/material/TextField';
import { frFR, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

/* https://mui.com/x/react-date-pickers/getting-started/ */

export const DateTimePicker = (props) => {
    const {
        meta: { error },
        input,
        label,
        placeholder = 'DD/MM/YYYY',
    } = props;

    let locale = frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    /* Props non traduite dans la lib */
    locale.datePickerDefaultToolbarTitle = 'Sélectionnez une date';

    return (
        <div data-id={`date-${input.name}`}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr" localeText={locale}>
                <MuiDateTimePicker
                    className={'w-100 bg-white'}
                    label={label}
                    value={input.value}
                    onChange={(newValue) => {
                        input.onChange(newValue);
                    }}
                    renderInput={(params) => {
                        params.inputProps.placeholder = placeholder;
                        params.InputProps.error = Boolean(error);
                        return <TextField {...params} />;
                    }}
                />
                {error && <small className={'alert alert-danger invalid-feedback d-block'}>{error}</small>}
            </LocalizationProvider>
        </div>
    );
};
