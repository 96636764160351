import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { INIT, postInit } from '../../../redux/actions/app/init/init.actions';
import { VEHICULE } from '../../../redux/actions/app/vehicule/vehicule.actions';
import { localStorageAMO } from '../../../redux/selectors/localStorage/localStorage.selectors';
import { getError, getErrorEntity, getLoadingEntity } from '../../../redux/selectors/ui/ui.selectors';
import { getSlugStep } from '../../../utils/function';
import {loader} from '@amo/core/components/loaders';
import Init from './Init';
import RouteStep from './InitFormulaire/RouteStep';

const mapStateToProps = (state, ownProps) => {
    return {
        loaded: getLoadingEntity(state, INIT) === false && getLoadingEntity(state, VEHICULE) !== true,
        stepTarif: getSlugStep('votre-tarif'),
        stepValidation: getSlugStep('validation'),
        stepInformationsConducteur: getSlugStep('vos-infos'),
        stepProfil: getSlugStep('votre-profil'),
        stepRecapitulatif: getSlugStep('recapitulatif'),
        stepPaiement: getSlugStep('paiement'),
        stepRecontact: getSlugStep('recontact'),
        error: getError(state) && getErrorEntity(state, INIT),
        cle: ownProps.cle,
        state,
    };
};

const mapDispatchToProps = (dispatch, ownProps) =>
    bindActionCreators(
        {
            load: () =>
                postInit({
                    token: localStorageAMO && localStorageAMO.token,
                    key: !_.find(RouteStep, ['path', '/' + ownProps.cle]) ? ownProps.cle : null,
                    origin: 'courtage',
                }),
        },
        dispatch,
    );

const InitContainer = compose(connect(mapStateToProps, mapDispatchToProps), loader())(Init);

export default InitContainer;
