import PropTypes from 'prop-types';
import { normalizeEuro } from '@amo/core/utils/normalize.js'

const TablePrice = (props) => {
    const { prix, prix_promo, frequence } = props;
    const enPromo = prix_promo !== undefined && prix > prix_promo;

    return (
        <>
            {enPromo && (
                <span className={'d-block f-12 font-weight-normal'}>
                    {normalizeEuro(prix_promo)} /{frequence}
                </span>
            )}

            <span className={`f-12 font-weight-normal ${enPromo ? 'text-decoration-line-through' : ''}`}>
                {normalizeEuro(prix)} TTC/{frequence}
            </span>
        </>
    );
};

TablePrice.propTypes = {
    prix: PropTypes.number.isRequired,
    prix_promo: PropTypes.number.isRequired,
    frequence: PropTypes.string.isRequired,
};

export default TablePrice;
