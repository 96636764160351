import { createSelector } from 'reselect';

/* Vehicule */
const vehiculeSelector = (state) => state.vehicule;

export const getVehicule = createSelector(vehiculeSelector, (vehicule) => vehicule);

export const getCylindreeVehicule = createSelector(vehiculeSelector, (vehicule) => vehicule.cylindree);

export const getModeleVehicule = createSelector(vehiculeSelector, (vehicule) => vehicule.modele);

export const getMarqueIdVehicule = createSelector(vehiculeSelector, (vehicule) =>
    vehicule.brand ? vehicule.brand.id : undefined,
);

export const getMarqueVehicule = createSelector(vehiculeSelector, (vehicule) =>
    vehicule.brand ? vehicule.brand.name : undefined,
);

export const getImgMarqueVehicule = createSelector(vehiculeSelector, (vehicule) =>
    vehicule.brand ? vehicule.brand.img : undefined,
);

export const getTypeVehicule = createSelector(vehiculeSelector, (vehicule) =>
    vehicule.type ? vehicule.type.id : undefined,
);
export const getCodeCategorieVehicule = createSelector(vehiculeSelector, (vehicule) =>
    vehicule.codecategorie ? vehicule.codecategorie : undefined,
);
export const getGenreVehicule = createSelector(vehiculeSelector, (vehicule) =>
    vehicule.genre ? vehicule.genre : undefined,
);
export const getGroupeVehicule = createSelector(vehiculeSelector, (vehicule) => {
    return vehicule.groupe ? vehicule.groupe : undefined;
});

export const getSouscriptionDisabledVehicule = createSelector(vehiculeSelector, (vehicule) => {
    return vehicule.souscription_disabled;
});
