import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { getFormInputLabel } from '../../../../../../utils/transcoLabels';
import InformationsVehiculeSideBar from './InformationsVehiculeSideBar';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');
    return {
        dateMEC: selector(state, 'DemandeTarif[Vehicule][DateMEC]'),
        possessionPlusDe3Mois: getFormInputLabel(
            'DemandeTarif[Vehicule][PossessionPlusDe3Mois]',
            selector(state, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]')
        ),
        assure3DerniersMois: getFormInputLabel(
            'DemandeTarif[Vehicule][Assure3DerniersMois]',
            selector(state, 'DemandeTarif[Vehicule][Assure3DerniersMois]'),
        ),
        usageVehicule: getFormInputLabel(
            'DemandeTarif[Vehicule][UsageVehicule]',
            selector(state, 'DemandeTarif[Vehicule][UsageVehicule]'),
        ),
        usageNonLivraison: getFormInputLabel(
            'DemandeTarif[Vehicule][UsageNonLivraison]',
            selector(state, 'DemandeTarif[Vehicule][UsageNonLivraison]'),
        ),
        valeurVehicule: selector(state, 'DemandeTarif[Vehicule][ValeurVehicule]'),
        vehiculeImmatricule: getFormInputLabel(
            'DemandeTarif[Vehicule][VehiculeImmatricule]',
            selector(state, 'DemandeTarif[Vehicule][VehiculeImmatricule]'),
        ),
        carteGriseFrancaise: getFormInputLabel(
            'DemandeTarif[Vehicule][CarteGriseFrancaise]',
            selector(state, 'DemandeTarif[Vehicule][CarteGriseFrancaise]'),
        ),
        villeGarage: selector(state, 'DemandeTarif[Vehicule][VilleGarage]'),
        codePostalGarage: selector(state, 'DemandeTarif[Vehicule][CodePostalGarage]'),
    };
};

const InformationsVehiculeSideBarContainer = connect(mapStateToProps)(InformationsVehiculeSideBar);

export default InformationsVehiculeSideBarContainer;
