import { connect } from 'react-redux';
import { compose } from 'redux';
import { getLogoWhitelabel } from '../../../../../redux/selectors/init/init.selectors';
import {TopBar} from "@amo/core/components/containers";

const mapStateToProps = (state) => {
    return {
        logo: getLogoWhitelabel(state),
    };
};

const TopBarContainer = compose(connect(mapStateToProps))(TopBar);

export default TopBarContainer;
