import { SET_VEHICULE } from '../../actions/app/vehicule/vehicule.actions';

export const vehiculeReducer = (vehicule = {}, action) => {
    const { payload } = action;

    switch (action.type) {
        case SET_VEHICULE:
            return payload.data.data;

        default:
            return vehicule;
    }
};
