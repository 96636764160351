import { connect } from 'react-redux';
import { compose } from 'redux';
import { change } from 'redux-form';
import formValueSelector from 'redux-form/lib/formValueSelector';
import SearchBarVehicule from './SearchBarVehicule.jsx';
import {setVehicule} from "../../../../../../redux/actions/app/vehicule/vehicule.actions.js";

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');

    return {
        typeVehicule: selector(state, `typeVehicule`),
    };
};

const mapDispatchToProps = {
    changeValue: (field, value) => change('devis', field, value),
    setVehicule
};

const SearchBarVehiculeContainer = compose(connect(mapStateToProps, mapDispatchToProps))(SearchBarVehicule);

export default SearchBarVehiculeContainer;
