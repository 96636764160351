import PropTypes from 'prop-types';
import './select.scss';
import Swal from "sweetalert2";

export const Select = (props) => {
    const { error, children, label, required, input, onChange, disabled, help } = props;

    const showPopup = () => {
        Swal.fire({
            icon: 'info',
            text: help,
        });
    }

    return (
        <div className={'select-content'}>
            {label && (
                <label className={'label-text mb-1'}>
                    {label}
                    {required ? <sup className={'text-danger'}>*</sup> : ''}
                    {help && <span className="float-end d-inline-block btn-help" style={{marginTop: '-5px'}} onClick={() => showPopup()}>?</span>}
                </label>
            )}
            <select
                onChange={onChange || null}
                {...input}
                disabled={disabled}
                className={`form-select mb-3 rounded border cursor-pointer`}
                required={required}
            >
                {children}
            </select>
            {error && <small className={'alert alert-danger invalid-feedback d-block'}>{error}</small>}
        </div>
    );
};

Select.propTypes = {
    error: PropTypes.string,
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    input: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

Select.defaultProps = {
    required: false,
    disabled: false,
};
