export const getFormInputLabel = (name, value, getAll = null) => {
    const objet = {
        'DemandeTarif[Vehicule][SituationVehicule]': {
            1: 'Vous possédez déjà votre véhicule',
            0: 'Vous ne possédez pas encore votre véhicule',
        },
        typeVehicule: {
            moto: 'Moto',
            scooter: 'Scooter',
            50: 'Cyclo 50 cm3',
            quad: 'Quad',
            verte: 'Moto verte',
            all: 'Je ne sais pas',
        },
        'DemandeTarif[Vehicule][PossessionPlusDe3Mois]': {
            0: 'Depuis moins de 3 mois',
            1: 'Depuis plus de 3 mois',
        },
        'DemandeTarif[Vehicule][Assure3DerniersMois]': {
            0: 'Non',
            1: 'Oui',
        },
        'DemandeTarif[Vehicule][UsageVehicule]': {
            1: 'Promenade',
            2: 'Promenade + Trajet travail',
            3: 'Affaires',
        },
        'DemandeTarif[Vehicule][UsageNonLivraison]': {
            onError: 'Oui',
            1: 'Non',
        },
        titulaireCarteGriseMineur: {
            1: "Au nom de l'enfant",
            3: 'À votre nom',
        },
        titulaireCarteGrise: {
            1: 'À votre nom',
            2: "Au nom d'une société",
            3: "Au nom d'un parent",
        },
        'DemandeTarif[Vehicule][VehiculeImmatricule]': {
            onError: 'Non',
            1: 'Oui',
        },
        'DemandeTarif[Vehicule][CarteGriseFrancaise]': {
            onError: 'Étrangère',
            1: 'Française',
        },
        permis_possede: {
            permisA: 'Permis A',
            permisA2: 'Permis A2',
            permisAM: 'Permis AM (ou BSR)',
            permisAucun: 'Aucun',
        },
        dejaassure: {
            oui: 'Vous avez déjà été assuré en 2 roues',
            non: "Vous n'avez jamais déjà été assuré en 2 roues",
        },
        'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]': {
            1: 'Oui',
            0: 'Non',
        },
        'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]': {
            1: 'Oui',
            0: 'Non',
        },
        assure_moto: {
            1: 'Vous avez déjà été assuré en 2 roues',
            0: "Vous n'avez jamais été assuré en 2 roues",
        },
        assure_auto: {
            1: 'Vous avez déjà été assuré en auto',
            0: "Vous n'avez jamais été assuré en auto",
        },
        'DemandeTarif[Antecedents][SuspensionPermis]': {
            true: 'Oui',
            false: 'Non',
        },
        'DemandeTarif[Antecedents][AnnulationPermis]': {
            true: 'Oui',
            false: 'Non',
        },
        'DemandeTarif[Antecedents][CondamnationAlcoolemie]': {
            true: 'Oui',
            false: 'Non',
        },
        'DemandeTarif[Antecedents][CondamnationStupefiants]': {
            true: 'Oui',
            false: 'Non',
        },
        'DemandeTarif[Antecedents][CondamnationDelitFuite]': {
            true: 'Oui',
            false: 'Non',
        },
        'DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]': {
            true: 'Oui',
            false: 'Non',
        },
        'DemandeTarif[Antecedents][ResiliationAssureur]': {
            1: 'Oui',
            0: 'Non',
        },
        'DemandeTarif[Antecedents][MotifResiliationAssureur]': {
            NP: 'Non paiement de prime',
            FS: 'Fréquence sinistres',
            FD: 'Fausse déclaration',
            A: 'Autres',
        },
        sinistre_auto: {
            1: 'Oui',
            0: 'Non',
        },
        incendie: {
            true: 'Oui',
            false: 'Non',
        },
        accidents: {
            true: 'Oui',
            false: 'Non',
        },
        remorquage: {
            true: 'Oui',
            false: 'Non',
        },
        indemnisation: {
            3: 'Oui',
            0: 'Non',
        },
        remplacement: {
            true: 'Oui',
            false: 'Non',
        },
        accessoires: {
            true: 'Oui',
            false: 'Non',
        },
        rachat: {
            true: 'Oui',
            false: 'Non',
        },
        OptinNewsletter: {
            1: 'Oui',
            0: 'Non',
        },
        OptinUtilisationDonnees: {
            1: 'Oui',
            0: 'Non',
        },
        VehiculeSinistre: {
            A: 'auto',
            M: 'moto',
        },
        'DemandeTarif[ListePersonnes][ConducteurMineur]': {
            false: 'Non',
            true: 'Oui',
        },
        fractionnement: {
            A: 'Annuel',
            M: 'Mensuel',
        },
        formule: {
            F1: 'Essentielle',
            F2: 'Équilibre',
            F4: 'Tous risques'
        },
    };

    if (getAll) {
        return objet;
    }

    return objet[name][value];
};

export const getFormInputLabelSinistre = (name, value) => {
    const objet = {
        NatureSinistre: {
            BDG: 'Bris de glace',
            CIR: 'Circulation',
            STA: 'Stationnement',
            VOLVEH: 'Vol',
            VAND: 'Vandalisme',
            INC: 'Incendie',
            EVTNAT: 'Evènement naturel',
        },
        ResponsabiliteSinistre: {
            0: '0%',
            50: '50%',
            100: '100%',
        },
        VehiculeSinistre: {
            A: 'auto',
            M: 'moto',
        },
        TypeSinistre: {
            C: 'Dommage(s) corporel(s)',
            M: 'Dommage(s) matériel(s)',
        },
    };

    return objet;
};

export const getUsageByCode = (code) => {
    switch (code) {
        case '1':
            return 'Le loisir';
        case '2':
            return 'Promenade + trajet travail';
        case '3':
            return 'Affaires';
        default:
            return null;
    }
};
