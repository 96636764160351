import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import AntecedentsSideBarContainer from './AntecedentsSidebar/AntecedentsSideBarContainer';
import BesoinsSideBarContainer from './BesoinsSideBar/BesoinsSideBarContainer';
import DevisSideBar from './DevisSideBar/DevisSideBar';
import InformationsVehiculeSideBarContainer from './InformationsVehiculeSideBar/InformationsVehiculeSideBarContainer';
import ProfilSideBarContainer from './ProfilSideBar/ProfilSideBarContainer';
import VehiculeSideBarContainer from './VehiculeSideBar/VehiculeSideBarContainer';
import FormuleSideBar from "./FormuleSideBar/FormuleSideBar.jsx";
import FormuleSideBarContainer from "./FormuleSideBar/FormuleSideBarContainer.js";

const SideBar = (props) => {
    const {
        step,
        stepVehicule,
        stepInformationsVehicule,
        stepProfil,
        stepAntecedents,
        stepBesoins,
        postFill,
        stepDevis,
        isDevisOrange,
        stateDevis,
        stepRecap
    } = props;

    return (
        <div className={'h-100 mt'}>
            {!isMobileOnly && (
                <div className={'mb-5'}>
                    {step >= stepDevis && isDevisOrange && (
                        <DevisSideBar slug={'votre-tarif'} goTo={postFill} isCurrentStep={stepDevis === step} />
                    )}


                    <VehiculeSideBarContainer
                        slug={'votre-vehicule'}
                        goTo={postFill}
                        isCurrentStep={stepVehicule === step}
                    />

                    {(step >= stepInformationsVehicule || stateDevis) && (
                        <InformationsVehiculeSideBarContainer
                            slug={'informations'}
                            goTo={postFill}
                            isCurrentStep={stepInformationsVehicule === step}
                        />
                    )}
                    {(step >= stepProfil || stateDevis) && (
                        <ProfilSideBarContainer
                            slug={'votre-profil'}
                            goTo={postFill}
                            isCurrentStep={stepProfil === step}
                        />
                    )}
                    {(step >= stepAntecedents || stateDevis) && (
                        <AntecedentsSideBarContainer
                            slug={'vos-antecedents'}
                            goTo={postFill}
                            isCurrentStep={stepAntecedents === step}
                        />
                    )}

                    {(step >= stepBesoins || stateDevis) && (
                        <BesoinsSideBarContainer
                            slug={'vos-besoins'}
                            goTo={postFill}
                            isCurrentStep={stepBesoins === step}
                        />
                    )}

                    {(step === stepDevis) && (
                        <FormuleSideBarContainer
                            slug={'votre-tarif'}
                            goTo={postFill}
                            isCurrentStep={stepAntecedents === step}
                        />
                    )}
                </div>
            )}

            {/*<ChatBoxContainer avatar />*/}
        </div>
    );
};

SideBar.defaultProps = {};

SideBar.propTypes = {
    slug: PropTypes.string,
    postFill: PropTypes.func,
};

export default SideBar;
