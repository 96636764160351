import arrow from '@amo/core/assets/images/icons/bracket-bottom.svg';
import { useEffect, useState } from 'react';
import './collapse.scss';

export const Collapse = (props) => {
    const { title, children, isCollapse } = props;
    const [collapse, setCollapse] = useState(isCollapse);

    useEffect(() => {
        if (isCollapse !== collapse) {
            setCollapse(isCollapse);
        }
    }, [isCollapse]);

    return (
        <div
            className={`px-3 w-100 collapsecontainer border rounded cursor-pointer mb-3 overflow-hidden ${collapse ? 'deploy' : ''}`}
        >
            <div className="px-1 py-2" onClick={() => setCollapse(!collapse)} data-test={'collapseHeader'}>
                <div className="row collapsetitle align-items-center">
                    <div className={'col-10 text-start text-left'} data-test={'blocTitle'}>
                        <span className={'text-initial m-0'} data-test={'title'} dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                    <div className={'col-2 d-flex justify-content-end'}>
                        <img src={arrow} alt={'arrow'} />
                    </div>
                </div>
            </div>
            <div className={'collapsecontent row text-start text-left'} data-test={'containerChildren'}>
                {collapse && <div className={'p-2 w-full'}>{children}</div>}
            </div>
        </div>
    );
};

Collapse.defaultProps = {
    isCollapse: true,
};
