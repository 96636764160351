import React from 'react'
import PropTypes from 'prop-types'
import './title.scss'

export const Title = ({icon, children, className, dangerouslySetInnerHTML, size, ...other}) => {

    return (
        <p className={`title ${className}`} id="formulaire" {...other}>
            {icon && <span className={`icon-${icon} rounded-circle`} style={{fontSize: size ? size : 26}} /> }
            {children} <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        </p>
    )
}

Title.defaultProps = {
    icon: '',
    className: '',
    children: ''
};

Title.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.string
};