import { useParams, useLocation } from 'react-router-dom';
import InitContainer from './Init/InitContainer';

const GetParams = () => {
    const { key } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const cle = key || queryParams.get('cle');

    return <InitContainer cle={cle} />;
};

export default GetParams;
