import { useEffect, useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import QuestionContentContainer from '../../QuestionContent/QuestionContentContainer';
import {
    maxDateToday,
    minDateValidator,
    onError,
    required,
    requiredDateWithError
} from "@amo/core/utils/validateField";
import SlideContainer from "../../Slide/SlideContainer.js";
import _ from "lodash";
import moment from "moment";
import questions from '../../../../../../utils/json/questions.json'
import {QuestionLabel, SimpleTitle} from "@amo/core/components/containers";
import {DateField, Radio, Text, Select} from "@amo/core/components/forms";
import {AddSinistre} from '@amo/core/components/group-form'
import Swal from "sweetalert2";

const Antecedents = (props) => {
    const {
        source,
        sinistres,
        sinistre_auto,
        resiliationAssureur,
        annulationPermis,
        suspensionPermis,
        condamnationAlcoolemie,
        changeValue,
        cylindreeVehicule,
        familleProduit,
        assure_moto,
        assure_auto,
        nbMoisAssuranceMoto,
        nbMoisAssuranceAuto,
        valeurCrmMoto,
        valeurCrmAuto,
        firstValeurCrmAuto,
        firstValeurCrmMoto,
        firstValeurCrmMotoPlus3Ans,
        firstValeurCrmAutoPlus3Ans,
        valeurCrmMoto50Plus3Ans,
        valeurCrmAuto50Plus3Ans,
        typePermisB,
        malusAutoMoto,
        valid,
        handleSubmit,
        nextStep,
        fillLoading,
        previousStep,
        dejaClientAntecedents,
        isConcess,
        titulaireCarteGrise
    } = props;
    const [antecedentsSur3ans, setAntecedentsSur3ans] = useState(
        !_.includes(['quad', 'verte', '50'], familleProduit) &&
            (suspensionPermis === 'true' ||
                annulationPermis === 'true' ||
                resiliationAssureur === 'true' ||
                malusAutoMoto),
    );

    const clearAntecendentAnnulationPermis = () => {
        changeValue('DemandeTarif[Antecedents][DateAnnulationPermis]', '');
        clearAntecendentAnnulationPermisOrSuspensionPermis();
    };

    const clearAntecendentSuspensionPermis = () => {
        changeValue('DemandeTarif[Antecedents][DateSuspensionPermis]', '');
        changeValue('DemandeTarif[Antecedents][NbMoisSuspensionPermis]', '');

        if (annulationPermis !== 'true' && suspensionPermis !== 'true') {
            clearAntecendentAnnulationPermisOrSuspensionPermis();
        }
    };
    const clearAntecendentAnnulationPermisOrSuspensionPermis = () => {
        changeValue('DemandeTarif[Antecedents][CondamnationAlcoolemie]', 'false');
        changeValue('DemandeTarif[Antecedents][CondamnationStupefiants]', 'false');
        changeValue('DemandeTarif[Antecedents][CondamnationDelitFuite]', 'false');
        changeValue('DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]', 'false');
    };
    const clearAntecendentResiliationAssureur = () => {
        changeValue('DemandeTarif[Antecedents][MotifResiliationAssureur]', '');
        changeValue('DemandeTarif[Antecedents][DateResiliationAssureur]', '');
    };
    const clearAntecendentListeSinistres = () => {
        changeValue('DemandeTarif[Antecedents][ListeSinistres]', []);
    };

    const showPopup = e => {
        Swal.fire({
            icon: 'info',
            title: 'Le saviez-vous ?',
            confirmButtonText: "J'ai bien compris",
            html: '<p>Votre client est éligible à la loi sur la Consommation (dite loi Hamon). Si vous le souhaitez et sous réserve d’acceptation du devis, APRIL Moto se charge des démarches de résiliation de son contrat actuel !</p><div class="text-left mt-4"><p><b>Le saviez-vous&nbsp;?</b></p><ul><li>Les sociétés ne sont pas concernées par cette loi</li><li>Le souscripteur doit être identique pour l\'ancienne et la nouvelle assurance</li></ul></div>',
        });
    }

    useEffect(() => {
        const antecedents3ans =
            !_.includes(['quad', 'verte', '50'], familleProduit) &&
            (suspensionPermis === 'true' ||
                annulationPermis === 'true' ||
                resiliationAssureur === '1' ||
                malusAutoMoto);
        setAntecedentsSur3ans(antecedents3ans);

        if (!antecedents3ans){
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', '24');
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', '24');

            if (nbMoisAssuranceMoto > 24) {
                changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', 24);
            }
            if (nbMoisAssuranceAuto > 24) {
                changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', 24);
            }

        } else {
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', '36');
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', '36');
        }

    }, [suspensionPermis, annulationPermis, resiliationAssureur]);

    useEffect(() => {
        if (!firstValeurCrmAuto){
            changeValue('firstValeurCrmAuto', valeurCrmAuto)
        }
        if (!firstValeurCrmMoto){
            changeValue('firstValeurCrmMoto', valeurCrmMoto)
        }
        if (!firstValeurCrmMotoPlus3Ans){
            changeValue('firstValeurCrmMotoPlus3Ans', valeurCrmMoto50Plus3Ans)
        }
        if (!firstValeurCrmAutoPlus3Ans){
            changeValue('firstValeurCrmAutoPlus3Ans', valeurCrmAuto50Plus3Ans)
        }

        if (assure_auto == "0"){
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', null)
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', '1')
            changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', null)
        }

        if (assure_moto == "0"){
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', null)
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', '1')
            changeValue('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', null)
        }

        if(_.includes(['quad', 'verte'], familleProduit) && valeurCrmMoto !== undefined){
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', "1")
        }
    }, [])

    return (
        <div>
            <SimpleTitle>{questions['titreAntecedents'][source]}</SimpleTitle>

            <QuestionContentContainer name={'DemandeTarif[Antecedents][SuspensionPermis]'}>
                <SimpleTitle className={'mt-5 f-16'}>Au cours de ces 3 dernières années</SimpleTitle>
                <QuestionLabel className={'f-14 mt-4'} required>{questions['permisSuspension'][source]}&nbsp;?&nbsp;</QuestionLabel>

                <div className={'row'}>
                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][SuspensionPermis]'}
                            value={'true'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][SuspensionPermis]'}
                            value={'false'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            onChange={clearAntecendentSuspensionPermis}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {suspensionPermis === 'true' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][DateSuspensionPermis]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>{questions['datePermisSuspension'][source]}&nbsp;:&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={DateField}
                                name={'DemandeTarif[Antecedents][DateSuspensionPermis]'}
                                label={'JJ/MM/AAAA'}
                                validate={[requiredDateWithError, maxDateToday, minDateValidator]}
                                maxDate={moment()}
                                minDate={moment().subtract(3, 'years')}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {suspensionPermis === 'true' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][NbMoisSuspensionPermis]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>Indiquez le nombre de mois de suspension de permis&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-8 col-md-8'}>
                            <Field
                                component={SlideContainer}
                                name={'DemandeTarif[Antecedents][NbMoisSuspensionPermis]'}
                                step={1}
                                min={0}
                                max={60}
                                validate={required}
                                maxBackground={'bg-error'}
                                minBackground={'bg-success'}
                                minColor={'text-white'}
                                maxColor={'text-white'}
                                symbole={' mois'}
                            />
                        </div>
                        <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[Antecedents][NbMoisSuspensionPermis]'}
                                type={'number'}
                                max={60}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            <QuestionContentContainer name={'DemandeTarif[Antecedents][AnnulationPermis]'}>
                <QuestionLabel className={'f-14 mt-4'} required>{questions['permisAnnulation'][source]}&nbsp;?&nbsp;</QuestionLabel>

                <div className={'row'}>
                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][AnnulationPermis]'}
                            value={'true'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][AnnulationPermis]'}
                            value={'false'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            onChange={clearAntecendentAnnulationPermis}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {annulationPermis === 'true' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][DateAnnulationPermis]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>{questions['datePermisAnnulation'][source]}&nbsp;:&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={DateField}
                                name={'DemandeTarif[Antecedents][DateAnnulationPermis]'}
                                label={'JJ/MM/AAAA'}
                                validate={[requiredDateWithError, maxDateToday, minDateValidator]}
                                maxDate={moment()}
                                minDate={moment().subtract(3, 'years')}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {(annulationPermis === 'true' || suspensionPermis === 'true') && (
                <>
                    <QuestionContentContainer name={'DemandeTarif[Antecedents][CondamnationAlcoolemie]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>{questions['motifAlcoolemie'][source]}&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationAlcoolemie]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationAlcoolemie]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    onChange={() => changeValue('DemandeTarif[Antecedents][TxAlcool]', null)}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    {condamnationAlcoolemie === 'true' && (
                        <QuestionContentContainer name={'DemandeTarif[Antecedents][TxAlcool]'}>
                            <QuestionLabel className={'f-14 mt-4'} required>Quel taux en mg/L d'air expulsé&nbsp;?&nbsp;</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-8 col-md-8'}>
                                    <Field
                                        component={SlideContainer}
                                        name={'DemandeTarif[Antecedents][TxAlcool]'}
                                        step={0.01}
                                        min={0}
                                        max={2}
                                        validate={required}
                                        maxBackground={'bg-error'}
                                        minBackground={'bg-success'}
                                        minColor={'text-white'}
                                        maxColor={'text-white'}
                                        symbole={' mg/L'}
                                    />
                                </div>
                                <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                                    <Field
                                        component={Text}
                                        name={'DemandeTarif[Antecedents][TxAlcool]'}
                                        type={'number'}
                                        max={2}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    )}

                    <QuestionContentContainer name={'DemandeTarif[Antecedents][CondamnationStupefiants]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>{questions['motifStupefiant'][source]}&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationStupefiants]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationStupefiants]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    <QuestionContentContainer name={'DemandeTarif[Antecedents][CondamnationDelitFuite]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>{questions['motifDelitFuite'][source]}&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationDelitFuite]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationDelitFuite]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    <QuestionContentContainer name={'DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>{questions['motifConduiteSansAssurance'][source]}&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>
                </>
            )}

            <QuestionContentContainer name={'DemandeTarif[Antecedents][ResiliationAssureur]'}>
                <QuestionLabel className={'f-14 mt-4'} required>{questions['motifResiliation'][source]}&nbsp;?&nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][ResiliationAssureur]'}
                            value={'1'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][ResiliationAssureur]'}
                            value={'0'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            onChange={clearAntecendentResiliationAssureur}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {resiliationAssureur === '1' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>Pour quel motif&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                                value={'NP'}
                                type={'radio'}
                                label={'Non paiement de prime'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                                value={'FS'}
                                type={'radio'}
                                label={'Fréquence sinistres'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                                value={'FD'}
                                type={'radio'}
                                label={'Fausse déclaration'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                                value={'A'}
                                type={'radio'}
                                label={'Autres'}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {resiliationAssureur === '1' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][DateResiliationAssureur]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>A quelle date&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={DateField}
                                name={'DemandeTarif[Antecedents][DateResiliationAssureur]'}
                                label={'JJ/MM/AAAA'}
                                validate={[requiredDateWithError, maxDateToday, minDateValidator]}
                                maxDate={moment()}
                                minDate={moment().subtract(3, 'years')}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            <hr style={{ margin: '50px 0px 40px 0' }} />

            {(!_.includes(['quad', 'verte', '50'], familleProduit) &&
                !(
                    suspensionPermis === 'true' ||
                    annulationPermis === 'true' ||
                    resiliationAssureur === '1' ||
                    malusAutoMoto
                )  && source === 'conseiller') && (
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]'}>
                        <QuestionLabel className={'f-14 mt-4'}>Passer les antécedents sur 36 mois ?</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]'}
                                    value={'36'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    onChange={() => {
                                        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', '36');
                                        setAntecedentsSur3ans(true)
                                    }}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]'}
                                    value={'24'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    onChange={() => {
                                        setAntecedentsSur3ans(false)
                                        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', '24');
                                    }}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>
                )}

            <QuestionContentContainer name={'sinistre_auto'}>
                <SimpleTitle className={'mt-5'}>{questions['titreSinistres'][source]}</SimpleTitle>
                <QuestionLabel className={'f-14 mt-4'} required>{questions['sinistreAutoMoto'][source]} {antecedentsSur3ans ? '3 dernières années ?' : '2 dernières années ?'}</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'sinistre_auto'}
                            value={'1'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'sinistre_auto'}
                            value={'0'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            onChange={clearAntecendentListeSinistres}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {sinistre_auto === '1' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][ListeSinistres]'}>
                    <FieldArray
                        name={'DemandeTarif[Antecedents][ListeSinistres]'}
                        component={AddSinistre}
                        sinistres={sinistres}
                        className={'justify-content-start mt-5'}
                        col={'12'}
                        antecedentsSur3ans={antecedentsSur3ans}
                    />
                </QuestionContentContainer>
            )}
            {cylindreeVehicule > 50 && !_.includes(['quad', 'verte', '50'], familleProduit) ? (
                <QuestionContentContainer name={'assure_moto'}>
                    <SimpleTitle className={'mt-5'}>{questions['historiqueAssurance'][source]}</SimpleTitle>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        {questions['assure2roues'][source]} {antecedentsSur3ans ? '3 dernières années ?' : '2 dernières années ?'}
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'assure_moto'}
                                value={'1'}
                                type={'radio'}
                                label={'Oui'}
                                validate={required}
                                className={'small'}
                                onChange={() => {
                                    changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', firstValeurCrmMoto)
                                    changeValue('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', firstValeurCrmMotoPlus3Ans)
                                }}
                            />
                        </div>

                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'assure_moto'}
                                value={'0'}
                                type={'radio'}
                                label={'Non'}
                                validate={required}
                                onChange={() => {
                                    changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', null)
                                    changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', '1')
                                    changeValue('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', null)
                                }}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            ) : (
                ''
            )}
            {!_.includes(['quad', 'verte', '50'], familleProduit) && assure_moto === '1' && (
                <>
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>Indiquez le nombre de mois assuré en 2-roues</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-8 col-md-8'}>
                                <Field
                                    component={SlideContainer}
                                    name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'}
                                    step={1}
                                    min={1}
                                    max={antecedentsSur3ans ? 36 : 24}
                                    validate={required}
                                    minBackground={'bg-error'}
                                    maxBackground={'bg-success'}
                                    maxColor={'text-white'}
                                    minColor={'text-white'}
                                    symbole={' mois'}
                                />
                            </div>
                            <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'}
                                    type={'number'}
                                    max={antecedentsSur3ans ? 36 : 24}
                                />
                            </div>

                            {!isConcess && dejaClientAntecedents && !_.isEmpty(dejaClientAntecedents) && (
                                <div className={'col-12 bg-error text-white border p-4 rounded text-left mt-5 f-14'}>
                                    <p className={'d-inline-block'}>
                                        Merci de contrôler et de reprendre la valeur ci-dessous.
                                    </p>
                                    {dejaClientAntecedents.Resilie ? (
                                        <>
                                            <p className={'d-inline-block m-0'}>
                                                Le client a {dejaClientAntecedents.NbMoisAssurance} mois d'assurance MOTO chez nous sur les 24 derniers mois.
                                            </p>
                                            {dejaClientAntecedents.VerificationNecessaire && (
                                                <p className={'d-inline-block font-weight-bold mb-0 mt-3'}>
                                                    Pensez à redemander TOUS les antécédents du client sur les 24
                                                    derniers mois.
                                                </p>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <p className={'d-inline-block m-0'}>
                                                Le client a {dejaClientAntecedents.NbMoisAssurance} mois d'assurance
                                                MOTO chez nous.
                                            </p>
                                            {_.find(dejaClientAntecedents, function (d) {
                                                return d.Type === 'quad' || d.Type === 'verte' || d.Type === '50';
                                            }) &&
                                                dejaClientAntecedents.VerificationNecessaire && (
                                                    <p className={'d-inline-block font-weight-bold mb-0 mt-3'}>
                                                        Attention, le dernier contrat souscrit par le client n'avait pas
                                                        d'antécédents déclarés à la souscription. Pensez à redemander
                                                        TOUS les antécédents du client sur les 24 derniers mois.
                                                    </p>
                                                )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </QuestionContentContainer>
                    {!_.includes(['quad', 'verte', '50'], familleProduit) &&
                        ((assure_moto === '0' && valeurCrmMoto !== undefined && valeurCrmMoto !== '1.00' && valeurCrmMoto !== '1') ||
                            (nbMoisAssuranceMoto === undefined &&
                                valeurCrmMoto !== undefined &&
                                valeurCrmMoto !== '1.00' &&
                                valeurCrmMoto !== '1'
                            )) && (
                            <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                                <p className={'mb-0'}>
                                    <strong>Attention !</strong> vous avez declaré un bonus de {valeurCrmMoto} sans
                                    assurance moto sur les derniers mois. Sans modification de votre part, le bonus
                                    declaré passera à 1.00 automatiquement.
                                </p>
                            </div>
                        )}
                </>
            )}

            {
                nbMoisAssuranceMoto >= 12 && titulaireCarteGrise !== "2" && <>
                    <QuestionContentContainer name={'dateSouscriptionAncienContrat'}>
                        <QuestionLabel className={'f-14 mt-4'} required>
                            {questions['dateSouscriptionAncienContrat'][source]}
                        </QuestionLabel>
                        <div className={'row text-center'}>
                            <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'dateSouscriptionAncienContrat'}
                                    label={'Il y a moins d\'un an'}
                                    value={'0'}
                                    type={'radio'}
                                    validate={required}
                                />
                            </div>
                            <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'dateSouscriptionAncienContrat'}
                                    label={'Il y a plus d\'un an'}
                                    value={'1'}
                                    type={'radio'}
                                    validate={required}
                                    onChange={() => showPopup()}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>
                </>
            }

            {typePermisB && (
                <>
                    {!_.includes(['quad', 'verte', '50'], familleProduit) &&
                        <QuestionContentContainer name={'assure_auto'}>
                            <QuestionLabel className={'f-14 mt-4'} required>Avez-vous été assuré en auto au cours
                                des {antecedentsSur3ans ? '3 dernières années ?' : '2 dernières années ?'}</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'assure_auto'}
                                        value={'1'}
                                        type={'radio'}
                                        label={'Oui'}
                                        validate={required}
                                        className={'small'}
                                        onChange={() => {
                                            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', firstValeurCrmAuto)
                                            changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', firstValeurCrmAutoPlus3Ans)
                                        }}
                                    />
                            </div>

                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'assure_auto'}
                                    value={'0'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    onChange={() => {
                                        changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', null)
                                        changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', '1')
                                        changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', null)
                                    }}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer> }
                    {!_.includes(['quad', 'verte', '50'], familleProduit) &&
                        ((assure_auto === '0' && valeurCrmAuto !== undefined && valeurCrmAuto !== '1.00' && valeurCrmAuto !== '1') ||
                            (nbMoisAssuranceAuto === undefined &&
                                valeurCrmAuto !== undefined &&
                                valeurCrmAuto !== '1.00' &&
                                valeurCrmAuto !== '1')) && (
                            <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                                <p className={'mb-0'}>
                                    <strong>Attention !</strong> vous avez declaré un bonus de {valeurCrmAuto} sans
                                    assurance auto sur les derniers mois. Sans modification de votre part, le bonus
                                    declaré passera à 1.00 automatiquement.
                                </p>
                            </div>
                        )}
                </>
            )}

            {!_.includes(['quad', 'verte', '50'], familleProduit) && assure_auto === '1' && (
                <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>Indiquez le nombre de mois assuré en auto</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-8 col-md-8'}>
                            <Field
                                component={SlideContainer}
                                name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'}
                                step={1}
                                min={1}
                                max={antecedentsSur3ans ? 36 : 24}
                                validate={required}
                                minBackground={'bg-error'}
                                maxBackground={'bg-success'}
                                maxColor={'text-white'}
                                minColor={'text-white'}
                                symbole={' mois'}
                            />
                        </div>
                        <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'}
                                type={'number'}
                                max={antecedentsSur3ans ? 36 : 24}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {familleProduit === 'quad' || familleProduit === 'verte' ? (
                <>
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}>
                        <QuestionLabel required>Avez-vous du malus&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}
                                    value={'onError'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={[required, onError]}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}
                                    value={'1'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={[required, onError]}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    {valeurCrmMoto === 'onError' && (
                        <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>
                                Nous ne pouvons pas proposer de tarif pour les bonus supérieurs à 1.00
                            </p>
                        </div>
                    )}
                </>
            ) : (
                ''
            )}

            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn btn-primary float-right medium-rounded mb-2 order-lg-1`}
                    onClick={valid ? handleSubmit(nextStep) : null}
                    data-id="btn-next"
                >
                    {fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Continuer'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>
        </div>
    );
};

Antecedents.defaultProps = {};

Antecedents.propTypes = {};

export default Antecedents;
