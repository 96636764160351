import { QuestionLabel } from '@amo/core/components/containers';
import { Autocomplete, Text } from '@amo/core/components/forms';
import { normalizeDecimal } from '@amo/core/utils/normalize';
import { getRaisonSocialeBySirenV2 } from '@amo/core/utils/requestsApi';
import { required } from '@amo/core/utils/validateField';
import { listFormeJuridique } from '@amo/core/utils/valuesSelect';
import { useRef, useState } from 'react';
import { Field } from 'redux-form';
import questions from '../../../../../../utils/json/questions.json';

// {
//     "denomination": "APRIL MOTO",
//     "adresse": {
//     "rue": "14 QUAI DE MARMOUTIER",
//         "codePostal": "37100",
//         "ville": "TOURS",
//         "pays": "FRANCE"
// },
//     "dateCreation": "19\/08\/1994",
//     "codeJuridique": "5710",
//     "statutJuridique": "SAS"
// }

export default function SIREN({ changeValue, source }) {
    const [loadingSIREN, setLoadingSIREN] = useState(false);
    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [needManualReason, setNeedManualReason] = useState(false); // [TODO] : needManualReason is always false, so the following condition is always false too : !_.get(props.data, 'DemandeTarif[ListePersonnes][1][Nom]') && errorSIREN
    const [errorSIREN, setErrorSIREN] = useState(false);
    const inputSIREN = useRef(null);
    const [society, setSociety] = useState({});

    const fetchGetSociety = async (value) => {
        setLoadingSIREN(true);

        try {
            let society = await getRaisonSocialeBySirenV2(value);
            setSociety(society);
            changeValue('DemandeTarif[ListePersonnes][1][Nom]', society.denomination);

            const adresse = society.adresse;

            changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', adresse.rue)
            changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', '');
            changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', '');
            changeValue('DemandeTarif[ListePersonnes][0][CP]', adresse.codePostal);
            changeValue('DemandeTarif[ListePersonnes][0][Ville]', adresse.ville);

            changeValue('DemandeTarif[ListePersonnes][1][LegalStatus]', listFormeJuridique.find((item) => item.id === society.statutJuridique).id);
            // console.log('society.statutJuridique', society.statutJuridique, listFormeJuridique.find((item) => item.id === society.statutJuridique));

            setErrorSIREN(false);
        } catch (e) {
            if (e.response.status === 500) {
                setNeedManualReason(true);
                setErrorSIREN(e.response.data.message);
            } else {
                setErrorSIREN(e.response.data.message);
            }
        }

        setIsFirstFetch(false);
        setLoadingSIREN(false);
    };

    const whenSIRENChange = () => {
        changeValue('DemandeTarif[ListePersonnes][1][Nom]', '');

        changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', '');
        changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', '');
        changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', '');
        changeValue('DemandeTarif[ListePersonnes][0][CP]', '');
        changeValue('DemandeTarif[ListePersonnes][0][Ville]', '');
        setIsFirstFetch(true);
        setSociety({})
    };

    return (
        <>
            <QuestionLabel className={'f-14 mt-4'} required>
                Indiquez son numéro de SIREN&nbsp;
            </QuestionLabel>
            <div className="row">
                <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                    <Field component={Text} name={'DemandeTarif[ListePersonnes][1][IdSociety]'} normalize={normalizeDecimal} required onChange={whenSIRENChange} ref={inputSIREN} type={'tel'} />

                    {errorSIREN && <div className={'alert-danger alert mb-0'}>{errorSIREN}</div>}

                    {needManualReason && (
                        <div className={'alert-danger alert mb-0'}>
                            Nous n’arrivons pas à récupérer les informations de votre société depuis l’INPI. Merci de compléter les informations suivantes manuellement.
                        </div>
                    )}

                    <span className="btn btn-secondary btn-smaller mt-2" onClick={() => fetchGetSociety(inputSIREN.current.value)}>
                        {loadingSIREN ? (
                            <div className="spinner-border" role="status" style={{ width: 19, height: 19 }}>
                                <span className="sr-only" />
                            </div>
                        ) : (
                            'Je valide mon numéro de SIREN'
                        )}
                    </span>
                </div>
            </div>

            {society?.denomination && !errorSIREN && (
                <dl style={{ marginTop: '20px', display: 'grid', gridTemplateColumns: '1fr 1fr' }} className="alert-success alert p-3 rounded-lg">
                    <dt>Raison sociale</dt>
                    <dd>{society.denomination}</dd>
                    <dt>Statut juridique</dt>
                    <dd>{listFormeJuridique.find((item) => item.id === society.statutJuridique)?.name ?? society.statutJuridique}</dd>
                    <dt>Date de création</dt>
                    <dd>{society.dateCreation}</dd>
                    <dt>Code postal</dt>
                    <dd>{society.adresse.codePostal}</dd>
                    <dt>Ville</dt>
                    <dd>{society.adresse.ville}</dd>
                    <dt>Adresse</dt>
                    <dd>{society.adresse.rue}</dd>
                </dl>
            )}

            {needManualReason && errorSIREN && (
                <>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        {questions['raisonSocialSociete'][source]}&nbsp;?&nbsp;
                    </QuestionLabel>

                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                            <Field component={Text} name={`DemandeTarif[ListePersonnes][1][Nom]`} validate={required} />
                        </div>
                    </div>

                    <QuestionLabel className={'f-14 mt-4'} required>
                        Quel est le statut juridique de son entreprise&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className="row">
                        <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                            <Field
                                name={'DemandeTarif[ListePersonnes][1][LegalStatus]'}
                                component={Autocomplete}
                                data={listFormeJuridique.map((item) => ({ label: item.name, value: item.id }))}
                                required
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
