import AntecedentsContainer from './Steps/Antecedents/AntecedentsContainer';
import BesoinsContainer from './Steps/Besoins/BesoinsContainer';
import EnvoiPiecesContainer from './Steps/EnvoiPieces/EnvoiPiecesContainer';
import InformationsConducteurContainer from './Steps/InformationsConducteur/InformationsConducteurContainer';
import InformationsVehiculeContainer from './Steps/InformationsVehicule/InformationsVehiculeContainer';
import PaiementContainer from './Steps/Paiement/PaiementContainer';
import ProfilContainer from './Steps/Profil/ProfilContainer';
import RecapitulatifContainer from './Steps/Recapitulatif/RecapitulatifContainer';
import RecontactContainer from './Steps/Recontact/RecontactContainer';
import SignatureContainer from './Steps/Signature/SignatureContainer';
import TarifContainer from './Steps/Tarif/TarifContainer';
import ValidationContainer from './Steps/Validation/ValidationContainer';
import VehiculeContainer from './Steps/Vehicule/VehiculeContainer';

let step = 0;

const RouteStep = [
    {
        step: step++,
        slug: 'votre-vehicule',
        title: 'Votre véhicule',
        path: '/votre-vehicule',
        main: (props) => <VehiculeContainer {...props} />,
    },
    {
        step: step++,
        slug: 'informations',
        title: 'Informations',
        path: '/informations-vehicule',
        main: (props) => <InformationsVehiculeContainer {...props} />,
    },
    {
        step: step++,
        slug: 'votre-profil',
        title: 'Votre profil',
        path: '/votre-profil',
        main: (props) => <ProfilContainer {...props} />,
    },
    {
        step: step++,
        slug: 'vos-antecedents',
        title: 'Vos antécédents',
        path: '/vos-antecedents',
        main: (props) => <AntecedentsContainer {...props} />,
    },
    {
        step: step++,
        slug: 'vos-besoins',
        title: 'Recueil du besoin',
        path: '/vos-besoins',
        main: (props) => <BesoinsContainer {...props} />,
    },
    {
        step: step++,
        slug: 'votre-tarif',
        title: 'Votre tarif',
        path: '/votre-tarif',
        main: (props) => <TarifContainer {...props} />,
    },
    {
        step: step++,
        slug: 'vos-infos',
        title: 'Vos infos',
        path: '/vos-infos',
        main: (props) => <InformationsConducteurContainer {...props} />,
    },
    {
        step: step++,
        slug: 'recapitulatif',
        title: 'Récapitulatif',
        path: '/recapitulatif',
        main: (props) => <RecapitulatifContainer {...props} />,
    },
    {
        step: step++,
        slug: 'paiement',
        title: 'Paiement',
        path: '/paiement',
        main: (props) => <PaiementContainer {...props} />,
    },
    {
        step: step++,
        slug: 'validation',
        title: 'Validation',
        path: '/validation',
        main: (props) => <ValidationContainer {...props} />,
    },
    {
        step: step++,
        slug: 'signature',
        title: 'Signature',
        path: '/signature',
        main: (props) => <SignatureContainer {...props} />,
    },
    {
        step: step++,
        slug: 'envoi-des-pieces',
        title: 'Envoi des pièces',
        path: '/envoi-des-pieces',
        main: (props) => <EnvoiPiecesContainer {...props} />,
    },
    {
        step: step++,
        slug: 'recontact',
        title: 'Etre recontacté',
        path: '/recontact',
        main: (props) => <RecontactContainer {...props} />,
    },
];

export default RouteStep;
