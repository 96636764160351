import PropTypes from 'prop-types';
import SideBarContent from '../SideBarContent/SideBarContent';

const BesoinsSideBar = (props) => {
    const {
        incendie,
        accidents,
        remorquage,
        indemnisation,
        remplacement,
        accessoires,
        rachat,
        isCurrentStep,
        slug,
        goTo,
    } = props;

    return (
        <SideBarContent
            title={'Recueil du besoin'}
            icon={'security'}
            isCurrentStep={isCurrentStep}
            slug={slug}
            goTo={goTo}
            tabContent={[
                { title: 'Assurance vol ou incendie', value: incendie },
                { title: 'Assurance en cas de dommages tous accidents (Tous risques)', value: accidents },
                { title: 'Prise en charge (remorquage) en cas de panne', value: remorquage },
                { title: 'Véhicule de remplacement', value: remplacement },
                {
                    title: 'Indemnisation en cas d’accident responsable pouvant vous engendrer de graves blessures, voire même un décès',
                    value: indemnisation,
                },
                { title: 'Assurance des accessoires et équipements', value: accessoires },
                { title: 'Rachat de franchise', value: rachat },
            ]}
        />
    );
};

BesoinsSideBar.propTypes = {
    slug: PropTypes.string.isRequired,
    goTo: PropTypes.func,
};

export default BesoinsSideBar;
