export const APP_ENV = import.meta.env.VITE_VERCEL_ENV ?? 'development';
export const APP_URL_PASS = import.meta.env.VITE_PASS_URL;
export const API_URL_WS_FORMULAIRE = import.meta.env.VITE_WS_FORMULAIRE_URL;
export const APP_NAME = 'amo-devis';
export const APP_URL_APPLICATION = window.location.origin;
export const API_URL_WHEELS = import.meta.env.VITE_WHEELS_URL;
export const GRAVITEE_API_KEY_IBAN = import.meta.env.VITE_GRAVITEE_API_KEY_IBAN;
export const GRAVITEE_URL = import.meta.env.VITE_GRAVITEE_URL;
export const SECRET_ENCRYPT = import.meta.env.VITE_SECRET_ENCRYPT;
export const SERVICES_URL = import.meta.env.VITE_SERVICES_URL;
export const DOCUMENTS_URL = import.meta.env.VITE_DOCUMENTS_URL;
