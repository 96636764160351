import PropTypes from 'prop-types';
import SideBarContent from '../SideBarContent/SideBarContent';

const InformationsVehiculeSideBar = (props) => {
    const {
        dateMEC,
        possessionPlusDe3Mois,
        assure3DerniersMois,
        usageVehicule,
        usageNonLivraison,
        valeurVehicule,
        vehiculeImmatricule,
        carteGriseFrancaise,
        villeGarage,
        codePostalGarage,
        isCurrentStep,
        slug,
        goTo,
    } = props;

    let communeStationnement = '';
    communeStationnement += villeGarage ? ` ${villeGarage}` : '';
    communeStationnement += codePostalGarage ? ` ${codePostalGarage}` : '';

    return (
        <SideBarContent
            title={'Votre usage'}
            icon={'beneficiaire'}
            isCurrentStep={isCurrentStep}
            slug={slug}
            goTo={goTo}
            tabContent={[
                { title: 'Date de première mise en circulation', value: dateMEC },
                { title: 'Vous possédez ce véhicule', value: possessionPlusDe3Mois },
                { title: 'Au cours des trois derniers mois ce véhicule était assuré', value: assure3DerniersMois },
                { title: 'Vous utilisez ce véhicule pour', value: usageVehicule },
                {
                    title: 'Ce véhicule est destiné à un usage professionnel de restauration ou livraison',
                    value: usageNonLivraison,
                },
                { title: 'Valeur du véhicule', value: valeurVehicule },
                { title: 'Le véhicule est immatriculé', value: vehiculeImmatricule },
                { title: 'La carte grise est', value: carteGriseFrancaise },
                { title: 'Commune de stationnement', value: communeStationnement },
            ]}
        />
    );
};

InformationsVehiculeSideBar.propTypes = {
    slug: PropTypes.string.isRequired,
    goTo: PropTypes.func,
};

export default InformationsVehiculeSideBar;
