import { setError, setLoader } from '../../../actions/app/ui/ui.actions';
import {
    GET_VEHICULE,
    POST_VEHICULE_IS_DISABLED,
    setVehicule,
    VEHICULE,
} from '../../../actions/app/vehicule/vehicule.actions';
import { apiRequest, API_ERROR, API_SUCCESS } from '../../../actions/core/api/api.actions';

export const vehiculeMiddleware = () => (next) => (action) => {
    next(action);

    const { payload } = action;

    switch (action.type) {
        case GET_VEHICULE:
            next([
                apiRequest({ body: null, method: 'GET', url: payload.data, entity: VEHICULE }),
                setLoader({ state: true, entity: VEHICULE }),
            ]);
            break;

        case `${VEHICULE} ${API_SUCCESS}`:
            next([
                setVehicule({ data: payload.data }),
                setError({ state: false, entity: VEHICULE }),
                setLoader({ state: false, entity: VEHICULE }),
            ]);
            break;

        case `${VEHICULE} ${API_ERROR}`:
            next([setError({ state: true, entity: VEHICULE }), setLoader({ state: false, entity: VEHICULE })]);
            break;

        case POST_VEHICULE_IS_DISABLED:
            next([
                apiRequest({
                    body: payload.body,
                    method: 'POST',
                    url: payload.data,
                    entity: POST_VEHICULE_IS_DISABLED,
                }),
                setLoader({ state: true, entity: POST_VEHICULE_IS_DISABLED }),
            ]);
            break;

        default:
            return null;
    }
};
