import {QuestionLabel, Card, TableCollapse, Icons, SimpleTitle} from '@amo/core/components/containers';
import {Checkbox, DateTimePicker, PostalCodeCity, Radio, Select, Text} from '@amo/core/components/forms';
import { normalizeImmatriculation, normalizeNumMarquage, normalizeTel } from '@amo/core/utils/normalize';
import { checkIban, getCompanys } from '@amo/core/utils/requestsApi.js';
import {
    excludeCodePostal,
    maxDate30Days,
    maxDate33Days,
    minDateHourToday,
    minLentghImmatriculation,
    required,
    requiredDateWithError,
    validateEmail,
    validatePhone,
} from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form';
import questions from '../../../../../../utils/json/questions.json';
import TiersPayeurContainer from './TiersPayeur/TiersPayeurContainer';
import Swal from "sweetalert2";

const Recapitulatif = (props) => {
    const {
        isMB,
        isConcess,
        devisHamon,
        conducteur,
        addressConducteur,
        crmAuto,
        crmMoto,
        marque,
        modele,
        version,
        dateMec,
        usage,
        formule,
        fractionnement,
        changeValue,
        prelevement_auto,
        postalCodeCity,
        VehiculeImmatricule,
        permis,
        nomTitulaire,
        nomPrenom,
        adresseIban,
        cpIban,
        neConnaitPasNumeroContratActuel,
        villeIban,
        dateHeureEffet,
        source,
        ibanIsCheck,
        valid,
        handleSubmit,
        postPaySubmit,
        payLoaded,
        previousStep,
        nbMoisAssuranceMoto,
        typeVehicule,
        honoraires,
        taux_apporteur,
        frais_recurrents,
        honoraires_choisi,
        souscripteurIsSociety,
        apporteur1,
        cylindree,
        vehicule,
        titulaireCarteGriseSo,
        immatriculationAlreadySet,
        searchBy
    } = props;
    const [companys, setCompanys] = useState([]);
    const [errorIban, setErrorIban] = useState(null);
    const [loadingIban, setLoadingIban] = useState(false);
    const [immatDisabled, setImmatDisabled] = useState(immatriculationAlreadySet && searchBy === "immatriculation");
    const inputIban = useRef('');

    let modeleVehicule = '';
    modeleVehicule += marque !== undefined ? marque : '';
    modeleVehicule += cylindree !== undefined ? ` ${cylindree}` : '';
    modeleVehicule += modele !== undefined ? ` ${modele}` : '';

    let date = `${
        vehicule.annee_debut_version !== null && vehicule.annee_debut_version !== 0
            ? `depuis ${vehicule.annee_debut_version}`
            : ''
    } ${
        vehicule.annee_fin_version !== null && vehicule.annee_fin_version !== 0
            ? ` jusqu'a ${vehicule.annee_fin_version}`
            : ''
    }`;

    useEffect(() => {
        (async function fetchCompanys() {
            let listCompanys = await getCompanys();
            setCompanys(listCompanys);
        })();

        if (fractionnement === 'M') {
            changeValue('DemandeContrat[ModePaiementTerme]', 'P');
        }

        changeValue('DemandeContrat[NomTitulaire]', nomPrenom);
        changeValue('DemandeContrat[adrIBAN]', adresseIban);
        changeValue('DemandeContrat[cpIBAN]', cpIban);
        changeValue('DemandeContrat[villeIBAN]', villeIban);
        changeValue('postalCodeCity-villeIban', true);
        changeValue('DemandeContrat[TiersPayeur]', {});
    }, []);

    const changeLienPaiement = (value) => {
        if (value === 'true') {
            changeValue('DemandeContrat[ModePaiementComptant]', 'M');
        } else if (value === 'cheque') {
            changeValue('DemandeContrat[ModePaiementComptant]', 'C');
        } else {
            changeValue('DemandeContrat[ModePaiementComptant]', null);
        }
    };

    const changeImmatriculation = (value) => {
        if (value === 'VIN') {
            changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', 0);
        } else {
            changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', 1);
        }
    };
    const verificationTitulaire = (value) => {
        if (value && _.toUpper(nomPrenom) === _.toUpper(value)) {
            changeValue('DemandeContrat[TiersPayeur]', {});
        }
    };

    const fetchCheckIban = async (value) => {
        setLoadingIban(true);
        let iban = await checkIban(value);
        setLoadingIban(false);
        changeValue('DemandeContrat[BIC]', iban.bank_data.bic);

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null;

        _.forEach(fieldValidations, (field) => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)) {
                error = iban.validations[field].message;
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === 'NO' || iban.sepa_data.SCT === 'NO') {
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires");
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null);
    };

    useEffect(() => {
        if (source === 'site') {
            changeValue('lienPaiementEmail', 'false');
        }
    }, []);

    const validateDateHeureEffet = () => {
        let array = [requiredDateWithError, minDateHourToday]

        if (devisHamon && devisHamon === '1'){
            array.push(maxDate33Days)
        } else {
            if(_.includes([479846, 111619, 479835], apporteur1.codeBelair)){
                array.push(maxDate30Days)
            } else {
                array.push(maxDate33Days)
            }
        }

        return array
    }

    const showPopup = () => {
        Swal.fire({
            icon: 'info',
            text: "Toutes ces informations sont présentes sur votre avis d'échéance !",
        });
    }

    const popupHamon = (e) => {
        if (!neConnaitPasNumeroContratActuel){
            changeValue("DemandeContrat[DevisHamonContratActuel]", "")
            Swal.fire({
                icon: 'info',
                text: "Soyez tranquille, un conseiller va venir vers vous dans les plus brefs délais pour prendre en charge les démarches de résiliation de votre assurance.",
                confirmButtonText: "J'ai bien compris"
            });
        }
    }



    return (
        <div>
            <SimpleTitle>{questions['titreRecapitulatif'][source]}</SimpleTitle>

            <div className={'row'}>
                <div className={'col-12 col-md-6 text-initial'}>
                    <QuestionLabel>{questions['informationsClient'][source]}</QuestionLabel>
                    <Card icon={'beneficiaire'} title={conducteur}>
                        <p className={'mb-2'}>
                            Adresse
                            <br />
                            <span className={'fw-bold'}>{addressConducteur}</span>
                        </p>

                        {crmAuto && (
                            <p className={'mb-2'}>
                                Bonus / Malus Auto:{' '}
                                <span className={'fw-bold'}>{_.round(crmAuto, 2)}</span>
                            </p>
                        )}

                        {crmMoto && (
                            <p className={'mb-3'}>
                                Bonus / Malus 2-roues:{' '}
                                <span className={'fw-bold'}>{_.round(crmMoto, 2)}</span>
                            </p>
                        )}

                        {_.find(permis, function (p) {
                            return p && p.TypePermis;
                        }) !== undefined && (
                            <div className={'mb-3'}>
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[ListePersonnes][0][NumPermis]'}
                                    label={
                                        <span>
                                            Numéro du permis&nbsp;<sup className={'text-error'}>*</sup>
                                        </span>
                                    }
                                    normalize={(v) => _.trim(v.toUpperCase())}
                                    validate={required}
                                />
                            </div>
                        )}

                        <div className={'mb-3'}>
                            {VehiculeImmatricule ? (
                                <>
                                    <Field
                                        component={Text}
                                        name={'DemandeTarif[Vehicule][VIN]'}
                                        label={
                                            <span>
                                                Numéro de série&nbsp;<sup className={'text-error'}>*</sup>
                                            </span>
                                        }
                                        normalize={normalizeNumMarquage}
                                        validate={[required]}
                                        onChange={() => changeImmatriculation('VIN')}
                                    />
                                    <div className={'row'}>
                                        <div className={'col-12 col-md-12 mt-3 mt-md-2'}>
                                            <Field
                                                name="immatriculation_check"
                                                component={Checkbox}
                                                type={'checkbox'}
                                                format={(v) => v === '0'}
                                                normalize={(v) => (v ? '0' : '1')}
                                                onChange={() => changeValue('DemandeTarif[Vehicule][VIN]', null)}
                                            >
                                                <div className={'f-14 mx-2'}>Le véhicule a une immatriculation</div>
                                            </Field>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Field
                                        component={Text}
                                        name={'DemandeTarif[Vehicule][Immatriculation]'}
                                        label={
                                            <span>
                                                Numéro d'immatriculation&nbsp;<sup className={'text-error'}>*</sup>
                                            </span>
                                        }
                                        normalize={normalizeImmatriculation}
                                        validate={[required, minLentghImmatriculation]}
                                        onChange={() => changeImmatriculation('Immat')}
                                        disabled={immatDisabled}
                                    />
                                    <div className={'row'}>
                                        <div className={'col-12 col-md-12 mt-3 mt-md-2'}>
                                            <Field
                                                name="immatriculation_check"
                                                component={Checkbox}
                                                type={'checkbox'}
                                                format={(v) => v === '0'}
                                                normalize={(v) => (v ? '0' : '1')}
                                                onChange={() => changeValue('DemandeTarif[Vehicule][Immatriculation]', null)}
                                            >
                                                <div className={'f-14 mx-2'}>Le véhicule n'a pas d'immatriculation</div>
                                            </Field>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className={'mb-3'}>
                            <Field
                                component={Text}
                                label={
                                    <span>
                                        Numéro de téléphone&nbsp;<sup className={'text-error'}>*</sup>
                                    </span>
                                }
                                name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                                icon={'phone'}
                                iconSize={18}
                                validate={[required, validatePhone]}
                                normalize={normalizeTel}
                                onChange={(e) =>
                                    changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value))
                                }
                            />
                        </div>
                        <Field
                            component={Text}
                            label={
                                <span>
                                    Adresse email&nbsp;<sup className={'text-error'}>*</sup>
                                </span>
                            }
                            name={'DemandeTarif[ListePersonnes][0][Email]'}
                            icon={'envelope'}
                            validate={[required, validateEmail]}
                            iconSize={18}
                            onChange={(e) => changeValue('DemandeContrat[SignatureEmail]', e.target.value)}
                        />
                    </Card>
                </div>

                <div className={'col-12 col-md-6 text-initial'}>
                    <div className={'h-100 d-flex flex-column '}>
                        <QuestionLabel>{questions['recapVehicule'][source]}</QuestionLabel>
                        <Card icon={'moto'} title={'Votre véhicule'} className={'flex-fill'}>
                            <div className={'text-primary f-14 font-weight-bold mb-2'}>{marque}</div>

                            <p className={'mb-2'}>
                                {modeleVehicule} <small>{date}</small>
                            </p>

                            <p className={'mb-2'}>
                                Mise en circulation
                                <br />
                                <span className={'fw-bold'}>{dateMec}</span>
                            </p>

                            <p className={'mb-2'}>
                                Usage
                                <br />
                                <span className={'fw-bold'}>{usage}</span>
                            </p>
                        </Card>
                    </div>
                </div>
            </div>

            <QuestionLabel className={'mt-5'}>Récapitulatif</QuestionLabel>
            <QuestionLabel className={'f-14 my-4'}>
                {questions['choixFormule'][source]} : <span className={'text-primary-light'}>{formule?.LibelleFormule}</span>
            </QuestionLabel>

            <div className={'row my-4'}>
                <div className={'col-12 col-md-12'}>
                    <div className={`scrollingTable`}>
                        <div className="table-responsive">
                            <table className={'table mb-0'}>
                                <thead>
                                <tr>
                                    <th scope="col" className={'bg-secondary-light text-initial border-bottom-0 w-50 fw-bold '}>
                                        Vos garanties
                                    </th>
                                    <th scope="col" className={'bg-primary text-white border-bottom-0 w-50 fw-bold'}>
                                        <p className={'mb-0 d-flex justify-content-between align-items-center'}>
                                            <span>{formule?.LibelleFormule}</span>
                                            <span className={'f-16 font-weight-normal'}>
                                        {fractionnement === 'M'
                                            ? `${_.round(formule?.PrimeMensuellePromo, 2)}/mois`
                                            : `${_.round(formule?.ComptantAnnuelPromo, 2)}/an`}
                                    </span>
                                        </p>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className={'text-center'}>
                                {_.map(formule?.ListeGaranties, (g, i) => {
                                    return (
                                        g.Souscrite && (
                                            <tr key={i}>
                                                <th scope="row" className={'f-14 font-weight-normal text-initial text-left'}>
                                                    <TableCollapse title={<div>{g.LibelleGarantie}</div>}>
                                                        {g.texteDeroulant && <p>{g.courtier ?? g.texteDeroulant}</p>}
                                                    </TableCollapse>
                                                </th>
                                                <td className={'align-middle bg-primary'}>
                                                    <Icons
                                                        className={`text-primary-light p-2 border full-rounded border-white text-white`}
                                                        fontSize={5}
                                                        icon={'Shape'}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <QuestionLabel className={'mt-5'}>Paiement</QuestionLabel>
            {fractionnement === 'M' ? (
                <>
                    <p className={'f-14 mt-4'}>
                        Montant à régler (TTC)&nbsp;: <strong>{_.round(formule?.ComptantMensuelPromo, 2)}€</strong>{' '}
                        correspondant à vos deux premières mensualités.
                    </p>
                    <p className={'f-14 mt-4'}>
                        Les prochains prélèvements seront de{' '}
                        <strong>
                            {_.round(formule?.PrimeMensuellePromo, 2)}€ et débuteront à partir du troisième mois.
                        </strong>
                    </p>
                </>
            ) : (
                <>
                    <p className={'f-14 mt-4'}>
                        Montant à régler (TTC)&nbsp;: <strong>{_.round(formule?.ComptantAnnuelPromo, 2)}€</strong>
                    </p>
                </>
            )}

            {!isConcess && !isMB && (
                <>
                    <p className={'f-14 mt-4'}>
                        Votre taux de commission&nbsp;: <strong>{_.round(taux_apporteur, 2)}%</strong> soit{' '}
                        {_.round(honoraires_choisi, 2)}€
                    </p>
                    <p className={'f-14 mt-4'}>
                        Vos frais de dossier&nbsp;: <strong>{_.round(honoraires, 2)}€</strong>
                    </p>
                    {parseInt(frais_recurrents) > 0 && (
                        <p className={'f-14 mt-4'}>
                            Vos frais récurrents&nbsp;: <strong>{_.round(frais_recurrents, 2)}€</strong>
                        </p>
                    )}
                </>
            )}

            {(nbMoisAssuranceMoto > 11 || _.includes(['50', 'quad', 'verte'], typeVehicule)) &&
                !souscripteurIsSociety && !titulaireCarteGriseSo && (
                    <>
                        <QuestionLabel>La loi Hamon</QuestionLabel>

                        <p className={'f-14 mt-4'} required>
                            {questions['loiHamon'][source]}&nbsp;?&nbsp;
                        </p>
                        <div className={'row'}>
                            <div
                                className={'col-6 text-left col-md-4 mt-3 mt-md-2'}
                                data-name="DemandeContrat[DevisHamon]"
                            >
                                <Field
                                    component={Radio}
                                    name={'DemandeContrat[DevisHamon]'}
                                    value={'1'}
                                    type={'radio'}
                                    label={'Oui'}
                                    onChange={() =>
                                        changeValue('DemandeContrat[DateHeureEffet]', moment().add(33, 'days'))
                                    }
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeContrat[DevisHamon]'}
                                    value={'0'}
                                    type={'radio'}
                                    label={'Non'}
                                    onChange={() => {
                                        if (
                                            dateHeureEffet &&
                                            moment.isMoment(dateHeureEffet) &&
                                            moment(dateHeureEffet)._isValid &&
                                            dateHeureEffet.diff(moment().endOf('day'), 'days') >= 30
                                        ) {
                                            changeValue('DemandeContrat[DateHeureEffet]', moment().add(15, 'minutes'));
                                        }
                                    }}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </>
                )}

            <p className={'f-14 mt-4'}>
                {devisHamon && devisHamon === '1' ? "Le nouveau contrat pourra commencer au plus tôt le :" : "Date et heure d'effet"}
            </p>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-3 mt-md-2'}>
                    <Field
                        component={DateTimePicker}
                        changeValue={changeValue}
                        name={'DemandeContrat[DateHeureEffet]'}
                        label={'JJ/MM/AAAA HH:MM'}
                        validate={validateDateHeureEffet()}
                        minDate={
                            devisHamon && devisHamon === '1' ? new Date(moment().add(33, 'days')) : new Date(moment())
                        }
                    />
                </div>
                {
                    devisHamon && devisHamon === '1' &&
                    <div className={'bg-secondary-light p-4 rounded text-left mt-3 f-14'}>
                        <p className={'mb-0'}>
                            {questions['texteDateHeureEffetHamon'][source]}
                        </p>
                    </div>
                }

            </div>

            {devisHamon && devisHamon === '1' && (
                <div>
                    <Field
                        name="confirm-souscripteur"
                        component={Checkbox}
                        type={"checkbox"}
                        className={"ml-2 mt-4"}
                        validate={required}
                    >
                        <div className={'f-14 mx-2 mt-2 cursor-pointer'}>J'atteste que le souscripteur du contrat actuel
                            est bien {conducteur}<small className={'text-error text-danger f-16'}>*</small></div>
                    </Field>
                    <p className={'f-14 mt-4'} required>
                        {questions['assureurActuel'][source]}&nbsp;?&nbsp;<Icons className={`ml-2 cursor-pointer icon-arrow text-initial`} fontSize={16} icon={'question'} onClick={() => showPopup()}/>
                    </p>
                    <div className={'row'}>
                        <div
                            className={'col-12 col-md-4 mt-3 mt-md-2'}
                            data-name="DemandeContrat[DevisHamonAssureurActuel]"
                        >
                            <Field
                                component={Select}
                                name={'DemandeContrat[DevisHamonAssureurActuel]'}
                                validate={required}
                                loading={_.isEmpty(companys)}
                            >
                                <option value={''}>Sélectionnez...</option>
                                {companys.map((company, index) => {
                                    if (company.code !== "" && company.libelle !== "") {
                                        return <option key={index} value={`${company.code}|${company.libelle}`}>
                                            {company.libelle}
                                        </option>
                                    }
                                })}
                            </Field>
                        </div>
                    </div>

                    <p className={'f-14 mt-4'}>
                        {questions['numeroContratActuel'][source]}&nbsp;?{!neConnaitPasNumeroContratActuel && <small className={'text-error text-danger f-16'}>*</small>}<Icons className={`ml-2 cursor-pointer icon-arrow text-initial`}
                       fontSize={16} icon={'question'} onClick={() => showPopup()}/>
                </p>
                <div className={'row'}>
                        <div
                            className={'col-12 col-md-4 mt-3 mt-md-2'}
                            data-name="DemandeContrat[DevisHamonContratActuel]"
                        >
                            <Field
                                component={Text}
                                name={'DemandeContrat[DevisHamonContratActuel]'}
                                validate={!neConnaitPasNumeroContratActuel ? required : null}
                                disabled={neConnaitPasNumeroContratActuel}
                            />
                        </div>
                    </div>

                    <Field
                        name="dont-know-contract-number"
                        component={Checkbox}
                        type={"checkbox"}
                        className={"ml-2"}
                        onChange={(e) => popupHamon(e)}
                    >
                        <div className={'f-14 mx-2 mt-2 cursor-pointer'}>{questions['dontKnowContractNumber'][source]}</div>
                    </Field>
                </div>
            )}

            <QuestionLabel className={'mt-5'}>Prélèvement</QuestionLabel>

            {fractionnement !== 'M' && (
                <>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        {questions['prelevement'][source]}&nbsp;?&nbsp;
                    </QuestionLabel>

                    <div className={'row'}>
                        <div
                            className={'col-6 text-left col-md-4 mt-3 mt-md-2'}
                            data-name="prelevement_auto"
                        >
                            <Field
                                component={Radio}
                                name={'prelevement_auto'}
                                value={'1'}
                                type={'radio'}
                                label={'Oui'}
                                validate={required}
                                onChange={() => changeValue('DemandeContrat[ModePaiementTerme]', 'P')}
                                className={'small'}
                            />
                        </div>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'prelevement_auto'}
                                value={'0'}
                                type={'radio'}
                                label={'Non'}
                                validate={required}
                                onChange={() => {
                                    changeValue('DemandeContrat[ModePaiementTerme]', 'B');
                                    changeValue('DemandeContrat[TiersPayeur]', {});
                                }}
                                className={'small'}
                            />
                        </div>
                    </div>
                </>
            )}

            {(prelevement_auto && prelevement_auto === '1') || fractionnement === 'M' ? (
                <>
                    <p className={'f-14 mt-4'} required>
                        Nom et prénom du titulaire du compte&nbsp;
                    </p>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 mt-3 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[NomTitulaire]'}
                                normalize={(v) => v.toUpperCase()}
                                validate={required}
                                onChange={(e) => verificationTitulaire(e.target.value)}
                            />
                        </div>
                    </div>

                    <p className={'f-14 mt-4'} required>
                        Adresse&nbsp;
                    </p>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 mt-3 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[adrIBAN]'}
                                normalize={(v) => v.toUpperCase()}
                                validate={required}
                            />
                        </div>
                    </div>

                    <p className={'f-14 mt-4'} required>
                        Ville / Code postal&nbsp;
                    </p>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 mt-3 mt-md-2'} data-name="DemandeContrat[villeIBAN]">
                            <PostalCodeCity
                                icon={'pin'}
                                id={'villeIban'}
                                nameVille={'DemandeContrat[villeIBAN]'}
                                changeValue={changeValue}
                                nameCodePostal={'DemandeContrat[cpIBAN]'}
                                validate={[excludeCodePostal]}
                                defaultValue={postalCodeCity}
                                required
                            />
                        </div>
                    </div>

                    <p className={'f-14 mt-4'}>IBAN</p>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 mt-3 mt-md-2'} data-name="DemandeContrat[IBAN]">
                            <Field
                                component={Text}
                                name={'DemandeContrat[IBAN]'}
                                ref={inputIban}
                                normalize={(v) => v.toUpperCase()}
                            />

                            {errorIban && (
                                <div className={'alert-danger alert'} dangerouslySetInnerHTML={{ __html: errorIban }} />
                            )}

                            {ibanIsCheck && !errorIban && (
                                <div className={'alert-success alert mb-0'}>L'IBAN est valide</div>
                            )}

                            <span
                                className={'btn btn-primary mt-3 w-100'}
                                onClick={loadingIban ? null : () => fetchCheckIban(inputIban.current.value)}
                            >
                                {loadingIban ? (
                                    <div className="spinner-border" role="status" style={{ width: 19, height: 19 }}>
                                        <span className="sr-only" />
                                    </div>
                                ) : (
                                    "Je vérifie l'IBAN"
                                )}
                            </span>

                            <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                                <p className={'mb-0'}>
                                    <strong>Attention ! </strong>
                                    {questions['warningRib'][source]}
                                </p>
                            </div>
                        </div>
                    </div>

                    {ibanIsCheck && (
                        <>
                            <QuestionLabel className={'f-14 mt-4'}>Code BIC&nbsp;</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-8 mt-3 mt-md-2'}>
                                    <Field
                                        component={Text}
                                        name={'DemandeContrat[BIC]'}
                                        normalize={(v) => v.toUpperCase()}
                                        disabled
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {_.toUpper(nomPrenom) !== _.toUpper(nomTitulaire) && <TiersPayeurContainer {...props} />}
                </>
            ) : (
                ''
            )}

            {source !== 'site' && (
                <>
                    <p className={'f-14 mt-4'} required>
                        {questions['choixModePaiement'][source]}&nbsp;?&nbsp;
                    </p>
                    <div className={'row'}>
                        <div className={'col-md-4 col-12 text-md-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'lienPaiementEmail'}
                                value={'true'}
                                type={'radio'}
                                label={'Envoi du lien de paiement'}
                                validate={required}
                                onChange={(e) => changeLienPaiement(e.target.value)}
                                className={'small'}
                            />
                        </div>
                        <div className={'col-md-4 col-12 text-md-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'lienPaiementEmail'}
                                value={'false'}
                                type={'radio'}
                                label={'Carte bancaire'}
                                validate={required}
                                onChange={(e) => changeLienPaiement(e.target.value)}
                                className={'small'}
                            />
                        </div>
                        {(source === 'courtier' || source === 'conseiller') && (
                            <div className={'col-md-4 col-12 text-md-left col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'lienPaiementEmail'}
                                    value={'cheque'}
                                    type={'radio'}
                                    label={'Chèque'}
                                    validate={required}
                                    onChange={(e) => changeLienPaiement(e.target.value)}
                                    className={'small'}
                                />
                            </div>
                        )}
                    </div>

                    <p className={'f-14 mt-4'} required>
                        Souhaitez-vous utiliser l'envoi du lien de signature électronique pour le contrat&nbsp;?&nbsp;
                    </p>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeContrat[Signature]'}
                                value={'true'}
                                type={'radio'}
                                label={'Oui'}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeContrat[Signature]'}
                                value={'false'}
                                type={'radio'}
                                label={'Non'}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                    </div>
                </>
            )}

            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn btn-primary medium-rounded float-right mb-2 order-lg-1`}
                    data-id="btn-souscription"
                    onClick={valid ? handleSubmit(postPaySubmit) : null}
                >
                    {!!payLoaded ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Je finalise la souscription immédiatement'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
        </div>
    );
};

export default Recapitulatif;
