import _ from 'lodash';
import RouteStep from '../components/App/Init/InitFormulaire/RouteStep';

export const routesAffichees = (routes) => {
    let cloneRoute = _.cloneDeep(routes);
    cloneRoute = _.reject(cloneRoute, ['slug', 'recontact']);
    return cloneRoute;
};


export const getSlugStep = (slug) => {
    const route = _.find(RouteStep, ['slug', slug]);
    return route && route !== -1 ? route.step : '';
};
