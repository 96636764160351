import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { getFormInputLabel } from '../../../../../../utils/transcoLabels';
import AntecedentsSideBar from './AntecedentsSideBar';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');

    const malusAuto = selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]');
    const malusMoto = selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]');

    return {
        suspensionPermis: getFormInputLabel(
            'DemandeTarif[Antecedents][SuspensionPermis]',
            selector(state, 'DemandeTarif[Antecedents][SuspensionPermis]'),
        ),
        dateSuspensionPermis: selector(state, 'DemandeTarif[Antecedents][DateSuspensionPermis]'),
        nbMoisSuspensionPermis: selector(state, 'DemandeTarif[Antecedents][NbMoisSuspensionPermis]'),
        annulationPermis: getFormInputLabel(
            'DemandeTarif[Antecedents][AnnulationPermis]',
            selector(state, 'DemandeTarif[Antecedents][AnnulationPermis]'),
        ),
        dateAnnulationPermis: selector(state, 'DemandeTarif[Antecedents][DateAnnulationPermis]'),
        resiliationAssureur: getFormInputLabel(
            'DemandeTarif[Antecedents][ResiliationAssureur]',
            selector(state, 'DemandeTarif[Antecedents][ResiliationAssureur]'),
        ),
        motifResiliationAssureur: getFormInputLabel(
            'DemandeTarif[Antecedents][MotifResiliationAssureur]',
            selector(state, 'DemandeTarif[Antecedents][MotifResiliationAssureur]'),
        ),
        dateResiliationAssureur: selector(state, 'DemandeTarif[Antecedents][DateResiliationAssureur]'),
        sinistre_auto: getFormInputLabel('sinistre_auto', selector(state, 'sinistre_auto')),
        listeSinistres: selector(state, 'DemandeTarif[Antecedents][ListeSinistres]'),
        assure_moto: getFormInputLabel('assure_moto', selector(state, 'assure_moto')),
        nbMoisAssuranceMoto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'),
        assure_auto: getFormInputLabel('assure_auto', selector(state, 'assure_auto')),
        nbMoisAssuranceAuto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'),
        malusAutoMoto: (malusAuto ? Number(malusAuto) > 1 : false) || (malusMoto ? Number(malusMoto) > 1 : false),
        familleProduit: selector(state, 'typeVehicule'),
    };
};

const AntecedentsSideBarContainer = connect(mapStateToProps)(AntecedentsSideBar);

export default AntecedentsSideBarContainer;
