const removeNonDigits = (value) => value.replace(/[^\d]/g, '');
const formatWithSeparator = (value, length, separator) => {
    if (value.length <= length) return value;
    return `${value.slice(0, length)}${separator}${value.slice(length)}`;
};

/**
 * Return Normalize function
 * @param normalize
 * @returns {function}
 */
export const getNormalize = (normalize) => {
    const normalizeList = {
        date: normalizeDate,
        immatriculation: normalizeImmatriculation,
    };

    return normalizeList[normalize];
};

/**
 * Normalize input Date DD/MM/YYYY
 * @param value
 * @returns {string}
 */
export const normalizeDateHour = (value) => {
    if (!value) {
        return value;
    }

    const separateurDate = '/';
    const separateurHour = ':';
    const onlyNums = removeNonDigits(value);

    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    if (onlyNums.length <= 4) {
        return `${onlyNums.slice(0, 2)}${separateurDate}${onlyNums.slice(2)}`;
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 2)}${separateurDate}${onlyNums.slice(2, 4)}${separateurDate}${onlyNums.slice(
            4,
            8,
        )}`;
    }
    if (onlyNums.length <= 10) {
        return `${onlyNums.slice(0, 2)}${separateurDate}${onlyNums.slice(2, 4)}${separateurDate}${onlyNums.slice(
            4,
            8,
        )} ${onlyNums.slice(8, 10)}`;
    }
    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 2)}${separateurDate}${onlyNums.slice(2, 4)}${separateurDate}${onlyNums.slice(
            4,
            8,
        )} ${onlyNums.slice(8, 10)}${separateurHour}${onlyNums.slice(10, 12)}`;
    }
};

/**
 * Normalize input hour to HH:MM
 * @param value
 * @returns {string}
 */
export const normalizeHour = (value) => {
    if (!value) {
        return value;
    }

    const separateurHour = ':';
    const onlyNums = removeNonDigits(value);

    const truncatedNums = onlyNums.slice(0, 4);

    if (truncatedNums.length <= 2) {
        return truncatedNums;
    }
    if (truncatedNums.length <= 4) {
        return `${truncatedNums.slice(0, 2)}${separateurHour}${truncatedNums.slice(2)}`;
    }
};

/**
 * Normalize input Date DD/MM/YYYY
 * @param value
 * @returns {string}
 */
export const normalizeDate = (value) => {
    if (!value) {
        return value;
    }

    const separateur = '/';

    const onlyNums = removeNonDigits(value);
    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    if (onlyNums.length <= 4) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2)}`;
    }
    return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(4, 8)}`;
};

/**
 * Normalize input Date MM/YYYY
 * @param value
 * @returns {string}
 */
export const normalizeMonth = (value) => {
    if (!value) {
        return value;
    }

    const separateur = '/';

    const onlyNums = removeNonDigits(value);
    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    if (onlyNums.length <= 4) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2)}`;
    }
    return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 6)}`;
};

/**
 * Normalize input Date MM/YYYY
 * @param value
 * @returns {string}
 */
export const normalizeYear = (value) => {
    if (!value) {
        return value;
    }

    return removeNonDigits(value);
};

/**
 * Normalize input Number 1 000
 * @param value
 * @returns {string}
 */
export const normalizeNumber = (value) => {
    if (!value) {
        return value;
    }

    const onlyNums = removeNonDigits(value);
    if (onlyNums.length <= 3) {
        return onlyNums;
    }

    return `${onlyNums.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(/' '/g, '&nbsp;')}`;
};

/**
 * Normalize input TextField to Capitalize
 * @param value
 * @returns {string}
 */
export const normalizeCapitalize = (value) => {
    return value && value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Normalize input Tel XX XX XX XX XX
 * @param value
 * @returns {string}
 */
export const normalizeTel = (value) => {
    if (!value) {
        return value;
    }

    const separateur = ' ';

    const onlyNums = removeNonDigits(value);

    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    if (onlyNums.length <= 4) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}`;
    }
    if (onlyNums.length <= 6) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(4, 6)}`;
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(
            4,
            6,
        )}${separateur}${onlyNums.slice(6, 8)}`;
    }
    return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(
        4,
        6,
    )}${separateur}${onlyNums.slice(6, 8)}${separateur}${onlyNums.slice(8, 10)}`;
};

/**
 * Normalize input Number without spaces
 * @param value
 * @returns {string}
 */
export const normalizeDecimal = (value) => {
    if (!value) {
        return value;
    }

    return removeNonDigits(value);
};

/**
 * Normalize input Immatriculation
 * @param value
 * @returns {string}
 */
export const normalizeImmatriculationOld = (value) => {
    if (!value) {
        return value;
    }

    return value
        .replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '')
        .toUpperCase()
        .slice(0, 9);
};

/**
 * Normalize input Immatriculation
 * YY-XXX-YY - Nouvelle + YY-XXX-Y - Mobylette
 * XXXX-YY-XX - Ancienne
 * XXXX-AAA-1A - Ancienne Corse
 * @param value
 * @returns {string}
 */
export const normalizeImmatriculation = (value) => {
    if (!value) return value;

    const separateur = '-';

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase();

    const patterns = [
        { regex: /^[0-9]{2}[a-zA-Z]{2}[0-9]{2}$/, format: [2, 2, 2] }, // 12-AB-34
        { regex: /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$/, format: [2, 3, 2] }, // AA-123-AA
        { regex: /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]$/, format: [2, 3, 1] }, // AA-123-A
        { regex: /^[a-zA-Z][0-9]{2}[a-zA-Z]$/, format: [1, 2, 1] }, // A-12-B
        { regex: /^[a-zA-Z][0-9]{3}[a-zA-Z]$/, format: [1, 3, 1] }, // A-123-B
        { regex: /^\d{3}[a-zA-Z]{3}\d{2}$/, format: [3, 3, 2] }, // 123-ABC-45
        { regex: /^[0-9]{4}[a-zA-Z]{2}[0-9]{2}$/, format: [4, 2, 2] }, // 1234-AA-12
        { regex: /^[0-9]{4}[a-zA-Z]{3}[0-9][a-zA-Z]$/, format: [4, 3, 2] }, // 1234-AAA-1A
    ];

    for (const { regex, format } of patterns) {
        if (regex.test(value)) {
            let index = 0;
            return format.map((length) => {
                const part = onlyNums.slice(index, index + length);
                index += length;
                return part;
            }).join(separateur);
        }
    }

    return onlyNums.slice(0, 11);
};


/**
 * Normalize input Rib 12345-12345-1234567891A-12
 * @param value
 * @returns {string}
 */
export const normalizeRib = (value) => {
    if (!value) {
        return value;
    }

    const separateur = '-';

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase();

    if (onlyNums.length <= 5) {
        return onlyNums;
    }
    if (onlyNums.length <= 10) {
        return `${onlyNums.slice(0, 5)}${separateur}${onlyNums.slice(5, 10)}`;
    }

    if (onlyNums.length <= 21) {
        return `${onlyNums.slice(0, 5)}${separateur}${onlyNums.slice(5, 10)}${separateur}${onlyNums.slice(10, 21)}`;
    }

    return `${onlyNums.slice(0, 5)}${separateur}${onlyNums.slice(5, 10)}${separateur}${onlyNums.slice(
        10,
        21,
    )}${separateur}${onlyNums.slice(21, 23)}`;
};

/**
 * Normalize Input Postal Code 37100
 * @param value
 * @returns {number}
 */
export const normalizePostalCode = (value) => {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '');

    return onlyNums.slice(0, 5);
};

/**
 * Normalize Boolean
 * @param value
 * @returns {boolean}
 */
export const normalizeBoolean = (value) => {
    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    return value;
};

/**
 * Normalize input IBAN FR11-2222-3333-4444-5555-6666-777
 * @param value
 * @returns {string}
 */
export const normalizeIban = (value) => {
    if (!value) {
        return value;
    }

    const separateur = '-';

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase();

    if (onlyNums.length <= 4) {
        return onlyNums;
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}`;
    }

    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}`;
    }

    if (onlyNums.length <= 16) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(
            8,
            12,
        )}${separateur}${onlyNums.slice(12, 16)}`;
    }

    if (onlyNums.length <= 20) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(
            8,
            12,
        )}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}`;
    }

    if (onlyNums.length <= 24) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(
            8,
            12,
        )}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(
            20,
            24,
        )}`;
    }

    return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(
        8,
        12,
    )}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(
        20,
        24,
    )}${separateur}${onlyNums.slice(24, 27)}`;
};

/**
 * Normalize input VIN
 * @param value
 * @returns {int}
 */
export const normalizeNumMarquage = (value) => {
    if (!value) {
        return value;
    }

    return value.replace(' ', '');
};

/**
 * Normalize input Name (remove numbers)
 * @param value
 * @returns {string}
 */
export const normalizeName = (value) => {
    if (!value) {
        return value;
    }

    return value.replace(/[0-9]/g, '');
};

/**
 * Format number to euro value
 * @param number
 * @param style
 * @returns {string}
 */
export const normalizeEuro = (number, style = 'currency') => {
    return new Intl.NumberFormat('fr-FR', { style, currency: 'EUR' }).format(number);
};
/**
 * Normalize input TextField to Capitalize
 * @param value
 * @returns {string}
 */
export const normalizeAccent = value => {
    return value && value.replace(/"/g, "").normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()
}

/**
 * Normalize input string to number
 * @param value
 * @returns {int}
 */
export const normalizeNumberOnly = value => {
    if (!value) {
        return value
    }

    return value.replace(/[^\d]/g, '');
}