import PropTypes from 'prop-types'
import './avatar.scss'

export const Avatar = (props) => {
    const { img, text, onClick, direction } = props

    return (
        <div className={`avatar direction-${direction} d-flex justify-content-center`} onClick={onClick} >
            <div>
                <img src={img} alt="Aide" />
            </div>
            { text && <div className={'bg-secondary-light p-3 d-inline-block ml-2 align-bottom text-left'} data-test="text">
                <p dangerouslySetInnerHTML={{__html: text}} className={'m-0'} />
            </div> }
        </div>
    );
};

Avatar.defaultProps = {
    onClick: () => {},
    direction: "bottom"
};

Avatar.propTypes = {
    img: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func
};
