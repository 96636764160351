import PropTypes from 'prop-types';
import { normalizeEuro } from '@amo/core/utils/normalize.js'

const HeaderTarifAnnualPrice = (props) => {
    const { prix, prix_promo } = props;
    const enPromo = prix_promo !== undefined && prix > prix_promo;

    return (
        <div className={`text-initial f-14 mb-4`}>
            soit {normalizeEuro(enPromo ? prix_promo : prix)} <span className="text-initial">TTC/an</span>
        </div>
    );
};

HeaderTarifAnnualPrice.propTypes = {
    prix: PropTypes.number.isRequired,
    prix_promo: PropTypes.number,
};

export default HeaderTarifAnnualPrice;
