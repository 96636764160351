import TextField from '@mui/material/TextField';
import { frFR, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PropTypes from 'prop-types';

/* https://mui.com/x/react-date-pickers/getting-started/ */

export const DateField = (props) => {
    const {
        meta: { error, touched },
        input,
        label,
        placeholder = 'DD/MM/YYYY',
    } = props;
    let locale = frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    locale.datePickerDefaultToolbarTitle = 'Sélectionnez une date';

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr" localeText={locale}>
            <DatePicker
                className={'w-100 bg-white'}
                label={label}
                value={input.value}
                onChange={(newValue) => {
                    input.onChange(newValue);
                }}
                renderInput={(params) => {
                    params.error = false;
                    if (moment(input.value, 'DD/MM/YYYY').isValid()) {
                        params.inputProps.value = moment(input.value, 'DD/MM/YYYY', true).format('L');
                    }
                    params.inputProps.placeholder = placeholder;
                    params.InputProps.error = Boolean(error) && input.value !== '';
                    return <TextField {...params} />;
                }}
                {...props}
            />
            {error && input.value !== '' && (
                <small className={'alert alert-danger invalid-feedback d-block'}>{error}</small>
            )}
        </LocalizationProvider>
    );
};

DateField.propTypes = {
    meta: PropTypes.shape({
        error: PropTypes.string,
    }),
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
};
