import classNames from 'classnames';
import PropTypes from 'prop-types';
import './radio-btn.scss';

export const RadioBtn = (props) => {
    const {
        icon,
        input,
        type,
        label,
        onClick,
        meta: { error },
        className,
        children,
    } = props;

    return (
        <label
            className={classNames(`${className}`, {
                'border-danger': input.checked && error,
                active: input.checked,
            })}
        >
            <input type={type} {...input} hidden onClick={onClick} />
            {icon && (
                <div className={'icon mb-4'}>
                    <img src={icon} alt={label} />
                </div>
            )}
            <span className={'d-block'} dangerouslySetInnerHTML={{ __html: label }} />
            {children ? children : ''}
        </label>
    );
};

RadioBtn.propTypes = {
    icon: PropTypes.string,
    input: PropTypes.shape({
        checked: PropTypes.bool,
    }),
    type: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    meta: PropTypes.shape({
        error: PropTypes.string,
    }),
    className: PropTypes.string,
    children: PropTypes.node,
};

RadioBtn.defaultProps = {
    input: {
        checked: false,
    },
    type: 'radio',
    label: 'label',
    meta: {
        touched: false,
        error: '',
    },
    className: '',
};
