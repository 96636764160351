import { connect } from 'react-redux';
import {getGestionnaireInit, getSourceDemandeTarifInit} from '../../../../../redux/selectors/init/init.selectors';
import Footer from './Footer';

const mapStateToProps = (state) => {
    return {
        gestionnaireIsAMO: getGestionnaireInit(state).codeBelair === 43397,
        source: getSourceDemandeTarifInit(state),
    };
};

const FooterContainer = connect(mapStateToProps)(Footer);

export default FooterContainer;
