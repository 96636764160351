import PropTypes from 'prop-types';
import './navbar.scss';

const NavBar = (props) => {
    const { routes, activeStep } = props;

    return (
        <div className={'nav stepper col navbar mt-1 pr-lg-5'}>
            {routes.slice(0, 4).map((s, i) => (
                <div
                    className={`etape col ${s.step === activeStep ? 'active' : ''} ${
                        s.step < activeStep ? 'past d-none d-lg-block' : ''
                    } ${s.step > activeStep ? 'disabled d-none d-lg-block' : ''}`}
                    key={i}
                >
                    {s.step <= activeStep ? (
                        <p>
                            <a href={s.path}>{s.title}</a>
                        </p>
                    ) : (
                        <p>{s.title}</p>
                    )}
                    <div className="progress">
                        {s.step === activeStep && (
                            <div className="progress-bar bg-info" role="progressbar" style={{ width: 100 + '%' }} />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

NavBar.defaultProps = {
    activeStep: 0,
};

NavBar.propTypes = {
    activeStep: PropTypes.number,
    routes: PropTypes.array,
};

export default NavBar;
