import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {Modal} from "@amo/core/components/containers";

const PopupDevis = (props) => {
    const {
        email,
        handleSubmit,
        nextStep,
        pdfDevis,
        conventionAssistance,
        dispositionGenerale,
        documentInformation,
        ficheConseil,
        source
    } = props;
    const [open, setOpen] = useState(true);

    useEffect(() => {
        return () => document.body.classList.remove('modal-open');
    }, []);

    return (
        <>
            {open && (
                <Modal
                    onClick={() => {
                        setOpen(!open);
                        document.body.classList.remove('modal-open');
                    }}
                >
                    <div className={'text-center'}>
                        <h3 className={'card-title'}>Votre proposition d'assurance</h3>
                        <div className={'mt-3'}>
                            <button
                                className={`btn btn-primary medium-rounded my-3`}
                                onClick={handleSubmit(nextStep)}
                                data-id="btn-finalisation"
                            >
                                FINALISER LA SOUSCRIPTION
                            </button>

                            <a
                                href={pdfDevis}
                                className="card-link bold d-block"
                                rel={'noopener noreferrer'}
                                target={'_blank'}
                            >
                                Télécharger la proposition d'assurance
                            </a>
                        </div>

                        <div className={'mt-3'}>
                            <p className={'card-text'}>Retrouvez aussi en téléchargement :</p>
                            <ul className={'list-unstyled'}>
                                {ficheConseil && (
                                    <li>
                                        <a href={ficheConseil} rel={'noopener noreferrer'} target={'_blank'}>
                                            Fiche conseil
                                        </a>
                                    </li>
                                )}
                                {documentInformation && (
                                    <li>
                                        <a href={documentInformation} rel={'noopener noreferrer'} target={'_blank'}>
                                            Document d'information
                                        </a>
                                    </li>
                                )}
                                {dispositionGenerale && (
                                    <li>
                                        <a href={dispositionGenerale} rel={'noopener noreferrer'} target={'_blank'}>
                                            Dispositions Générales
                                        </a>
                                    </li>
                                )}
                                {conventionAssistance && (
                                    <li>
                                        <a href={conventionAssistance} rel={'noopener noreferrer'} target={'_blank'}>
                                            Annexe Assistance
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

PopupDevis.defaultProps = {
    email: 'votre adresse mail',
    vehicule: 'véhicule',
};

PopupDevis.propTypes = {
    email: PropTypes.string,
    vehicule: PropTypes.string,
};

export default PopupDevis;
