import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change, clearFields, formValueSelector } from 'redux-form';
import { setCountries } from '../../../../../../redux/actions/app/ui/ui.actions';
import { getListCountries } from '../../../../../../redux/selectors/ui/ui.selectors';
import { getVehicule } from '../../../../../../redux/selectors/vehicule/vehicule.selectors';
import { getCRMAutoMin, getCRMMotoMin } from '@amo/core/utils/functions';
import Profil from './Profil';
import {getApporteur1Init, getSourceDemandeTarifInit} from "../../../../../../redux/selectors/init/init.selectors.js";

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');

    return {
        apporteur: getApporteur1Init(state),
        source: getSourceDemandeTarifInit(state),
        civilitePrincipale: selector(state, 'civilitePrincipale'),
        conducteur: selector(state, 'DemandeTarif[ListePersonnes][0]'),
        permis: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis]'),
        permisOnError: selector(state, 'permisOnError'),
        acceptRecontact: selector(state, 'accept-recontact'),
        typePermisAM: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][0][TypePermis]'),
        typePermisA1: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]'),
        typePermisA2: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]'),
        typePermisA: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][3][TypePermis]'),
        typePermisB: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]'),
        datePermisA2: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]'),
        datePermisA: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]'),
        datePermisB: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]'),
        dateNaissance: selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]'),
        conducteurMineur: selector(state, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'),
        cylindree: selector(state, 'cylindreeVehicule'),
        valeurCrmMoto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'),
        valeurCrmAuto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]'),
        vehicule: getVehicule(state),
        cylindreeVehicule: selector(state, 'cylindreeVehicule'),
        titulaireCarteGrise: selector(state, 'titulaireCarteGrise'),
        familleProduit: selector(state, 'typeVehicule'),
        minCRMAuto: getCRMAutoMin(selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]')),
        vehiculeImmatricule: selector(state, 'DemandeTarif[Vehicule][VehiculeImmatricule]'),
        minCRMMoto: getCRMMotoMin({
            datePermisA1: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]'),
            datePermisA2: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]'),
            datePermisA: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]'),
            datePermisB: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]')
        }),
        birthCountryRepresentantLegal: selector(state, 'DemandeTarif[ListePersonnes][1][BirthCountry]'),
        countries: getListCountries(state),
        formation: selector(state, 'DemandeTarif[ListePersonnes][0][Formation]'),
        DeclarationAssurance: selector(state, 'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeValue: (field, value) => change('devis', field, value),
            unsetValue: (field) => clearFields('devis', false, false, field),
            setCountries,
        },
        dispatch,
    );

const ProfilContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Profil);

export default ProfilContainer;
