import _ from "lodash";
import {Icons} from "@amo/core/components/containers";

const ErreurTarif = (props) => {
    const { text, codeBelairApp1, source } = props;

    return (
        <div>
            <div className={'bg-secondary-light p-4 mb-5 rounded text-center'}>
                <div className={'f-21 text-danger mb-3'}>
                    <Icons className={`d-inline-block position-relative mr-2`} fontSize={30} icon={'triangle'} />
                    <p>Cher internaute,</p>
                    <p>
                        Les informations que vous avez saisies ne nous permettent pas de vous proposer un tarif en
                        ligne.
                    </p>
                    <p>Le tarif est en feu rouge.</p>
                    <br />
                    {
                        _.includes([479846, 111619, 479835], codeBelairApp1) && <>
                                <p>Vous pouvez contacter un conseiller au <b>01 41 03 31 20</b></p>
                                <br />
                            </>
                    }
                    <p>A très bientôt</p>

                    {source === 'conseiller' && <>
                        <br />
                        <b>Ces informations ne doivent pas être communiquées au client</b>
                        <p>Motif du refus INTERNE : {text}</p>
                        <p>A très bientôt</p>
                    </> }
                </div>
            </div>
        </div>
    );
};

export default ErreurTarif;
