import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GetParams from './GetParams';

const App = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    {/* Route principale qui correspond aux URL avec la clé dans le chemin */}
                    <Route path="/:key?" element={<GetParams />} />

                    <Route path="/fr/form/site/:product?" element={<GetParams />} />
                    <Route path="/fr/devis/reload/:id" element={<GetParams />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
