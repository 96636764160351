import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { getFormInputLabel } from '../../../../../../utils/transcoLabels';
import FormuleSideBar from './FormuleSideBar';
import {getPrixTotal, getPrixTotalPromo} from "../../../../../../redux/selectors/ui/ui.selectors.js";

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');

    return {
        fractionnement: getFormInputLabel('fractionnement', selector(state, `DemandeTarif[Police][FractionnementChoisi]`)),
        formule: getFormInputLabel('formule', selector(state, `DemandeTarif[Police][FormuleChoisie]`)),
        tarif: getPrixTotalPromo(state)
    };
};

const FormuleSideBarContainer = connect(mapStateToProps)(FormuleSideBar);

export default FormuleSideBarContainer;
