export const CHECK_STATUT_CONTRAT = '[Check status contrat]';

export const FETCH_CHECK_STATUT_CONTRAT = `${CHECK_STATUT_CONTRAT} Fetch`;

export const fetchContratStatut = ({ query }) => ({
    type: FETCH_CHECK_STATUT_CONTRAT,
    payload: {
        data: query,
    },
});
