import * as Sentry from '@sentry/react';
import { APP_ENV } from '../constants';
//
// const vitalsUrl = 'https://vitals.vercel-analytics.com/v1/vitals';
//
// function getConnectionSpeed() {
//     return 'connection' in navigator &&
//         navigator['connection'] &&
//         // @ts-ignore
//         'effectiveType' in navigator['connection']
//         ? navigator['connection']['effectiveType']
//         : '';
// }
//
// export function sendToVercelAnalytics(metric) {
//     const analyticsId = import.meta.env.VITE_VERCEL_ANALYTICS_ID;
//     if (!analyticsId) {
//         return;
//     }
//
//     const body = {
//         dsn: analyticsId,
//         id: metric.id,
//         page: window.location.pathname,
//         href: window.location.href,
//         event_name: metric.name,
//         value: metric.value.toString(),
//         speed: getConnectionSpeed(),
//     };
//
//     const blob = new Blob([new URLSearchParams(body).toString()], {
//         // This content type is necessary for `sendBeacon`
//         type: 'application/x-www-form-urlencoded',
//     });
//     if (navigator.sendBeacon) {
//         navigator.sendBeacon(vitalsUrl, blob);
//     } else
//         fetch(vitalsUrl, {
//             body: blob,
//             method: 'POST',
//             credentials: 'omit',
//             keepalive: true,
//         });
// }
//
// export const reportWebVitals = (onPerfEntry) => {
//     if (APP_ENV !== 'production') return;
//
//     if (onPerfEntry && onPerfEntry instanceof Function) {
//         import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
//             getCLS(onPerfEntry);
//             getFID(onPerfEntry);
//             getFCP(onPerfEntry);
//             getLCP(onPerfEntry);
//             getTTFB(onPerfEntry);
//         });
//     }
// };

export const sentryInit = () => {
    if (APP_ENV !== 'production') return;

    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
        tracesSampleRate: 0.01,
        environment: APP_ENV,
        enabled: true,
    });
};
