// https://wheels-recette.april-moto.com/vehicles/immatriculation/FW-490-VL?type=moto

import { useQuery } from '@tanstack/react-query';
import { requestWheels } from '../utils/request';

export const useImmatWheels = (search = false, type, immat, a2filter = "1") => {
    return useQuery({
        queryKey: [type, immat, a2filter],
        queryFn: () => requestWheels.get(`/vehicles/immatriculation/${immat}?type=${type}&a2_filter=${a2filter}`).then((res) => res.data),
        enabled: search && !!type && !!immat
    });
}
