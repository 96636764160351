import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
import {
    postNotFindVehicule,
    POST_NOT_FIND_VEHICULE,
} from '../../../../../../redux/actions/app/notFindVehicule/notFindVehicule.actions.js';
import { getErrorEntity, getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors.js';
import NotFindVehicule from './NotFindVehicule.jsx';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');

    return {
        loading: getLoadingEntity(state, POST_NOT_FIND_VEHICULE),
        success:
            getLoadingEntity(state, POST_NOT_FIND_VEHICULE) === false &&
            getErrorEntity(state, POST_NOT_FIND_VEHICULE) === false,
        initialValues: {
            marque: selector(state, 'marqueVehicule'),
            cylindree: selector(state, 'cylindreeVehicule'),
        },
    };
};

const mapDispatchToProps = {
    changeValue: (field, value) => change('notFindVehicule', field, value),
    onSubmit: (form) => postNotFindVehicule({ form }),
};

const NotFindVehiculeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'notFindVehicule',
    }),
)(NotFindVehicule);

export default NotFindVehiculeContainer;
