import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import LibertyRider from '../../../../assets/images/liberty-rider.png';
import HeaderError from './Header/HeaderError';
import HeaderTarifContainer from './HeaderTarif/HeaderTarifContainer';
import './initformulaire.scss';
import NavBarContainer from './NavBar/NavBarContainer';
import SideBarContainer from './SideBar/SideBarContainer';
import TopBarContainer from './TopBar/TopBarContainer';
import {ChampsObligatoires} from "@amo/core/components/containers";
import FooterContainer from "./Footer/FooterContainer.js";
import BookingContainer from "./Booking/BookingContainer.js";

const InitFormulaire = (props) => {
    const {
        changeValue,
        url_pay,
        stepPaiement,
        stepRecapitulatif,
        step,
        routes,
        stepTarif,
        tarifLoaded,
        stepRecontact,
        source,
        isDevisOrange,
        apporteur1,
        apporteur2,
        realisateur,
        actifApporteur,
        initialValues
    } = props
    const navigate = useNavigate();

    const [etape, setEtape] = useState(null);
    const [showPopupDevis, setShowPopupDevis] = useState(false);
    const [formRappelAffiche, setFormRappelAffiche] = useState(false);

    useEffect(() => {
        if (step !== etape) {
            setEtape(step);
            navigate(`${_.find(routes, ['step', step]).path}${window.location.search}`);
        }
        setShowPopupDevis(false);

    }, [step]);

    useEffect(() => {
        if (step === stepRecapitulatif || step === stepPaiement) {
            changeValue('token', url_pay.token);
            changeValue('url_return', url_pay.url_return);
        }
    }, []);

    useEffect(() => {
        const arrayTelevendeurs = [
            542965, 550354, 550356, 550679, 532370, 532831, 532914, 533179, 532263, 533219, 533312, 533263, 532736, 550355,
            533005, 543159, 532783, 550353, 550680, 550681, 703884, 705322, 712018, 780153, 797307, 800227, 548156
        ];
        const cle = new URLSearchParams(window.location.search).get('cle');

        if (source === 'conseiller') {
            if (_.includes(arrayTelevendeurs, realisateur)) {
                window.location.href = `https://televente.april-moto.com/${cle}`;
            }
        } else if (source === 'site') {
            if (apporteur1 === 43397 && (!apporteur2 || apporteur2 === 43397)) {
                window.location.href = `https://devis.april-moto.com/${cle}`;
            }
        }
    }, [source, realisateur, apporteur1, apporteur2]);

    if(actifApporteur === 'inactif') {
        return (
            <>
                <TopBarContainer />
                <HeaderError message={'Le formulaire n\'est pas accessible pour votre compte car aucun produit n\'est disponible. Merci de contacter le service commercial pour plus d\'informations.'}/>
            </>
        );
    }

    return (
        <div className={'position-relative'} style={{ minHeight: '100vh' }}>
            <TopBarContainer setFormRappelAffiche={setFormRappelAffiche} formRappelAffiche={formRappelAffiche} apporteur={apporteur1}>
                {step !== stepRecontact && <NavBarContainer />}
                {formRappelAffiche && <BookingContainer setShowBooking={setFormRappelAffiche} />}
            </TopBarContainer>

            <div id="formulaire" />


            {step === stepTarif && tarifLoaded && <HeaderTarifContainer />}

            <div className="container steps-formulaire">
                {!isMobileOnly && step === stepTarif && (
                    <div className={'text-center'}>
                        <img src={LibertyRider} alt="Liberty Rider" width={'100%'} />
                    </div>
                )}

                <div className={'row'}>
                    {(step <= 7 && !(isDevisOrange && source === 'courtier') ) && <div className={'col-12 col-lg-4 order-lg-1'}>
                        <SideBarContainer/>
                    </div>}

                    <SwitchTransition>
                        <CSSTransition
                            timeout={500}
                            key={etape}
                            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                            classNames={`fade-left`}
                        >
                            <div className={`col-12 order-lg-0 ${step <= 7 ? 'col-lg-8' : 'col-lg-12'}`}>
                                {routes.map((r, i) => {
                                    if (window.location.pathname === r.path) {
                                        return <div key={i}>{r.main(props)}</div>;
                                    }
                                })}

                                <ChampsObligatoires />
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </div>

            <FooterContainer />
        </div>
    );
};

InitFormulaire.defaultProps = {
    step: 0,
};

InitFormulaire.propTypes = {
    step: PropTypes.number,
    route: PropTypes.array,
};

export default InitFormulaire;
