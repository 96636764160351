import { Field } from 'redux-form';
import { onError, required } from '@amo/core/utils/validateField';
import {Radio} from "@amo/core/components/forms";

const TitulaireCarteGriseMineur = (props) => {
    const { changeValue } = props;

    return (
        <>
            <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                <Field
                    component={Radio}
                    name={'titulaireCarteGrise'}
                    value={'3'}
                    type={'radio'}
                    label={'À votre nom'}
                    validate={[required, onError]}
                    onChange={() => {
                        changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
                        changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
                    }}
                    className={'small'}
                />
            </div>
            <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                <Field
                    component={Radio}
                    name={'titulaireCarteGrise'}
                    value={'1'}
                    type={'radio'}
                    label={'Au nom de votre enfant'}
                    validate={[required, onError]}
                    onChange={() => {
                        changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
                        changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
                    }}
                    className={'small'}
                />
            </div>

            <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                <Field
                    component={Radio}
                    name={'titulaireCarteGrise'}
                    value={'onError'}
                    type={'radio'}
                    label={'Autre'}
                    validate={[required, onError]}
                    className={'small'}
                />
            </div>
        </>
    );
};

export default TitulaireCarteGriseMineur;
