import PropTypes from 'prop-types';
import SideBarContent from '../SideBarContent/SideBarContent';

const VehiculeSideBar = (props) => {
    const { vehicule, logoMarque, situationVehicule, familleProduit, marque, cylindree, modele, isCurrentStep, slug, goTo } = props;

    let date = `${
        vehicule.annee_debut_version !== null && vehicule.annee_debut_version !== 0
            ? `depuis ${vehicule.annee_debut_version}`
            : ''
    } ${
        vehicule.annee_fin_version !== null && vehicule.annee_fin_version !== 0
            ? ` jusqu'a ${vehicule.annee_fin_version}`
            : ''
    }`;

    let modeleVehicule = '';
    modeleVehicule += marque !== undefined ? marque : '';
    modeleVehicule += cylindree !== undefined ? ` ${cylindree}` : '';
    modeleVehicule += modele !== undefined ? ` ${modele}` : '';
    modeleVehicule += (vehicule.annee_debut_version !== undefined || vehicule.annee_fin_version !== undefined ) ? `<small class="d-block">${date}</small>` : '';

    return (
        <SideBarContent
            title={'Votre véhicule'}
            icon={'moto'}
            isCurrentStep={isCurrentStep}
            slug={slug}
            goTo={goTo}
            tabContent={[
                { title: 'À propos de votre véhicule', value: situationVehicule },
                { title: 'Type de véhicule', value: familleProduit },
                { title: 'Modèle de véhicule', value: modeleVehicule },
                { type: "img", value: logoMarque }
            ]}
        />
    );
};

VehiculeSideBar.propTypes = {
    slug: PropTypes.string,
    goTo: PropTypes.func,
};

export default VehiculeSideBar;
