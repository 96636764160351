import _ from 'lodash';
import {SimpleTitle, Icons} from "@amo/core/components/containers";

const EnvoiPieces = (props) => {
    const { immatriculation, contract, documentsUrl, listeDocuments, urlPdfContrat, urlPdfSepa, urlPdfHamon, urlFicheConseil, urlMemo, urlAttestationAssurance } = props;

    const setTrackingGoToDocuments = () => {
        window.dataLayer.push({
            event: 'click',
            action: 'clickButton',
            libelle: 'Envoyer vos documents',
            categorie: 'GO-TO-DOCUMENTS',
        });
        window._paq.push(['trackEvent', 'infos', 'clickButton', 'Envoyer vos documents']);
    };

    return (
        <div>
            <div>
                
                <div className={'bg-secondary-light p-4 blocSuccess text-center rounded'}>
                    <div className={'f-21 textCheck text-primary-light m-0'}>
                        <Icons
                            className={`p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mr-2`}
                            fontSize={7}
                            icon={'Shape'}
                        />
                        La proposition a bien été validée et le contrat va débuter à la date indiquée sur le contrat.
                    </div>
                    
                    <div className='row justify-content-center'>

                        <a href={urlPdfContrat} download target={'_blank'} className={'btn btn-primary my-3 mx-2'}>
                            Télécharger le contrat
                        </a>

                        {(_.isEmpty(immatriculation) && urlAttestationAssurance) && (
                            <a href={urlAttestationAssurance} download target={'_blank'} className={'btn btn-primary my-3 mx-2'}>
                                Télécharger l'Attestation d'assurance
                            </a>
                        )}

                        {(!_.isEmpty(immatriculation) && urlMemo) && (
                            <a href={urlMemo} download target={'_blank'} className={'btn btn-primary my-3 mx-2'}>
                                Télécharger le Mémo
                            </a>
                        )}

                        {urlPdfSepa &&
                            <a href={urlPdfSepa} download target={'_blank'} className={'btn btn-primary my-3 mx-2'}>
                                Télécharger le Mandat SEPA
                            </a>
                        }
                        {urlPdfHamon && (
                            <a href={urlPdfHamon} download target={'_blank'} className={'btn btn-primary my-3 mx-2'}>
                                Télécharger le Mandat Loi Hamon
                            </a>
                        )}

                        {urlFicheConseil && (
                            <a href={urlFicheConseil} download target={'_blank'} className={'btn btn-primary my-3 mx-2'}>
                                Télécharger la fiche conseil
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div className="row">
                <SimpleTitle className={'mt-5'}>Vos documents à nous transmettre</SimpleTitle>

                <ul className={'mt-4'}>
                    {_.map(listeDocuments, (d, i) => (
                        <li key={i} className={'p-1'}>
                            {d.Description}
                        </li>
                    ))}
                </ul>
            </div>

            <div className={'row mt-5'}>
                <div className="col text-center">
                    <a
                        href={documentsUrl}
                        className={'btn btn-primary medium-rounded text-uppercase mb-2'}
                        onClick={() => setTrackingGoToDocuments()}
                    >
                        Envoyer vos documents
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EnvoiPieces;
