export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export const setNotification = ({ entity, body, type, title, timeout, onHidden }) => ({
    type: `${entity} ${SET_NOTIFICATION}`,
    meta: {
        title,
        body,
        type,
        timeout,
        onHidden,
    },
});
