import { connect } from 'react-redux';
import { getStep } from '../../../../../redux/selectors/ui/ui.selectors';
import { routesAffichees } from '../../../../../utils/function';
import { getRoutesByDevice } from '@amo/core/utils/getRoutesByDevice.js';
import NavBar from './NavBar';
import RouteStep from '../RouteStep';

const mapStateToProps = (state) => {
    return {
        routes: routesAffichees(getRoutesByDevice(getStep(state),RouteStep)),
        activeStep: getStep(state),
    };
};

const NavBarContainer = connect(mapStateToProps)(NavBar);

export default NavBarContainer;
