import { APP_URL_PASS } from '@amo/core/constants/constants.js'

export const ASK_QUESTION = '[Ask Question]';
export const POST_ASK_QUESTION = `${ASK_QUESTION} Post`;

export const postAskQuestion = (form) => ({
    type: POST_ASK_QUESTION,
    payload: {
        data: `${APP_URL_PASS}/televente/contact`,
        body: form,
    },
});
