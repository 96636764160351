import PropTypes from 'prop-types';
import { normalizeEuro } from '@amo/core/utils/normalize.js'

const HeaderTarifPrice = (props) => {
    const { prix, prix_promo, frequence } = props;
    const enPromo = prix_promo !== undefined && prix > prix_promo;

    return (
        <>
            {enPromo && (
                <div className={'d-block font-weight-bold text-primary-light'}>
                    {normalizeEuro(prix_promo)} <span className="text-initial">TTC/{frequence}</span>
                </div>
            )}

            <div
                className={` ${
                    enPromo
                        ? 'text-decoration-line-through text-initial f-21 mb-3'
                        : 'font-weight-bold text-primary-light'
                }`}
            >
                {normalizeEuro(prix)} <span className="text-initial">TTC/{frequence}</span>
            </div>
        </>
    );
};

HeaderTarifPrice.propTypes = {
    prix: PropTypes.number.isRequired,
    prix_promo: PropTypes.number,
    frequence: PropTypes.string.isRequired,
};

export default HeaderTarifPrice;
