import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { clearFields } from 'redux-form';
import formValueSelector from 'redux-form/lib/formValueSelector';
import {
    getApporteur1RecueilInit,
    getIsConcess,
    getIsConseiller, getIsMB
} from '../../../../../../redux/selectors/init/init.selectors';
import { getTypeVehicule } from '../../../../../../redux/selectors/vehicule/vehicule.selectors';
import Besoins from './Besoins';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');

    return {
        utilisation_recueil: selector(state, 'utilisation_recueil'),
        assistance: selector(state, 'DemandeTarif[DevoirConseil][DevoirASS2]'),
        montantAccessoires: String(selector(state, 'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]')).replace(
            /\s/g,
            '',
        ),
        montant: selector(state, 'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]'),
        typeVehicule: getTypeVehicule(state),
        recueilActif: getApporteur1RecueilInit(state),
        isConcess: getIsConcess(state),
        isConseiller: getIsConseiller(state),
        isMB: getIsMB(state)
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            unsetValue: (field) => clearFields('devis', false, false, field),
        },
        dispatch,
    );

const BesoinsContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Besoins);

export default BesoinsContainer;
