import { connect } from 'react-redux';
import { getErrorTarif } from '../../../../../../../redux/selectors/ui/ui.selectors';
import ErreurTarif from './ErreurTarif';
import {getApporteur1Init} from "../../../../../../../redux/selectors/init/init.selectors.js";
import {getSourceDemandeTarifInit} from "../../../../../../../redux/selectors/init/init.selectors.js";

const mapStateToProps = (state) => {
    return {
        text: getErrorTarif(state),
        codeBelairApp1: getApporteur1Init(state)?.codeBelair,
        source: getSourceDemandeTarifInit(state),
    };
};

const ErreurTarifContainer = connect(mapStateToProps)(ErreurTarif);

export default ErreurTarifContainer;
