import classNames from 'classnames';
import PropTypes from 'prop-types';
import './radio-calendrier.scss';

export const RadioCalendrier = (props) => {
    const {
        input,
        type,
        label,
        onClick,
        meta: { error },
        className,
    } = props;

    return (
        <label
            className={classNames(`calendrier-radio py-2 text-center my-1 ms-1 ml-1 rounded cursor-pointer ${className}`, {
                'border-danger': input.checked && error,
                active: input.checked,
            })}
            style={{ width: '60px' }}
        >
            <input type={type} {...input} hidden onClick={onClick} />
            <span dangerouslySetInnerHTML={{ __html: label }} />
        </label>
    );
};
RadioCalendrier.propTypes = {
    input: PropTypes.shape({
        checked: PropTypes.bool,
    }),
    type: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    meta: PropTypes.shape({
        error: PropTypes.string,
    }),
    className: PropTypes.string,
};

RadioCalendrier.defaultProps = {
    input: {
        checked: false,
    },
    type: 'radio',
    label: 'label',
    meta: {
        touched: false,
        error: '',
    },
    className: '',
};
