import PropTypes from 'prop-types';

export const Icons = (props) => {
    const { icon, className, fontSize, ...other } = props;

    return <span className={`icon-${icon} ${className}`} style={{ fontSize }} {...other} />;
};

Icons.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    fontSize: PropTypes.number,
};

Icons.defaultProps = {
    icon: 'moto',
    className: '',
};
